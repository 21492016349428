<mat-form-field fxFlex="grow" fxFlex.xs="auto" fxFill appearance="outline">
    <mat-label>{{'Telephone number' | translate}}</mat-label>
    <mat-hint>{{hint}}</mat-hint>
    <input type="tel" required [formControl]="customerPhone" matInput>
    <mat-error *ngIf="customerPhone?.hasError('required')">
        {{'You must introduce your telephone number' | translate }}
    </mat-error>
    <mat-error *ngIf="customerPhone?.hasError('pattern')">
        {{'Introduce a valid norwegian telephone number' | translate }}
    </mat-error>
    <mat-error *ngIf="customerPhone?.hasError('phoneExistsForActiveBooking')">
        {{'Phone number already used to book this event' | translate }}
    </mat-error>
</mat-form-field>