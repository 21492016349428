<div class="wrapper">
  <div class="mat-body privacy-policy mat-elevation-z1 page">
    <div *ngIf="translate.currentLang == 'nb'" fxLayout="column">
      <h1 class="mat-h1">Regler for publisering </h1>
      <p>For å unngå upassende bruk av dette nettstedet, må du oppnå følgende regler når du publiserer arrangementet:</p>
      <ul>
        <li>Brukeren er ansvarlig for det publiserte innholdet. Inkludert misvisende eller utdatert informasjon.</li>
        <li>Brukeren har ikke lov til å publisere noe seksuelt innhold, inkludert tekster og bilder.</li>
        <li>Brukeren har ikke lov til å publisere noe ikke-arrangementetsrelatert innhold, slik som annonser eller spam mot tredjeparts nettsteder.</li>
        <li>Brukeren har ikke lov til å publisere uautoriserte bilder av barn: alle bilder må ha en gyldig og sporbar kreditt.</li>
        <li>Brukeren har ikke lov til å publisere hat, sexistisk, fremmedfiendtlig, rasistisk eller homofobt innhold.</li>
      </ul>
      <p>Unnlatelse av å oppfylle noen av de tidligere reglene vil bety sletting av upassende innhold og brukerkontoen de ble publisert under.</p>
    </div>
    <div *ngIf="translate.currentLang != 'nb'" fxLayout="column">
      <h1 class="mat-h1">Rules for publishing</h1>
      <p>In order to avoid inappropriate uses of this website, you must accomplish with the following rules when you publish your event:</p>
      <ul>
        <li>The user is responsable for the published content. Including any misleading or outdated information.</li>
        <li>The user is not allowed to publish any sexual content, including texts and pictures.</li>
        <li>The user is not allowed to publish any non-event related content, such us advertisements or spam towards third parties websites.</li>
        <li>The user is not allowed to publish unauthorized pictures of children: all pictures must have a valid and traceable credit.</li>
        <li>The user is not allowed to publish hate, sexist, xenofobic, racist or homofobic content.</li>
      </ul>
      <p>Failure to accomplish with any of the previous rules would mean the deletion of the inappropriate content and the user account under which they were published.</p>
    </div>
  </div>
</div>
