import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { EventService } from '../services/event.service';
import { VenueService } from '../services/venue.service';
import { OrganizerService } from '../services/organizer.service';
import { UtilsService } from '../services/utils.service';
import { Venue } from '../models/venue';
import { Event } from '../models/event';
import { Organizer } from '../models/organizer';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {

  venues: Observable<Venue[]>;
  superEvents: Observable<Event[]>;
  organizers: Observable<Organizer[]>;
  environment: any;
  pxHeight: number;

  feedForm = new FormGroup({
    filter: new FormControl('all', []),
    numEvents: new FormControl(10, [Validators.max(999), Validators.min(1), Validators.required]),
    organizers: new FormControl('', []),
    superEvents: new FormControl('', []),
    template: new FormControl(environment.templates[0]?.id || ''),
    venues: new FormControl('', [])
  });

  iframeURL: string = environment.siteURL + '/iframe?';
  iframeURLsSafe: SafeResourceUrl[] = [];
  query: string = '';
  code: string = '';

  graphQLURLSafe: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.siteURL + '/graphQL');

  constructor(private eventService: EventService,
    public organizerService: OrganizerService,
    public venueService: VenueService,
    public sanitizer: DomSanitizer,
    public utilsService: UtilsService,
    public translate: TranslateService) { }

  ngOnInit() {
    this.environment = environment;
    this.organizers = this.organizerService.getAllOrganizers();
    this.venues = this.venueService.getAllVenues();
    this.superEvents = this.eventService.getAllSuperEvents();
    this.updateCode();
  }

  updateCode() {
    this.code = '';
    this.iframeURLsSafe = [];
    this.query = '';
    let filter = this.feedForm.controls["filter"].value;
    let org = this.feedForm.controls["organizers"].value;
    let venue = this.feedForm.controls["venues"].value;
    let fest = this.feedForm.controls["superEvents"].value;
    if (filter == 'organizer' && org) {
      this.query += '&organizerSlug=' + org;
    }
    if (filter == 'venue' && venue) {
      this.query += '&venueSlug=' + venue;
    }
    if (filter == 'superEvent' && fest) {
      this.query += '&superEvent=' + fest;
    }
    this.query += '&template=' + this.feedForm.controls.template.value;
    const templates = environment.templates.filter(t => t.id == this.feedForm.controls.template.value);
    const multiIframe = templates?.length > 0 && templates[0].multiIframe;
    if (!multiIframe || this.feedForm.controls.numEvents.value == 1) {
      this.pxHeight = this.feedForm.controls.numEvents.value * 250 + 100;
      this.query += '&page=0&pageSize=' + this.feedForm.controls.numEvents.value;
      this.code = `<iframe style="height: ${this.pxHeight}px; width: 100%;" frameborder="0" src="${this.iframeURL + this.query}"></iframe>`;
      this.iframeURLsSafe = [this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURL + this.query)];
    } else {
      for (let i = 0; i < this.feedForm.controls.numEvents.value; i++) {
        this.code += `<iframe style="width: 100%;" frameborder="0" src="${this.iframeURL + this.query + '&page=' + i + '&pageSize=1'}"></iframe>`;
        this.iframeURLsSafe.push(this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURL + this.query + '&page=' + i + '&pageSize=1'));
      }
    }
  }

}
