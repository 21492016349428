<div class="wrapper">
    <a class="margin-bottom back" mat-raised-button routerLink="/my-teams">
        <mat-icon>chevron_left</mat-icon><span>{{'My Teams' | translate}}</span>
    </a>
    <div *ngIf="team" class="team mat-elevation-z1">
        <h2>{{'Edit team' | translate }}</h2>
        <form class="margin-top" [formGroup]="editTeamForm" (ngSubmit)="saveTeam()">
            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">

                <mat-form-field fxFill appearance="outline">
                    <mat-label translate>{{'Team name' | translate}}</mat-label>
                    <input type="text" required formControlName="name" matInput>
                    <mat-error *ngIf="editTeamForm.hasError('required',['name'])">
                        {{'The team name is required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFill appearance="outline">
                    <mat-label translate>{{'Team editors' | translate}}</mat-label>
                    <mat-chip-list #teamEditorsList aria-label="Team editors" formArrayName="users">
                        <mat-chip [selected]="isAdmin(teamEditorEmail)" [removable]="!isAdmin(teamEditorEmail)"
                            (removed)="removeTeamEditor(teamEditorEmail)"
                            *ngFor="let teamEditorEmail of users_teamsControls.value">
                            {{teamEditorEmail}} {{isAdmin(teamEditorEmail) ? '(admin)' : ''}}
                            <mat-icon matChipRemove *ngIf="!isAdmin(teamEditorEmail)">cancel</mat-icon>
                        </mat-chip>
                        <input [placeholder]="'New team editor...' | translate" [matChipInputFor]="teamEditorsList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true"
                            (matChipInputTokenEnd)="addTeamEditor($event)">
                    </mat-chip-list>
                    <mat-hint align="end" translate>Introduce the email addresses of the team editors</mat-hint>
                </mat-form-field>

                <mat-form-field fxFill appearance="outline">
                    <mat-label translate>{{'Event editors' | translate}}</mat-label>
                    <mat-chip-list #eventEditorsList aria-label="Event editors" formArrayName="users">
                        <mat-chip [selected]="isAdmin(eventEditorEmail)" [removable]="!isAdmin(eventEditorEmail)"
                            (removed)="removeEventEditor(eventEditorEmail)"
                            *ngFor="let eventEditorEmail of usersControls.value">
                            {{eventEditorEmail}} {{isAdmin(eventEditorEmail) ? '(admin)' : ''}}
                            <mat-icon matChipRemove *ngIf="!isAdmin(eventEditorEmail)">cancel</mat-icon>
                        </mat-chip>
                        <input [placeholder]="'New event editor...' | translate" [matChipInputFor]="eventEditorsList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true"
                            (matChipInputTokenEnd)="addEventEditor($event)">
                    </mat-chip-list>
                    <mat-hint align="end" translate>Introduce the email addresses of the event editors</mat-hint>
                </mat-form-field>

                <mat-form-field fxFill appearance="outline" *ngIf="environment.ui.showOnForm.rsvp">
                    <mat-label translate>{{'Booking editors' | translate}}</mat-label>
                    <mat-chip-list #bookingEditorsList aria-label="Booking editors" formArrayName="users_bookings">
                        <mat-chip [selected]="isAdmin(bookingEditorEmail)" [removable]="!isAdmin(bookingEditorEmail)"
                            (removed)="removeBookingEditor(bookingEditorEmail)"
                            *ngFor="let bookingEditorEmail of users_bookingsControls.value">
                            {{bookingEditorEmail}} {{isAdmin(bookingEditorEmail) ? '(admin)' : ''}}
                            <mat-icon matChipRemove *ngIf="!isAdmin(bookingEditorEmail)">cancel</mat-icon>
                        </mat-chip>
                        <input [placeholder]="'New booking editor...' | translate"
                            [matChipInputFor]="bookingEditorsList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            matChipInputAddOnBlur="true" (matChipInputTokenEnd)="addBookingEditor($event)">
                    </mat-chip-list>
                    <mat-hint align="end" translate>Introduce the email addresses of the booking editors</mat-hint>
                </mat-form-field>
            </div>
        </form>
        <div class="margin-top" fxLayoutAlign="end center" fxLayoutGap="20px">
            <button mat-stroked-button *ngIf="(authService.user | async)?.uid == team?.admin?.uid" color="warn"
                (click)="deleteTeam()">{{'Delete' | translate}}</button>
            <button mat-raised-button color="primary" (click)="saveTeam()" type="submit">{{'Save' | translate}}</button>
        </div>
    </div>
</div>