import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';

import { Filter } from '../models/filter';
import { Event } from '../models/event';

import { EventService } from './event.service';
import moment from 'moment';
import { first, map } from 'rxjs/operators';

const EVENT_LIST = makeStateKey('eventList');
const USE_CACHED_LIST = makeStateKey('useCachedList');
const FILTER = makeStateKey('filter');
const SEARCH_PAGE = makeStateKey('searchPage');
const MORE_AVAILABLE = makeStateKey('moreAvailable');
const TOTAL_HITS = makeStateKey('totalHits');

@Injectable({
  providedIn: 'root'
})
export class EventListService {

  //Number of events per block. Used on the pagination
  moreAvailable: boolean = true;
  totalHits: number = 0;
  searchPage: number = 0;

  filter: Filter = new Filter();
  lastChecked: any;

  listChanges$: BehaviorSubject<Event[]> = new BehaviorSubject(null);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadMoreIsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  filteredList: Event[] = null;
  useCachedList: boolean = false;

  constructor(
    private eventService: EventService,
    private state: TransferState,
    @Inject(PLATFORM_ID) private platformId: any) {
    //Setup the SSR cache logic
    this.initCacheOptions();
  }

  initCacheOptions() {
    this.useCachedList = this.state.get<boolean>(USE_CACHED_LIST, false);
    this.filteredList = this.state.get<Event[]>(EVENT_LIST, null);
    this.filter = Filter.fromObject(this.state.get<Filter>(FILTER, new Filter()));
    this.moreAvailable = this.state.get<boolean>(MORE_AVAILABLE, true);
    this.totalHits = this.state.get<number>(TOTAL_HITS, 0);
    this.searchPage = this.state.get<number>(SEARCH_PAGE, 0);
  }

  moreEventsAvailable() {
    return this.moreAvailable;
  }

  getTotalHits() {
    return this.totalHits;
  }

  getFrontPageEvents(filter: Filter, applyPagination: boolean): Observable<Event[]> {
    //Setup the searchpage if necessary
    this.searchPage = applyPagination ? this.searchPage + 1 : 0;
    if (isPlatformServer(this.platformId)) {
      this.state.set<number>(SEARCH_PAGE, this.searchPage);
    }

    return this.eventService.getEvents(filter, this.searchPage).pipe(
      map((results: any) => {
        if (results && results.data) {
          this.totalHits = results.data.events?.totalCount || 0;
          this.moreAvailable = results.data.events?.hasMore || false;
          if (isPlatformServer(this.platformId)) {
            this.state.set<boolean>(MORE_AVAILABLE, this.moreAvailable);
            this.state.set<number>(TOTAL_HITS, this.totalHits);
          }
        }
        return results;
      }),
      this.eventService.getTransformGraphQLEventsWithPagination()
    );
  }

  //This function is called after an event is updated, so the user can see an updated list
  //with his own changes
  public listenAndRefresh() {
    if (isPlatformBrowser(this.platformId)) {
      this.lastChecked = moment();
      this.eventService.getCache().subscribe(
        (cache: any) => {
          const lastChange = moment(cache.lastChange);
          if (lastChange.isAfter(this.lastChecked)) {
            this.lastChecked = lastChange;
            //Purge the local cache to fetch the events
            this.filterEvents(this.filter, true);
          }
        }
      );
    }
  }

  //Match the new filter to the current filter and apply the changes if it applies
  public filterEvents(newFilter: Filter, purgeCache: boolean = false) {
    if (this.useCachedList) {
      this.state.set<boolean>(USE_CACHED_LIST, false);
      this.useCachedList = false;
      return;
    }
    if (!this.filter.isEqual(newFilter) || this.filteredList == null || purgeCache) {
      this.isLoading$.next(true);
      this.mergeEventList(this.getFrontPageEvents(newFilter, false), true);
      this.filter = newFilter.clone();
      if (isPlatformServer(this.platformId)) {
        this.state.set<Filter>(FILTER, this.filter);
      }
    }
    if (isPlatformServer(this.platformId)) {
      this.state.set<boolean>(USE_CACHED_LIST, true);
      this.useCachedList = true;
    }
  }

  private mergeEventList(eventList: Observable<Event[]>, resetList: boolean): void {
    eventList?.pipe(first())?.subscribe(
      (newList) => {
        if (resetList) {
          this.filteredList = newList;
        } else {
          //Merge the new events within the existing list
          let newListWithoutReps = newList.filter(event => !this.sameNameInPresentList(event));
          this.filteredList = this.filteredList.concat(newListWithoutReps);
        }
        this.isLoading$.next(false);
        this.loadMoreIsLoading$.next(false);
        this.listChanges$.next(this.filteredList);
        if (isPlatformServer(this.platformId)) {
          this.state.set<Event[]>(EVENT_LIST, this.filteredList);
        }
      }
    );
  }

  private sameNameInPresentList(event: Event): boolean {
    for (let presentEvent of this.filteredList) {
      if (presentEvent.title_nb == event.title_nb) {
        return true;
      }
    }
    return false;
  }

  //Load more events applying the current filter
  public loadMoreEvents() {
    this.loadMoreIsLoading$.next(true);
    this.mergeEventList(this.getFrontPageEvents(this.filter, true), false);
  }

  public listChanges(): Observable<Event[]> {
    return this.listChanges$;
  }

  public listIsLoading(): Observable<boolean> {
    return this.isLoading$;
  }

  public loadMoreIsLoading(): Observable<boolean> {
    return this.loadMoreIsLoading$;
  }

}
