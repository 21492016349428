<div class="wrapper" *ngIf="(authService.user | async) as user">
  <div class="my-teams mat-elevation-z1">
    <div fxLayout="row" class="margin-bottom" fxLayoutAlign="space-between center">
      <h2 class="mat-title" translate>My teams</h2>
    </div>
    <div *ngIf="user.email">
      <div *ngIf="teams$ | async as teams">
        <div *ngIf="teams.length == 0" class="mat-body text-center">
          <span translate>You have no teams yet. Why don't you add your team</span>&nbsp;<a class="mat-body-strong" (click)="addTeam()"><span translate>here</span></a>?
        </div>
        <mat-list *ngIf="teams.length > 0">
          <app-my-teams-list-item *ngFor="let team of teams" [team]="team"></app-my-teams-list-item>
        </mat-list>
      </div>
      <div *ngIf="!(teams$ | async)" class="loading">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
    <div *ngIf="!user.email">
      {{ 'You must provide an email address for your authentication to manage teams.' | translate}} <a class="blue" routerLink="/contact">{{ 'Please contact us.' | translate }}</a>
    </div>
    <div fxLayout="row" class="margin-top" fxLayoutAlign="end center">
      <a mat-raised-button color="primary" (click)="addTeam()"><span translate>Add a team</span></a>
    </div>
  </div>
</div>
