import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Observable, of, zip } from 'rxjs';

import { Firestore, QueryConstraint, QueryFieldFilterConstraint, QueryFilterConstraint, collection, collectionData, or, query, where } from '@angular/fire/firestore';
import { LocalStorageService } from 'ngx-localstorage';
import { map } from 'rxjs/operators';
import { Event } from '../models/event';
import { isPlatformBrowser } from '@angular/common';



@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  //TODO Declare an observable to show the number of favorites to the user in the top bar

  private firestore: Firestore = inject(Firestore);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private storageService: LocalStorageService) { }

  getFavSlugs(): string[] {
    if (isPlatformBrowser(this.platformId)) {
      let favSlugs: string[] = [];
      const rawSlugs = this.storageService.get('favslugs', 'hvaskjerkalender');
      if (rawSlugs?.length > 0) {
        favSlugs = rawSlugs.split(",");
      }
      return favSlugs;
    }
    return [];
  }

  setFavSlugs(favSlugs: string[]) {
    if (isPlatformBrowser(this.platformId)) {
      this.storageService.set('favslugs', favSlugs?.join(',') || '', 'hvaskjerkalender');
    }
  }

  isFavorite(eventSlug: string): boolean {
    if (isPlatformBrowser(this.platformId)) {
      let favSlugs: string[] = this.getFavSlugs();
      return favSlugs.indexOf(eventSlug) != -1;
    }
    return false;
  }

  addFavSlug(eventSlug: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const favSlugs: string[] = this.getFavSlugs();
      favSlugs.push(eventSlug);
      this.setFavSlugs(favSlugs);
    }
  }

  removeFavSlug(eventId: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const favSlugs: string[] = this.getFavSlugs();
      const favIndex: number = favSlugs.indexOf(eventId);
      favSlugs.splice(favIndex, 1);
      this.setFavSlugs(favSlugs);
    }
  }

  toggle(eventSlug: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isFavorite(eventSlug)) {
        this.removeFavSlug(eventSlug);
      } else {
        this.addFavSlug(eventSlug);
      }
    }
  }

  getFavorites(): Observable<any[]> {
    if (isPlatformBrowser(this.platformId)) {
      const favSlugs = this.getFavSlugs();
      if (favSlugs?.length > 0) {
        const colRef = collection(this.firestore, "events");
        const favSlugsArrays = [];
        const chunkSize = 30;
        for (let i = 0; i < favSlugs.length; i += chunkSize) {
            const chunk = favSlugs.slice(i, i + chunkSize);
            favSlugsArrays.push(chunk);
        }
        const ws: QueryConstraint[] = favSlugsArrays.map((chunkedFavs: string[]) => where('event_slug', 'in', chunkedFavs)); 
        const arrayQueries = ws.map((qfC: QueryConstraint) => collectionData(query(colRef, qfC), { idField: "id" }));
       
        return zip(...arrayQueries).pipe(
          map((aEvents: Event[][]) => {
            console.log(aEvents);
            return aEvents.reduce((accumulator, value) => accumulator.concat(value), []).sort((e1, e2) => e1.startDate?.seconds > e2.startDate?.seconds ? 1 : -1);
          })
        );
      } else {
        return of([]);
      }
    }
    return of([]);
  }
}
