<div class="wrapper">
  <div *ngIf="environment.contact.email" class="contact page mat-elevation-z1">
    <h1 class="mat-h1" translate>Contact</h1>
    <div class="margin-bottom">
      <span translate>You can write us to</span>&nbsp;<a class="blue" [href]="'mailto:' + environment.contact.email">{{environment.contact.email}}</a>&nbsp;<span translate>or use the form below</span>:
    </div>
    <form [formGroup]="contactForm">
      <div fxLayout="column" fxLayoutAlign="end center">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label translate>Your email</mat-label>
          <input type="email" required matInput formControlName="email">
          <mat-hint align="end"><span translate>Email address so you we can contact you</span></mat-hint>
          <mat-error *ngIf="contactForm.hasError('required',['email'])" translate>
            The email is required
          </mat-error>
          <mat-error *ngIf="contactForm.hasError('email',['email'])" translate>
            You must write a valid email address
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width margin-top" appearance="outline">
          <mat-label translate>Your message</mat-label>
          <textarea required cdkTextareaAutosize cdkAutosizeMinRows="10" #message maxlength="1000" matInput formControlName="message"></textarea>
          <mat-hint align="end">{{message.value.length}} / 1000</mat-hint>
          <mat-error *ngIf="contactForm.hasError('required',['message'])" translate>
            The message is required
          </mat-error>
          <mat-error *ngIf="contactForm.hasError('maxlength',['message'])" translate>
            Maximum length is 1000 chars
          </mat-error>
        </mat-form-field>
        <div class="full-width text-right margin-top">
          <button [disabled]="formIsWorking" mat-raised-button color="primary" type="button" (click)="send()"><span translate *ngIf="!formIsWorking">Send</span><span translate *ngIf="formIsWorking">Sending...</span></button>
        </div>
      </div>
    </form>
    <div class="agreement"><span translate>By contacting us, you are agreeing to our</span>&nbsp;<a class="blue" routerLink="/privacy-policy"><span translate>privacy policy</span></a></div>
  </div>
  <div *ngIf="environment.contact.custom" class="contact page mat-elevation-z1 custom-contact">
    <h1 class="mat-h1" translate>Contact</h1>
    <div [innerHTML]="environment.contact.custom"></div>
  </div>
</div>
