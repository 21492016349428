import { Injectable, inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { TicketType } from '../models/ticket-type';
import allTicketTypes from '../../assets/data/ticketTypes.json';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Price } from '../models/price';
import { Ticket } from '../models/booking';
import { Firestore, collection, collectionData, doc, setDoc } from '@angular/fire/firestore';

@Injectable()
export class TicketTypeService {

  private firestore: Firestore = inject(Firestore);
  constructor(private translate: TranslateService) { }

  //Returns the list of ticket types ordered by the current language
  getAllTicketTypes(): Observable<TicketType[]> {
    const lanName = "name_" + (this.translate.currentLang || 'nb');
    return collectionData(collection(this.firestore, 'ticketTypes')).pipe(
      map((ticketTypes: TicketType[]) => {
        let sortedTTs: TicketType[] = Object.keys(allTicketTypes)
          .map(function (k) { return { id: k, name: allTicketTypes[k][lanName], name_nb: allTicketTypes[k].name_nb, name_en: allTicketTypes[k].name_en }; });
        return [...sortedTTs, ...ticketTypes.map((tt) => ({ ...tt, name: tt[lanName] }))].sort((a, b) => {
          return a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1;
        });
      })
    );
  }

  getTicketTypeName(tt: Price | Ticket): string {
    const lanName = "name_" + (this.translate.currentLang || 'nb');
    return tt[lanName] || (allTicketTypes[tt.type] && allTicketTypes[tt.type][lanName]) || '';
  }

  addTicketType(tt: TicketType) {
    return setDoc(doc(this.firestore, "ticketTypes", tt.id), {...tt});
  }
}
