import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Booking } from '../models/booking';
import { BookingsStats, Event } from '../models/event';

@Component({
  selector: 'app-bookings-stats',
  templateUrl: './bookings-stats.component.html',
  styleUrls: ['./bookings-stats.component.scss']
})
export class BookingsStatsComponent implements OnInit, OnChanges {

  @Input()
  events: Event[];

  //Sales
  bookingsLength: number;
  adminBookings: number;
  guestsBookings: number;
  booked: number;
  available: number;
  checkedIn: number;
  totalSales: number;
  waitingList: number;

  loading: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    //Only execute when there is a change
    this.setupStats(
      changes.events?.currentValue || this.events );
  }

  setupStats(events: Event[]) {
    const bookingsStats = events?.map(e => e.bookingsStats) || [];
    console.log("setupStats", events, bookingsStats);
    if (bookingsStats?.length > 0) {
      this.bookingsLength = bookingsStats.reduce((pv, cv) => pv + (cv?.bookingsCount || 0), 0);
      this.adminBookings = bookingsStats.reduce((pv, cv) => pv + (cv?.adminBookings || 0), 0)
      this.guestsBookings = this.bookingsLength - this.adminBookings;
      this.waitingList = bookingsStats.reduce((pv, cv) => pv + (cv?.waitingListTickets || 0), 0);
      this.booked = bookingsStats.reduce((pv, cv) => pv + (cv?.bookedTickets || 0), 0);
      this.available = bookingsStats.reduce((pv, cv) => pv + (cv?.availableTickets || 0), 0);
      this.checkedIn = bookingsStats.reduce((pv, cv) => pv + ((cv?.attendedTickets + cv?.partiallyAttended) || 0), 0);
      this.totalSales = bookingsStats.reduce((pv, cv) => pv + (cv?.totalSales || 0), 0);
      this.loading = false;
    } else {
      this.loading = false;
      this.bookingsLength = 0;
      this.adminBookings = 0;
      this.guestsBookings = 0;
      this.booked = 0;
      this.available = 0;
      this.checkedIn = 0;
      this.waitingList = 0;
      this.totalSales = 0;
    }
  }
}
