import { Injectable, inject } from '@angular/core';

import { from, of } from 'rxjs';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DocumentSnapshot, Firestore, Query, addDoc, collection, collectionData, deleteDoc, doc, getDoc, query, updateDoc, where } from '@angular/fire/firestore';

import { Event } from '../models/event';
import { Organizer } from '../models/organizer';

import { UtilsService } from '../services/utils.service';

@Injectable()
export class OrganizerService {

  includeID = map((action: any) => {
    if (action.payload.data()) {
      return {
        id: action.payload.id,
        ...action.payload.data()
      }
    }
  });

  includeIDArray = map((actions: any) =>
    actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id;
      return {
        id,
        ...data,
      };
    })
  );

  orderOrganizersByName = map((organizers: Organizer[]) => organizers.sort((a, b) => { return a.name < b.name ? -1 : 1; }));

  //Returns an organizer which name is at least 90% similar to the search term
  filterOrganizersByNameSimilarity = (orgNames: string[]) => map((organizers: Organizer[]) => {
    if (orgNames?.length > 0) {
      return orgNames.map(orgName => {
        let maxSimilarityOrganizer = organizers.reduce((prev, current) => {
          return (this.utilsService.similarity(prev.name.toLowerCase(), orgName.toLowerCase()) > this.utilsService.similarity(current.name.toLowerCase(), orgName.toLowerCase())) ? prev : current
        }) //returns object
        if (this.utilsService.similarity(maxSimilarityOrganizer.name.toLowerCase(), orgName.toLowerCase()) >= 0.9) {
          return maxSimilarityOrganizer;
        }
      });
    }
    return null;
  });

  private firestore: Firestore = inject(Firestore);

  constructor(
    private utilsService: UtilsService) { }

  //Organizers
  getAllOrganizers(): Observable<any> {
    return from(collectionData(collection(this.firestore, "organizers"), { idField: "id" })).
      pipe(
        this.orderOrganizersByName
      );
  }

  getOrganizerByEvent(event: Observable<Event>): Observable<Organizer> {
    return event
      .pipe(
        mergeMap((eventIn: Event) =>
          from(getDoc(doc(this.firestore, "organizers", eventIn.organizers[0].organizer)).then((snap: DocumentSnapshot<Organizer>) => ({ id: snap.id, ...snap.data() })))
        )
      );
  }

  getOrganizerBySlug(organizerSlug: string): Observable<Organizer> {
    const colRef = collection(this.firestore, "organizers");
    const w = where('slug', '==', organizerSlug);
    const q = query(colRef, w);
    return collectionData(q, { idField: "id" }).pipe(map((organizers: Organizer[]) => organizers[0]));
  }

  getOrganizersFromOrganizerNames(organizerNames: string[]): Observable<Organizer[]> {
    return collectionData(collection(this.firestore, "organizers"), { idField: "id" })
      .pipe(
        this.filterOrganizersByNameSimilarity(organizerNames)
      );
  }

  addOrganizer(organizer: Organizer) {
    const colRef = collection(this.firestore, "organizers");
    return addDoc(colRef, organizer);
  }

  updateOrganizer(orgID: string, orgFields: any): void {
    const docRef = doc(this.firestore, "organizers", orgID);
    updateDoc(docRef, { ...orgFields });
  }

  deleteOrganizer(organizerId: string): Promise<any> {
    const docRef = doc(this.firestore, "organizers", organizerId);
    return deleteDoc(docRef);
  }

}
