import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Campaign } from '../models/campaign';
import { addDoc, collection, collectionData, deleteDoc, doc, Firestore, getDoc, limit, orderBy, query, updateDoc } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { environment } from '../../environments/environment';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  private firestore: Firestore = inject(Firestore);
  private functions: Functions = inject(Functions);
  
  constructor() { }

  //GETTERS
  getLastCampaigns(): Observable<Campaign[]> {
    const colRef = collection(this.firestore, 'campaigns');
    const ref = orderBy('scheduledAt', 'desc');
    const lim = limit(10);
    const q = query(colRef, ref, lim);
    return collectionData(q, { idField: "id" });
  }

  async getCampaign(campaignId: string): Promise<Campaign> {
    const docRef = doc(this.firestore, 'campaigns', campaignId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? { id: campaignId, ...docSnap.data() } : null;
  }

  //SETTERS
  generateCampaign(): Observable<any> {
    //Get the next day at 6am
    const scheduledAt = moment({ hours: 6, minutes: 0, seconds: 0 }).add(1, 'day');
    const colRef = collection(this.firestore, 'campaigns');
    return from(addDoc(colRef, {
      name: environment.content.siteName + " - " + moment().format('LLL'),
      scheduledAt: scheduledAt.toDate(),
      status: 'generating'
    }));
  }

  stopCampaign(campaignId: string): Observable<any> {
    const docRef = doc(this.firestore, 'campaigns', campaignId);
    return from(updateDoc(docRef, { status: 'stopping' }));
  }

  resumeCampaign(campaignId: string): Observable<any> {
    const docRef = doc(this.firestore, 'campaigns', campaignId);
    return from(updateDoc(docRef, { status: 'resuming' }));
  }

  updateCampaign(campaignId: string, fields: any): Observable<any> {
    const docRef = doc(this.firestore, 'campaigns', campaignId);
    return from(updateDoc(docRef, fields));
  }

  deleteCampaign(campaignId: string): Observable<any> {
    const docRef = doc(this.firestore, 'campaigns', campaignId);
    return from(deleteDoc(docRef));
  }

  //ACTIONS
  sendTestEmail(campaignId: string, testEmails: string[]) {
    const callable = httpsCallable(this.functions, 'sendTestCampaign');
    return from(callable({ campaignId, testEmails }));
  }

  //Update the metrics for the last 10 campaigns
  updateCampaignsStatistics() {
    const callable = httpsCallable(this.functions, 'updateLastCampaigns');
    return from(callable({  }));
  }
}
