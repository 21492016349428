<div class="wide-wrapper">
    <div class="page mat-elevation-z1" fxLayout="column" fxLayoutGap="100px">
        <div>
            <h1 class="mat-h1" translate>Admin booking</h1>
            <div *ngIf="loading" class="loading">
                <mat-spinner></mat-spinner>
            </div>
            <div *ngIf="!loading" #form>
                <app-customer-form-selector class="margin-bottom" [customerForm]="customerForm"
                    [toucher]="toucher"></app-customer-form-selector>

                <div id="general-errors" class="margin-bottom margin-top">
                    <div class="error-repeated-booking" *ngIf="errorRepeatedEvent">{{'Two or more bookings are for the
                        same event date and venue' | translate}}</div>
                </div>
                <div fxLayout="column" fxLayoutGap="50px">
                    <app-admin-booking-row *ngFor="let bookingForm of bookingsArrayForm.controls; let i = index;"
                        [bookingForm]="bookingForm" [index]="i" [toucher]="toucher" [tickets]="i > 0 ? bookingsArrayForm.get([i-1, 'tickets']).value : null"
                        [selectedEvent]="i == 0 ? selectedEvent : null"
                        (onDelete)="onDelete(i)"></app-admin-booking-row>
                </div>
            </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!loading">
            <button mat-stroked-button (click)="addBooking()"><mat-icon>add_circle</mat-icon> {{'Add another booking' |
                translate}}</button>
            <button mat-raised-button color="primary" [disabled]="disabledConfirm" (click)="confirmBookings()">{{'Confirm bookings' |
                translate}}</button>
        </div>
    </div>
</div>