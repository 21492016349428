import { Injectable, Inject, PLATFORM_ID, inject } from '@angular/core';

import { Auth, authState, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithRedirect, signOut, getRedirectResult, AuthProvider, reauthenticateWithRedirect, reauthenticateWithCredential, AuthCredential } from '@angular/fire/auth';
import { collection, collectionData, Firestore, doc, updateDoc, addDoc, query, where, getDoc } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

import { User } from '../models/user';
import { Event } from '../models/event';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';

import { RollbarService } from '../modules/rollbar';
import Rollbar from 'rollbar';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class AuthService {

  user: Observable<User>;
  private auth: Auth = inject(Auth);
  private firestore: Firestore = inject(Firestore); // inject Cloud Firestore

  constructor(
    private router: Router,
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.user = authState(this.auth);
    }
  }

  signup(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  loginWithProvider(provider: any) {
    return signInWithRedirect(this.auth, provider);
  }

  fetchSignInMethodsForEmail(email: string) {
    return fetchSignInMethodsForEmail(this.auth, email);
  }

  reset(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  signOut() {
    return signOut(this.auth).then(() => this.router.navigate(['']));
  }

  changeLanguage(newCode: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.auth.languageCode = newCode;
    }
  }

  onAuthStateChanged(handler: any) {
    this.auth.onAuthStateChanged(handler);
  }

  getCurrentUser(): Observable<User> {
    return this.user;
  }

  getRedirectResult() {
    if (isPlatformBrowser(this.platformId)) {
      return getRedirectResult(this.auth);
    }
    return null;
  }

  //USER INFO
  public getUserInfo(userId: string): Observable<User | boolean> {
    if (userId) {
      const docRef = doc(this.firestore, `users/${userId}`);
      return from(getDoc(docRef)).pipe(
        map(docSnap => docSnap.exists() ? docSnap.data() : false),
        catchError((error) => {
          this.rollbar.error('Error getUserInfo', error.toString(), "user id: ", userId);
          return of(false);
        })
      );
    } else {
      return of(false);
    }
  }

  public getUserInfoByEmail(email: string): Observable<User | null> {
    if (email) {
      const collectionRef = collection(this.firestore, 'users');
      const q = query(collectionRef, where('email', '==', email));
      return collectionData(q)
        .pipe(
          map((users: User[]) => users && users.length > 0 ? users[0] : null)
        );
    } else {
      return of(null);
    }
  }

  public getCurrentUserInfo(): Observable<User | boolean> {
    return (this.getCurrentUser() || of(null)).pipe(
        switchMap((user: User) => user ? this.getUserInfo(user.uid) : of(false))
      );
  }

  reauthenticateWithRedirect(userIn, provider: AuthProvider): Promise<any> {
    return reauthenticateWithRedirect(userIn, provider);
  }

  reauthenticateWithCredential(userIn, provider: AuthCredential): Promise<any> {
    return reauthenticateWithCredential(userIn, provider);
  }

  public storeUserPreferences(storedEvent: Event): void {
    this.getCurrentUser().subscribe(
      (user: any) => {
        if (user?.uid) {
          let updatedUserPreferences: User = {
            lastUsedOrganizer: storedEvent.organizers[0].organizer,
            lastUsedEditableBy: storedEvent.editableBy
          };
          if (storedEvent?.venue && storedEvent?.venueNote) {
            let newVenueNotes = {};
            newVenueNotes[storedEvent.venue] = storedEvent.venueNote;
            updatedUserPreferences = {
              ...updatedUserPreferences,
              usedVenueNotes: newVenueNotes
            };
          }
          const docRef = doc(this.firestore, `users/${user.uid}`);
          updateDoc(docRef, { ...updatedUserPreferences });
        }
      });
  }

  public gdprRequestDelete(uid: string, userEmail: string, toEmail: string, deleteCreatedContent: boolean) {
    const reqCollectionRef = collection(this.firestore, `gdprRequests/${uid}/requests`);
    const request = {
      user_account_email: userEmail,
      send_to_email: toEmail,
      type: deleteCreatedContent ? 'delete_with_data' : 'delete',
      request_date: new Date()
    };
    return addDoc(reqCollectionRef, request);
  }

  public gdprRequestExport(uid: string, userEmail: string, toEmail: string) {
    const reqCollectionRef = collection(this.firestore, `gdprRequests/${uid}/requests`);
    const request = {
      user_account_email: userEmail,
      send_to_email: toEmail,
      type: 'export',
      request_date: new Date()
    };
    return addDoc(reqCollectionRef, request);
  }

  setFacebookPageAccessToken(authResponse) {
    const callable = httpsCallable(inject(Functions), 'setFacebookPageAccessToken');
    return callable({ userAccessToken: authResponse.accessToken, userID: authResponse.userID });
  }

}
