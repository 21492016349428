<div fxLayout="column" fxLayoutGap="15px" *ngIf="isAdmin">
    <mat-slide-toggle *ngIf="action != 'admin-send-sms'" [formControl]="notifyBySMSControl">{{'Notify the customer
        with an SMS' | translate}}. <strong *ngIf="notifyBySMSControl.value == true">{{ numberSMS + ' ' + ('SMS will be sent' | translate) }}</strong></mat-slide-toggle>
    <mat-form-field fxFlex="grow" appearance="outline" *ngIf="notifyBySMSControl.value || action == 'admin-send-sms'">
        <mat-label translate>SMS content</mat-label>
        <textarea type="text" required aria-label="SMS content" #sms matInput [formControl]="smsControl"></textarea>
        <mat-error *ngIf="smsControl.hasError('required')" translate>
            The content for the SMS is required
        </mat-error>
        <mat-hint align="end">{{'Insert' | translate}}: <a class="blue" (click)="insert('BOOKING_NUMBER')">{{'booking number' | translate}}</a>, <a class="blue" (click)="insert('CUSTOMER_NAME')">{{'customer name' | translate}}</a>, <a class="blue" (click)="insert('COMMENTS')">{{'customer comments' | translate}}</a>, <a class="blue" (click)="insert('EVENT_TITLE')">{{'event title' | translate}}</a>, <a class="blue" (click)="insert('START_DATE')">{{'event start date' | translate}}</a>, <a class="blue" (click)="insert('TICKETS_DESCRIPTION')">{{'tickets description' | translate}}</a> or <a class="blue" (click)="insert('TOTAL_AMOUNT')">{{'total amount' | translate}}</a></mat-hint>
    </mat-form-field>
</div>