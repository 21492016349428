<mat-form-field (click)="picker.open()" [formGroup]="eventsRange" appearance="outline" fxFlex="100"
    *ngIf="showEventsRange">
    <mat-label>{{'Events range' | translate}}</mat-label>
    <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="fromDate" [placeholder]="'From date' | translate">
        <input matEndDate formControlName="untilDate" [placeholder]="'To date' | translate">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker>
        <mat-datepicker-actions>
            <button mat-button matDatepickerCancel (click)="showEventsRange = false">{{'Cancel' | translate}}</button>
            <button mat-raised-button color="primary" matDatepickerApply>{{'Apply' |
                translate}}</button>
        </mat-datepicker-actions>
    </mat-date-range-picker>
    <mat-hint align="end"><a class="blue" (click)="showEventsRange = false">{{'Cancel' | translate}}</a></mat-hint>
</mat-form-field>

<mat-form-field appearance="outline" fxFlex="grow" *ngIf="!showEventsRange">
    <mat-label>{{ label || hint }}</mat-label>
    <mat-select [formControl]="eventFormControl" #event [compareWith]="instancesComparator">
        <mat-option>
            <ngx-mat-select-search trim="blur" [placeholderLabel]="'Type the event title' | translate"
                [formControl]="eventFilterCtrl"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngIf="(filteredInstances | async)?.length == 0" [value]="null">{{'No events
            found with
            this title' | translate}}</mat-option>

        <mat-option *ngIf="showAllMyEventsOption && (filteredInstances | async)?.length > 0" [value]="null">{{'All my
            events' | translate}}</mat-option>

        <mat-optgroup *ngFor="let instanceGroup of (filteredInstances | async)" [label]="instanceGroup.eventTitle">
            <mat-option *ngFor="let instance of instanceGroup.instances" [value]="instance">
                {{instance.startDate | firestoreTimestamp | date:'short':'CET':translate.currentLang}} @ {{instance.venueObj?.name ||
                instance.venue?.name || 'Online'}}
            </mat-option>
        </mat-optgroup>

    </mat-select>
    <mat-error *ngIf="eventFormControl?.hasError('required')">{{'You must select an event' | translate}}</mat-error>
    <mat-error *ngIf="eventFormControl?.hasError('already_booked')">{{'There is already a booking with this phone number for this event date and venue' | translate}}</mat-error>
    <mat-hint *ngIf="showRangeFilter && !event.value && eventsRange.get('fromDate').value && !eventsRange.get('untilDate').value">{{'Only
        upcoming events' | translate}} | <a class="blue" (click)="allEvents()">{{'All the events' | translate}}</a> | <a
            class="blue" (click)="showEventsRange = true">{{'Select a period' | translate}}</a></mat-hint>
    <mat-hint *ngIf="showRangeFilter && !event.value && !eventsRange.get('fromDate').value && !eventsRange.get('untilDate').value"><a
            class="blue" (click)="onlyUpcoming()">{{'Only upcoming events' | translate}}</a> | {{'All the events' |
        translate}} | <a class="blue" (click)="showEventsRange = true">{{'Select a period' | translate}}</a></mat-hint>
    <mat-hint *ngIf="showRangeFilter && !event.value && eventsRange.get('fromDate').value && eventsRange.get('untilDate').value"><a
            class="blue" (click)="onlyUpcoming()">{{'Only upcoming events' | translate}}</a> | <a class="blue"
            (click)="allEvents()">{{'All the events' | translate}}</a> | {{'Events from' | translate}}
        {{eventsRange.get('fromDate').value | date:'short':'CET':translate.currentLang}} {{'to' | translate}}
        {{(eventsRange.get('untilDate').value | date:'short':'CET':translate.currentLang) || ''}} (<a class="blue"
            (click)="showEventsRange = true">{{'change' | translate}}</a>)</mat-hint>
    <mat-hint align="end" *ngIf="event.value"><a class="blue" (click)="eventFormControl.reset()">{{'Clear' |
            translate}}</a></mat-hint>
</mat-form-field>