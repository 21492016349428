<div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>{{'Booking' | translate}} {{index + 1}} <a *ngIf="index > 0" class="remove" (click)="deleteBooking()">({{'remove' | translate}})</a></div>
        <mat-slide-toggle (change)="notifySMS($event)" checked>{{'Send SMS with confirmation' | translate }}</mat-slide-toggle>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="grow">
            <app-booking-event-selector [eventFormControl]="eventFormControl" [toucher]="toucher"></app-booking-event-selector>
        </div>
        <app-customer-comments-editor fxFlex="50" *ngIf="selectedInstance" [comments]="comments" [toucher]="toucher"
            (onCustomerCommentsChange)="onCustomerCommentsChange($event)" [hint]="''"
            (onValidityChange)="validityOnComponent($event, 1)"></app-customer-comments-editor>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="selectedInstance" fxLayoutGap="20px">
        <div class="box">
            <div class="box-label">{{'Ticket selection' | translate}}</div>
            <app-ticket-selector [eventId]="selectedInstance.id" [startDateSeconds]="selectedInstance.startDate?.seconds" [tickets]="tickets" [toucher]="toucher"
            [venueName]="selectedInstance.venueObj?.name" inDoorCheckinMode="true" (onTicketsChange)="onTicketsChange($event)"
            (onValidityChange)="validityOnComponent($event, 2)"></app-ticket-selector>
        </div>
        <app-booking-status-editor *ngIf="currentStatus" [status]="currentStatus" [hint]="ticketsInfo" [toucher]="toucher" (onStatusChange)="onStatusChange($event)" (onValidityChange)="validityOnComponent($event, 3)"></app-booking-status-editor>        
    </div>
</div>