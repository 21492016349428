import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastActionStatus'
})
export class LastActionStatusPipe implements PipeTransform {

  transform(value: any[], ...args: string[]): string {
    if (value) {
      const actionStatus = value[value.length - 1].status;
      if (actionStatus == 'executing' || actionStatus == 'waiting-sms-response') {
        return args[0] == 'nb' ? 'Utfører' : 'Executing';
      }
      if (actionStatus == 'error') {
        return args[0] == 'nb' ? 'Feil' : 'Executing';
      }
      return '';
    }
    return '';
  }

}
