import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeamService } from '../services/team.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { User } from '../models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss']
})
export class AddTeamComponent implements OnInit {

  addTeamForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  formIsWorking: boolean = false;
  memberEmails: string[] = [];
  visible: boolean = true;
  selectable: boolean = true;
  addOnBlur: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  editTeam: boolean = false;
  adminEmail: string = '';
  currentUser: User;


  constructor(
    private translate: TranslateService,
    public authService: AuthService,
    public dialogRef: MatDialogRef<AddTeamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private teamService: TeamService,
    public snackBar: MatSnackBar) {
    if (data) {
      this.addTeamForm.controls["name"].setValue(data.team.name);
      this.memberEmails = data.team.users;
      this.adminEmail = this.data.team.admin.email;
    } else {
      let userSub = this.authService.getCurrentUserInfo().subscribe(
        (userInfo: User) => {
          if (userInfo) {
            this.currentUser = userInfo;
            this.memberEmails.push(userInfo.email);
            this.adminEmail = userInfo.email;
          }
          userSub.unsubscribe();
        }
      )
    }
  }

  ngOnInit() {

  }

  onCancel() {
    this.dialogRef.close();
  }

  //Prevent closing the dialog when the user press Enter focused on the "name" field
  preventClosing(e) {
    e.preventDefault();
  }

  isAdmin(memberEmail: string): boolean {
    return (!this.data && this.adminEmail == memberEmail)
          || (this.data && this.data.team.admin.email == memberEmail) ;
  }

  saveTeam() {
    this.formIsWorking = true;
    if (this.addTeamForm.valid) {
      this.teamService.updateTeam(this.data.team.id, { ... this.data.team, name: this.addTeamForm.value.name, users: this.memberEmails })
        .then(() => {
          this.formIsWorking = false;
          this.dialogRef.close();
          this.snackBar.open(this.translate.instant("Team saved successfully"), null, {
            duration: 4000,
          });
        })
        .catch(error => {
          this.formIsWorking = false;
          console.error(error);
          this.snackBar.open(this.translate.instant("There was an error while saving your team"), null, {
            duration: 4000,
          });
        });
    }
  }

  addTeam() {
    this.formIsWorking = true;
    if (this.addTeamForm.valid) {
      this.teamService.addTeam({ name: this.addTeamForm.value.name, admin: this.currentUser, editors: [this.currentUser.email], users: this.memberEmails, users_bookings: this.memberEmails })
        .then(docRef => {
          this.formIsWorking = false;
          this.dialogRef.close(docRef.id);
          this.snackBar.open(this.translate.instant("Team created successfully"), null, {
            duration: 4000,
          });
        })
        .catch(error => {
          this.formIsWorking = false;
          console.error(error);
          this.snackBar.open(this.translate.instant("There was an error while creating your team"), null, {
            duration: 4000,
          });
        });
    }
  }

  addMember(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add a member
    if ((value || '').trim() && this.isValidEmail(value)) {
      this.memberEmails.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeMember(memberEmail: string): void {
    const index = this.memberEmails.indexOf(memberEmail);

    if (index >= 0) {
      this.memberEmails.splice(index, 1);
    }
  }

  isValidEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
