import { Inject, Injectable, inject } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RollbarService } from '../modules/rollbar';
import Rollbar from 'rollbar';
import { Customer } from '../models/customer';
import { Firestore, collection, collectionData, deleteDoc, doc, getDoc, orderBy, query, updateDoc, addDoc, DocumentReference } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private firestore: Firestore = inject(Firestore);
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar) { }

  //GETTERS
  getAllCustomers(): Observable<Customer[]> {
    const ord = orderBy("name");
    const colRef = collection(this.firestore, 'customers');
    const q = query(colRef, ord);
    return from(collectionData(q, { idField: "id" })).pipe(
      catchError((error, _) => {
        this.rollbar.error('Error getAllCustomers', error);
        return of([]);
      })
    );
  }

  async getCustomer(id: string): Promise<Customer> {
    const docRef = doc(this.firestore, 'customers', id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? { id, ...docSnap.data() } : null;
  }

  //SETTERS
  async addCostumer(customer: Customer): Promise<Customer> {
    const docRef: DocumentReference<Customer> = await addDoc(collection(this.firestore, 'customers'), {...customer});
    return this.getCustomer(docRef.id);
  }

  updateCustomer(id: string, updatedCustomer: Customer) {
    if (id && updatedCustomer) {
      return updateDoc(doc(this.firestore, 'customers', id), { ...updatedCustomer });
    }
    return null;
  }

  deleteCustomer(customerId: string) {
    if (customerId) {
      return deleteDoc(doc(this.firestore, "customers", customerId));
    }
  }

}