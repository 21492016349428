<h2 mat-dialog-title translate>Add repetitions</h2>
<form [formGroup]="addRecurrenceForm">
  <div mat-dialog-content fxLayout="column">
    <mat-radio-group formControlName="type" fxFlex="45px" fxLayout="row" fxLayoutGap="20px">
      <mat-radio-button fxFlex="50" class="recurrence" value="recurrence">
        <div translate>Recurrence</div>
      </mat-radio-button>
      <mat-radio-button fxFlex="50" class="single" value="manual">
        <div translate>Manually</div>
      </mat-radio-button>
    </mat-radio-group>
    <mat-divider fxFlex="20px"></mat-divider>
    <div fxFlex="320px" fxLayout="column" *ngIf="addRecurrenceForm.controls['type'].value == 'recurrence'">
      <div fxFlex="40px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="custom">
        <div fxFlex="nogrow" class="label" translate>Repeat every</div>
        <div fxFlex="60px">
          <input class="every full-width" formControlName="every" type="number" matInput>
        </div>
        <div fxFlex="75px">
          <select formControlName="timeUnit" class="time-unit full-width">
            <option value="day"><span translate>day</span></option>
            <option value="week"><span translate>week</span></option>
          </select>
        </div>
      </div>
      <div class="error-field" *ngIf="addRecurrenceForm.hasError('min',['every']) || addRecurrenceForm.hasError('max',['every'])">
        {{'Frequency must be a number between 1 and 28' | translate }}
      </div>
      <div fxFlex="30px" fxLayout="row" fxLayoutAlign="start center" *ngIf="addRecurrenceForm.controls['timeUnit'].value == 'week'">
        <div translate class="label repeat">Repeat on</div>
      </div>
      <div fxFlex="40px" fxLayout="row" fxLayoutAlign="start center" *ngIf="addRecurrenceForm.controls['timeUnit'].value == 'week'">
        <mat-chip-list selectable="true" multiple="true">
          <mat-chip [selected]="monday"><a (click)="monday = !monday" translate>M</a></mat-chip>
          <mat-chip [selected]="tuesday"><a (click)="tuesday = !tuesday" translate>T</a></mat-chip>
          <mat-chip [selected]="wednesday"><a (click)="wednesday = !wednesday" translate>W</a></mat-chip>
          <mat-chip [selected]="thursday"><a (click)="thursday = !thursday" translate>T</a></mat-chip>
          <mat-chip [selected]="friday"><a (click)="friday = !friday" translate>F</a></mat-chip>
          <mat-chip [selected]="saturday"><a (click)="saturday = !saturday" translate>S</a></mat-chip>
          <mat-chip [selected]="sunday"><a (click)="sunday = !sunday" translate>S</a></mat-chip>
        </mat-chip-list>
      </div>

      <div fxFlex="50px" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div fxFlex="78px" class="label repeat" translate>Repeat at</div>
        <select fxFlex="90px" name="startTime" formControlName="startTime">
          <option *ngFor="let hour of hours" [value]="hour.value">
            {{hour.viewValue}}
          </option>
        </select>
      </div>

      <div fxFlex="30px" fxLayout="row" fxLayoutAlign="start center">
        <div translate class="label">Ends</div>
      </div>
      <mat-radio-group fxFlex="100px" fxLayout="column" class="ends-radio-group" formControlName="ends">
        <mat-radio-button fxFlex="44px" fxLayout="row" fxLayoutAlign="start center" class="ends-radio-button" value="on">
          <div fxFlex.xs="40px" fxFlex="60px" class="label" fxFlexAlign="center" translate>On</div>
          <div fxFlex.xs="95px" fxFlex="50">
            <input class="end-date" fxFlexAlign="center" (click)="pickerEnd.open()" (blur)="addRecurrenceForm.controls['ends'].setValue('on')" matInput [matDatepicker]="pickerEnd" [min]="minEndDate" [max]="maxDate" formControlName="endDate">
            <mat-datepicker-toggle class="suffix" matSuffix [for]="pickerEnd">
              <mat-icon matDatepickerToggleIcon>event</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
          </div>
        </mat-radio-button>
        <div class="error-field" *ngIf="addRecurrenceForm.hasError('matDatepickerMin',['endDate'])">
          {{'The recurrence can not end before the start date of the parent event plus one day' | translate }}
        </div>
        <div class="error-field" *ngIf="addRecurrenceForm.hasError('matDatepickerMax',['endDate'])">
          {{'The recurrence can not end later than one year' | translate }}
        </div>
        <mat-radio-button fxFlex="44px" fxLayout="row" fxLayoutAlign="start center" class="ends-radio-button" value="after">
          <div fxFlex.xs="40px" fxFlex="60px" class="label" fxFlexAlign="center" translate>After</div>
          <div fxFlex.xs="50px" fxFlex="60px">
            <input class="occurrences" (click)="addRecurrenceForm.controls['ends'].setValue('after')" formControlName="numOccurrences" type="number" matInput>
          </div>
          <div fxFlex="100px" class="label suffix" fxFlexAlign="center" translate>occurrences</div>
        </mat-radio-button>
        <div class="error-field" *ngIf="addRecurrenceForm.hasError('max',['numOccurrences']) || addRecurrenceForm.hasError('min',['numOccurrences']) ">
          {{'The number of occurrences must be between 1 and 365' | translate }}
        </div>
      </mat-radio-group>
    </div>

    <div formArrayName="singleDates" *ngIf="addRecurrenceForm.controls['type'].value == 'manual'" fxLayout="column">
      <div fxFlex="320px" fxLayout="column" class="manual">
        <div *ngFor="let manualRepetition of manualRepetitions.controls; index as i;" [formGroupName]="i">
          <app-manual-repetition [placeholderSingle]="placeholderSingle" [minDate]="minDate" [hours]="hours" [index]="i" [manualRepetitionForm]="manualRepetition" (onDateAdded)="onDateAdded()" (onDateRemoved)="onDateRemoved(i)">
          </app-manual-repetition>
        </div>
      </div>
      <div class="error-field" *ngIf="addRecurrenceForm.hasError('allDistinct',['singleDates'])">
        {{'All the dates and times must be unique' | translate }}
      </div>
      <div class="error-field" *ngIf="addRecurrenceForm.hasError('maxLength',['singleDates'])">
        {{'Maximum 150 manual dates at a time' | translate }}
      </div>
    </div>

  </div>
  <div align="end" mat-dialog-actions>
    <span class="fill-spacer"></span>
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" (click)="addRepetitions()"><span translate>Add</span></button>
  </div>
</form>
