<div class="selection">
    <a *ngIf="hasMarker" (click)="clearPoiMarker()">{{'Remove current selection' | translate}}</a>
</div>
<div class="super-container full-width">
    <div #myMapEl id='mazemap-container' aria-label="map view" tabindex="1" class="mazemap"></div>
    <div id="search-input-container" class='search-control-default'>
        <input tabindex="0" id="searchInput" class="search-input" autocomplete="off" type="text" name='search' [placeholder]="'Search' | translate">
        <!-- Avoid propagating default behaviour to the parent form -->
        <form>
            <div id="suggestions" class="search-suggestions default"></div>
        </form>
    </div>
</div>
