import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Customer } from '../models/customer';
import { CustomerService } from '../services/customer.service';

@Component({
  selector: 'app-customer-edition',
  templateUrl: './customer-edition.component.html',
  styleUrls: ['./customer-edition.component.scss']
})
export class CustomerEditionComponent implements OnInit {

  customer: Customer;
  isEdit: boolean = false;
  isLoading: boolean = true;
  sub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService) {
    this.isEdit = route.snapshot.data['isEdit'];
  }

  async ngOnInit() {
    const customerId = this.route.snapshot?.paramMap?.get('customerId');
    if (this.isEdit && customerId) {
      this.customer = await this.customerService.getCustomer(customerId);
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onExecutionDone() {
    this.router.navigate(['/customers']);
  }

}
