import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { EventService } from '../services/event.service';
import { Event } from '../models/event';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-my-events',
  templateUrl: './my-events.component.html',
  styleUrls: ['./my-events.component.scss']
})
export class MyEventsComponent implements OnInit {

  events: Observable<Event[]>;
  pastEvents: boolean = false;

  constructor(
    private eventService: EventService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {
    this.pastEvents = this.route.snapshot.data['pastEvents'] || false;
  }

  async ngOnInit() {
    this.authService.getCurrentUser().subscribe(
      (user: any) => {
        if (user) {
          this.events = this.eventService.getEventsEditableByUser(this.pastEvents, user.uid);
        }
      }
    )
  }

}
