import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';
import { Filter } from '../models/filter';
import { Event } from '../models/event';
import { Category } from '../models/category';
import { CategoryService } from '../services/category.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventListService } from '../services/event-list.service';

import { environment } from '../../environments/environment';
import { debounceTime, first } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ActivityTypeService } from '../services/activityType.service';
import { OrganizerService } from '../services/organizer.service';
import { Organizer } from '../models/organizer';
import { Venue } from '../models/venue';
import { VenueService } from '../services/venue.service';
import { RouterParamService } from '../services/router-param.service';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-smart-filter',
  templateUrl: './smart-filter.component.html',
  styleUrls: ['./smart-filter.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class SmartFilterComponent implements OnInit, OnDestroy {

  defaultFilter: Filter = new Filter();
  filter: Filter = new Filter();

  //View logic
  showPanel: boolean = false;
  omniFocused: boolean = false;


  //Controls
  omni: FormControl = new FormControl();
  filterForm = new FormGroup({
    activityTypes: new FormControl(),
    categories: new FormControl(),
    fromDate: new FormControl(),
    mode: new FormControl('all', []),    
    organizer: new FormControl(),
    sortBy: new FormControl(environment.ui.showOnFilter.sortBy, []),
    superEvent: new FormControl(),
    tag: new FormControl(),
    venue: new FormControl()
  });

  //RegEx
  fromRegExp: RegExp = new RegExp(/from:\d\d\/\d\d\/\d\d\d\d/g);
  catsRegExp: RegExp = new RegExp(/categories:([A-Z]|-|,|\d)*/gi);
  actTypesRegExp: RegExp = new RegExp(/activityTypes:([A-Z]|-|,|\d)*/gi);
  orgRegExp: RegExp = new RegExp(/organizer:([A-Z]|-|\d)*/gi);
  venueRegExp: RegExp = new RegExp(/venue:([A-Z]|-|\d)*/gi);
  sortByRegExp: RegExp = new RegExp(/sortBy:(popularity|date)*/gi);
  modeRegExp: RegExp = new RegExp(/mode:(all|offline|online)*/gi);
  superEventRegExp: RegExp = new RegExp(/superEvent:([A-Z]|\d)*/gi);
  tagRegExp: RegExp = new RegExp(/tag:([A-Z]|\d)*/gi);
  extraSpacesRegExp: RegExp = new RegExp(/ +/gi);

  //Subscriptions
  filterURLChange: Subscription;
  omniSubs: Subscription;
  catsSubs: Subscription;
  fromDateSubs: Subscription;
  sortBySubs: Subscription;
  modeSubs: Subscription;
  superEventSubs: Subscription;
  actTypesSubs: Subscription;
  orgSubs: Subscription;
  venueSubs: Subscription;
  tagSubs: Subscription;

  minDate: any = moment();

  //Fields data
  allCategories: Category[];
  allActivityTypes: Category[];
  organizers: Observable<Organizer[]>;
  venues: Observable<Venue[]>;
  allSuperEvents: Observable<Event[]>;

  environment: any;

  constructor(
    private translate: TranslateService,
    private eventListService: EventListService,
    private eventService: EventService,
    public categoryService: CategoryService,
    public activityTypeService: ActivityTypeService,
    private organizerService: OrganizerService,
    private venueService: VenueService,
    private routerParam: RouterParamService,
    private router: Router,
    private adapter: DateAdapter<any>) {
    this.environment = environment;
    // For the datepicker component
    this.setAdapterLocale(this.translate.currentLang);
  }

  ngOnInit() {
    //Init components and fetch data
    this.initializeComponents();
    //Init field listeners
    this.setupListeners();
    //Subscribe to the language change event
    this.translate.onLangChange.subscribe(() => {
      this.initializeComponents();
    });
    //Parse parameters from URL and set them to the omni
    this.maybeSetFiltersFromURL();
  }

  ngOnDestroy() {
    this.filterURLChange?.unsubscribe();
    this.omniSubs?.unsubscribe();
    this.catsSubs?.unsubscribe();
    this.fromDateSubs?.unsubscribe();
    this.sortBySubs?.unsubscribe();
    this.actTypesSubs?.unsubscribe();
    this.orgSubs?.unsubscribe();
    this.venueSubs?.unsubscribe();
  }

  setAdapterLocale(language: string) {
    if (language != 'nb') {
      this.adapter.setLocale('en-GB');
    } else {
      this.adapter.setLocale(language);
    }
  }

  //Initialize all the data for the components
  initializeComponents(): void {
    // Setup category filter
    this.initializeCategoryFilter();
    // Setup activity types filter
    this.initializeActivityTypesFilter();
    // Setup datepicket
    this.initializeDateFilter();
    // Setup organizers
    this.initializeOrganizers();
    // Setup venues
    this.initializeVenues();
    // Setup super events
    this.initializeSuperEvents();
  }

  initializeCategoryFilter(): any {
    //Get the selected categories from the proper source
    this.allCategories = this.categoryService.getAllCategories();
  }

  initializeActivityTypesFilter(): any {
    //Get the selected categories from the proper source
    this.allActivityTypes = this.activityTypeService.getAllActivityTypes();
  }

  initializeDateFilter(): any {
    if (this.translate.currentLang != 'nb') {
      this.adapter.setLocale('en-GB');
    } else {
      this.adapter.setLocale(this.translate.currentLang);
    }
  }

  initializeOrganizers(): void {
    this.organizers = this.organizerService.getAllOrganizers();
  }

  initializeVenues(): void {
    this.venues = this.venueService.getAllVenues();
  }

  initializeSuperEvents(): void {
    this.allSuperEvents = this.eventService.getAllSuperEvents();
  }

  //LISTENERS
  setupListeners() {
    this.setupURLChangeListener();
    this.setupOmniListener();
    this.setupCategoriesListener();
    this.setupActivityTypesListener();
    this.setupFromDateListener();
    this.setupOrganizerListener();
    this.setupVenueListener();
    this.setupSortingListener();
    this.setupModeListener();
    this.setupSuperEventListener();
    this.setupTagListener();
  }

  setupURLChangeListener() {
    this.filterURLChange = this.routerParam.params$.subscribe(
      (params) => {
        if (params?.isNavigational) {
         this.parseParametersForFilters(params);
         this.applyFiltersToFields();
         this.eventListService.filterEvents(this.filter);
        }
      });
  }

  setupOmniListener(): void {
    this.omniSubs = this.omni.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((newValue) => this.onOmniChanges(newValue));
  }

  setupCategoriesListener() {
    this.catsSubs = this.filterForm.controls.categories.valueChanges.subscribe(
      (newCats: string[]) => {
        let currentOmni = this.omni.value || '';
        if (newCats?.length > 0) {
          if (currentOmni?.indexOf("categories:") != -1) {
            currentOmni = currentOmni.replace(this.catsRegExp, "categories:" + newCats.join(","));
          } else {
            currentOmni += " categories:" + newCats.join(",");
          }
        } else {
          currentOmni = currentOmni.replace(this.catsRegExp, '').trim();
        }
        this.omni.setValue(currentOmni);
      }
    )
  }

  setupActivityTypesListener() {
    this.actTypesSubs = this.filterForm.controls.activityTypes.valueChanges.subscribe(
      (newActivityTypes: string[]) => {
        let currentOmni = this.omni.value || '';
        if (newActivityTypes?.length > 0) {
          if (currentOmni?.indexOf("activityTypes:") != -1) {
            currentOmni = currentOmni.replace(this.actTypesRegExp, "activityTypes:" + newActivityTypes.join(","));
          } else {
            currentOmni += " activityTypes:" + newActivityTypes.join(",");
          }
        } else {
          currentOmni = currentOmni.replace(this.actTypesRegExp, '').trim();
        }
        this.omni.setValue(currentOmni);
      }
    )
  }

  setupFromDateListener() {
    this.fromDateSubs = this.filterForm.controls.fromDate.valueChanges.subscribe(
      (newFromDate: any) => {
        let currentOmni = this.omni.value || '';
        if (newFromDate) {
          let dateFromStr: string = newFromDate?.isValid() ? newFromDate.format('DD/MM/YYYY') : '';
          if (currentOmni?.indexOf("from:") != -1) {
            currentOmni = currentOmni.replace(this.fromRegExp, "from:" + dateFromStr);
          } else {
            currentOmni += " from:" + dateFromStr;
          }
        } else {
          currentOmni = currentOmni.replace(this.fromRegExp, '').trim();
        }
        this.omni.setValue(currentOmni);
      }
    )
  }

  setupOrganizerListener() {
    this.orgSubs = this.filterForm.controls.organizer.valueChanges.subscribe(
      (newOrganizer: string) => {
        let currentOmni = this.omni.value || '';
        if (newOrganizer) {
          if (currentOmni?.indexOf("organizer:") != -1) {
            currentOmni = currentOmni.replace(this.orgRegExp, "organizer:" + newOrganizer);
          } else {
            currentOmni += " organizer:" + newOrganizer;
          }
        }
        this.omni.setValue(currentOmni);
      }
    )
  }

  setupVenueListener() {
    this.venueSubs = this.filterForm.controls.venue.valueChanges.subscribe(
      (newVenue: string) => {
        let currentOmni = this.omni.value || '';
        if (newVenue) {
          if (currentOmni?.indexOf("venue:") != -1) {
            currentOmni = currentOmni.replace(this.venueRegExp, "venue:" + newVenue);
          } else {
            currentOmni += " venue:" + newVenue;
          }
        }
        this.omni.setValue(currentOmni);
      }
    )
  }

  setupSortingListener() {
    this.sortBySubs = this.filterForm.controls.sortBy.valueChanges.subscribe(
      (newSortBy: string) => {
        let currentOmni = this.omni.value || '';
        if (newSortBy && newSortBy != this.defaultFilter.sortBy) {
          if (currentOmni?.indexOf("sortBy:") != -1) {
            currentOmni = currentOmni.replace(this.sortByRegExp, "sortBy:" + newSortBy);
          } else {
            currentOmni += " sortBy:" + newSortBy;
          }
        } else {
          currentOmni = currentOmni.replace(this.sortByRegExp, '');
        }
        this.omni.setValue(currentOmni);
      }
    )
  }

  setupModeListener() {
    this.modeSubs = this.filterForm.controls.mode.valueChanges.subscribe(
      (newMode: string) => {
        let currentOmni = this.omni.value || '';
        if (newMode && newMode != 'all') {
          if (currentOmni?.indexOf("mode:") != -1) {
            currentOmni = currentOmni.replace(this.modeRegExp, "mode:" + newMode);
          } else {
            currentOmni += " mode:" + newMode;
          }
        } else {
          currentOmni = currentOmni.replace(this.modeRegExp, '');
        }
        this.omni.setValue(currentOmni);
      }
    )
  }

  setupSuperEventListener() {
    this.superEventSubs = this.filterForm.controls.superEvent.valueChanges.subscribe(
      (newSuperEvent: string) => {
        let currentOmni = this.omni.value || '';
        if (newSuperEvent) {
          if (currentOmni?.indexOf("superEvent:") != -1) {
            currentOmni = currentOmni.replace(this.superEventRegExp, "superEvent:" + newSuperEvent);
          } else {
            currentOmni += " superEvent:" + newSuperEvent;
          }
        }
        this.omni.setValue(currentOmni);
      }
    )
  }

  setupTagListener() {
    this.tagSubs = this.filterForm.controls.tag.valueChanges.subscribe(
      (newTag: string) => {
        let currentOmni = this.omni.value || '';
        if (newTag) {
          if (currentOmni?.indexOf("tag:") != -1) {
            currentOmni = currentOmni.replace(this.tagRegExp, "tag:" + newTag);
          } else {
            currentOmni += " tag:" + newTag;
          }
        } else {
          currentOmni = currentOmni.replace(this.tagRegExp, '');
        }
        this.omni.setValue(currentOmni);
      }
    );
  }

  //Parse the omni value to build a new filter
  onOmniChanges(newValue: string) {
    this.filter = new Filter();
    let omniStr = newValue;

    //Parse categories
    const catArr = omniStr.match(this.catsRegExp);
    if (catArr?.length > 0) {
      this.filter.categories = catArr[0].split(":")[1].split(",");
      omniStr = omniStr.replace(catArr[0], '');
    }
    //Parse activity types
    const actTypesArr = omniStr.match(this.actTypesRegExp);
    if (actTypesArr?.length > 0) {
      this.filter.activityTypes = actTypesArr[0].split(":")[1].split(",");
      omniStr = omniStr.replace(actTypesArr[0], '');
    }
    //Parse from date
    const fromArr = omniStr.match(this.fromRegExp);
    if (fromArr?.length > 0) {
      this.filter.fromDate = fromArr[0].split(":")[1];
      omniStr = omniStr.replace(fromArr[0], '');
    }
    //Parse organizers
    const orgArr = omniStr.match(this.orgRegExp);
    if (orgArr?.length > 0) {
      this.filter.organizerSlug = orgArr[0].split(":")[1];
      omniStr = omniStr.replace(orgArr[0], '');
    }
    //Parse venues
    const venueArr = omniStr.match(this.venueRegExp);
    if (venueArr?.length > 0) {
      this.filter.venueSlug = venueArr[0].split(":")[1];
      omniStr = omniStr.replace(venueArr[0], '');
    }
    //Parse sort by
    const sortByArr = omniStr.match(this.sortByRegExp);
    if (sortByArr?.length > 0) {
      this.filter.sortBy = sortByArr[0].split(":")[1];
      omniStr = omniStr.replace(sortByArr[0], '');
    }
    //Parse mode
    const modeArr = omniStr.match(this.modeRegExp);
    if (modeArr?.length > 0) {
      const mode = modeArr[0].split(":")[1];
      this.filter.mode = mode != 'all' ? mode : null;
      omniStr = omniStr.replace(modeArr[0], '');
    }
    //Parse super event
    const superEventArr = omniStr.match(this.superEventRegExp);
    if (superEventArr?.length > 0) {
      this.filter.superEvent = superEventArr[0].split(":")[1];
      omniStr = omniStr.replace(superEventArr[0], '');
    }

    //Parse tag
    const tagArr = omniStr.match(this.tagRegExp);
    if (tagArr?.length > 0) {
      this.filter.tag = tagArr[0].split(":")[1];
      omniStr = omniStr.replace(tagArr[0], '');
    }

    //Parse the search term
    this.filter.searchTerm = omniStr?.replace(this.extraSpacesRegExp, ' ').trim() || null;

    if (!this.filter.isEqual(this.defaultFilter)) {
      this.router.navigate(['/filter', this.simplifyCurrentFilter()], { replaceUrl: true } );
    }
    this.eventListService.filterEvents(this.filter);
  }

  onOmniFocused() {
    this.omniFocused = true
  }

  onOmniBlur() {
    this.omniFocused = false;
  }

  clear(): void {
    this.resetFilters();
    this.showPanel = false;
  }

  //Parse the filter values from URL
  maybeSetFiltersFromURL(): void {
    this.routerParam.params$.pipe(first()).subscribe(
      (params) => {
        this.parseParametersForFilters(params);
        this.applyFiltersToFields();
        this.eventListService.filterEvents(this.filter);
      });
  }

  parseParametersForFilters(params): void {
    this.filter.sortBy = this.parseSortBy(params['sortBy']);
    this.filter.categories = this.parseCategories(params['categories']);
    this.filter.activityTypes = this.parseActivityTypes(params['activityTypes']);
    this.filter.fromDate = this.parseFromDate(params['fromDate']);
    this.filter.searchTerm = this.parseSearchTerm(params['searchTerm']);
    this.filter.venueSlug = this.parseVenueSlug(params['venueSlug']);
    this.filter.organizerSlug = this.parseOrganizerSlug(params['organizerSlug']);
    this.filter.mode = this.parseMode(params['mode']);
    this.filter.superEvent = this.parseSuperEvent(params['superEvent']);
    this.filter.tag = this.parseTag(params['tag']);
  }

  parseSortBy(param: string): string {
    if (param == 'date' || param == 'popularity') {
      return param;
    }
    return this.defaultFilter.sortBy;
  }

  parseCategories(param: string): string[] | null {
    let catIDs = [];
    if (param && param != "null") {
      const catObjs = param.split(",").map(id => this.categoryService.getCategory(id.toUpperCase()));
      catIDs = catObjs.map(catObj => (catObj && catObj.id) || null).filter(catID => catID != null);
      return catIDs;
    }
    return this.defaultFilter.categories;
  }

  parseActivityTypes(param: string): string[] | null {
    let actTypesIDs = [];
    if (param && param != "null") {
      const actTypeObjs = param.split(",").map(id => this.activityTypeService.getActivityType(id.toUpperCase()));
      actTypesIDs = actTypeObjs.map(actTypeObj => (actTypeObj && actTypeObj.id) || null).filter(actTypesID => actTypesID != null);
      return actTypesIDs;
    }
    return this.defaultFilter.activityTypes;
  }

  parseFromDate(param: string): string | null {
    if (param != '' && param != "null" && moment(param, 'DD/MM/YYYY').isValid()) {
      return param;
    }
    return this.defaultFilter.fromDate;
  }

  parseSearchTerm(param: string): string | null {
    if (param != '' && param != "null") {
      return param;
    }
    return this.defaultFilter.searchTerm;
  }

  parseVenueSlug(param: string): string | null {
    if (param && param != '' && param != "null") {
      return param;
    }
    return this.defaultFilter.venueSlug;
  }

  parseOrganizerSlug(param: string): string | null {
    if (param && param != '' && param != "null") {
      return param;
    }
    return this.defaultFilter.organizerSlug;
  }

  parseMode(param: string): string | null {
    if (param != '' && param != "null") {
      return param;
    }
    return this.defaultFilter.mode;
  }

  parseSuperEvent(param: string): string | null {
    if (param != '' && param != "null") {
      return param;
    }
    return this.defaultFilter.superEvent;
  }

  parseTag(param: string): string | null {
    if (param != '' && param != "null") {
      return param;
    }
    return this.defaultFilter.tag;
  }

  applyFiltersToFields(): void {
    this.omni.setValue(this.filter.searchTerm);
    this.filterForm.controls.categories.setValue(this.filter.categories);
    this.filterForm.controls.activityTypes.setValue(this.filter.activityTypes);
    this.filterForm.controls.organizer.setValue(this.filter.organizerSlug);
    this.filterForm.controls.venue.setValue(this.filter.venueSlug);
    this.filterForm.controls.sortBy.setValue(this.filter.sortBy);
    this.filterForm.controls.mode.setValue(this.filter.mode || 'all');
    this.filterForm.controls.superEvent.setValue(this.filter.superEvent);
    this.filterForm.controls.tag.setValue(this.filter.tag);
    if (this.filter.fromDate) {
      this.filterForm.controls.fromDate.setValue(moment(this.filter.fromDate, 'DD/MM/YYYY'));
    }
  }

  //Reset Filter
  resetFilters() {
    this.filterForm.controls.categories.setValue('');
    this.filterForm.controls.activityTypes.setValue('');
    this.filterForm.controls.organizer.setValue('');
    this.filterForm.controls.venue.setValue('');
    this.filterForm.controls.sortBy.setValue(environment.ui.showOnFilter.sortBy);
    this.filterForm.controls.mode.setValue('all');
    this.filterForm.controls.superEvent.setValue('');
    this.filterForm.controls.tag.setValue('');
    this.filterForm.controls.fromDate.setValue('');
    this.omni.setValue('');
    this.router.navigate(['/'], { replaceUrl: true });
  }

  isDefaultFilter(): boolean {
    return this.filter.isEqual(this.defaultFilter);
  }

  simplifyCurrentFilter(): Filter {
    let simplifiedFilter = this.filter.clone();
    for (let filterProperty of Object.keys(simplifiedFilter)) {
      if (simplifiedFilter[filterProperty] == this.defaultFilter[filterProperty] || simplifiedFilter[filterProperty].length == 0) {
        delete simplifiedFilter[filterProperty];
      }
    }
    return simplifiedFilter;
  }

}
