import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { EventService } from '../services/event.service';
import { UtilsService } from '../services/utils.service';
import { Event } from '../models/event';
import { QuestionWarningComponent } from '../question-warning/question-warning.component';
import { TranslateService } from '@ngx-translate/core';
import { BookingService } from '../services/booking.service';
import { first } from 'rxjs/operators';
import { Booking } from '../models/booking';

@Component({
  selector: 'app-my-events-list-item',
  templateUrl: './my-events-list-item.component.html',
  styleUrls: ['./my-events-list-item.component.scss']
})
export class MyEventsListItemComponent implements OnInit {

  @Input()
  event: Event;

  loadingBookings: boolean = true;
  bookings: Booking[] = [];

  constructor(
    private eventService: EventService,
    public utilsService: UtilsService,
    private bookingService: BookingService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    if (this.event?.registrationEnabled) {
      this.bookingService.getBookings(this.event.id, null, null, ['active', 'attended', 'partially_attended', 'no_show']).pipe(first()).subscribe(
        (bookings) => {
          this.bookings = bookings;
          this.loadingBookings = false;
        }
      );
    }
  }

  deleteEvent(eventId: string): void {
    let dialogRef = this.dialog.open(
      QuestionWarningComponent, {
        width: '400px',
        data: {
          title: this.translate.instant("Delete event"),
          question: this.translate.instant("Are you sure that you want to remove this event and all its repetitions?")
        }
      });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (this.event.images.length > 0) {
          for (let image of this.event.images) {
            this.eventService.deleteImageByUrl(image.urlSmall);
            this.eventService.deleteImageByUrl(image.urlLarge);
            this.eventService.deleteImageByUrl(image.urlOriginal);
          }
        }
        this.eventService.deleteEvent(eventId)
        .then(
          () => this.snackBar.open(this.translate.instant("Your event was successfully deleted."), null, {
                duration: 4000,
              })
            )
        .catch(
          () => this.snackBar.open(this.translate.instant("There was a problem deleting your event. Please contact us."), null, {
                duration: 4000,
              })
        );
      }
    });
  }

  getBookedTickets(event, rep?) {
    let instance = event;
    if (this.bookings?.length > 0) {
      if (rep) {
        instance = {...instance, ...rep};
      }
      return this.bookings.filter((b) => b.startDate?.seconds == instance.startDate?.seconds && b.venueName == instance.venueObj.name).reduce((pv, cv) => cv.tickets.reduce((ppv, ccv) => ccv.numberTickets + ppv, 0) + pv, 0);
    }
    if (this.loadingBookings) {
      return this.translate.instant('Loading...');
    }
    return 0;
  }
}
