<div class="wrapper classic"
    *ngIf="environment.content.aboutus[translate.currentLang || 'en'] && environment.theme == 'classic'">
    <div class="contact page mat-elevation-z1">
        <h1 class="mat-h1" translate>About us</h1>
        <div class="margin-bottom" [innerHTML]="environment.content.aboutus[translate.currentLang || 'en']"></div>
    </div>
</div>

<div class="wrapper trdevents" *ngIf="environment.theme == 'trdevents'">
    <div class="contact page mat-elevation-z1">
        <h1 class="mat-h1" translate>About us</h1>
        <p *ngIf="translate.currentLang == 'nb'">
            Trdevents.no eies og driftes av Trondheim Management AS, som er et privat-offentlig selskap eid av
            Samarbeidsgruppen Midtby´n, Visit Trondheim AS, Trondheim Gårdeierforening og Trondheim Kommune.
            Trondheim Management jobber for en levende og attraktiv by og sentrum. Selskapet bidrar til at Trondheim
            er godt tilrettelagt for kultur, næring, handel og fritid, og er en viktig aktør og samarbeidspartner
            for synliggjøring og markedsføring av byens mangfoldige tilbud.
            Forsidebilde: Trondheim Dronetjeneste oversiktbilde Pstereo 2023.
        </p>
        <p *ngIf="translate.currentLang != 'nb'">
            Trdevents.no is owned and operated by Trondheim Management AS, which is a private-public company owned by
            Samarbeidsgruppen Midtby´n, Visit Trondheim AS, Trondheim Gårdeierforening and Trondheim Municipality.
            Trondheim
            Management works for a vibrant and attractive city and centre. The company contributes to Trondheim being
            well
            organized for culture, business, trade and leisure, and is an important actor and partner for the visibility
            and
            marketing of the city\'s diverse offer.
            Frontpage picture: Trondheim Dronetjeneste oversiktbilde Pstereo 2023.
        </p>
        <div fxLayout="column" fxLayoutAlign="start start">
            <div>Trondheim Management</div>
            <div>Nordre gate 10, 7011 Trondheim</div>
            <div><a class="email" href="mailto:post@midtbyen.no">post@midtbyen.no</a></div>
            <div>+47 993 54 000</div>
            <div>Org. nr 995 860 465</div>
        </div>
        <div class="full-width margin-top" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div class="title">Powered by</div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
                <img style="max-height: 50px" src="../../assets/logos/midtbyen-name.png" />
                <img style="max-height: 50px" src="../../assets/logos/visit-trondheim.png" />
            </div>
        </div>
    </div>
</div>