import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show-embed',
  templateUrl: './show-embed.component.html',
  styleUrls: ['./show-embed.component.scss']
})
export class ShowEmbedComponent implements OnInit {


  iframeURL: string = environment.siteURL + '/iframe?eventID=';
  iframeURLSafe: SafeResourceUrl;
  code: string;
  
  constructor(

    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ShowEmbedComponent>,) { 
    }

  ngOnInit(): void {
    this.iframeURL += this.data.eventID;
    this.iframeURLSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURL);
    this.code = `<iframe style="height: 400px;width: 100%;" frameborder="0" src="${this.iframeURL}"></iframe>`;
  }

  //ACTIONS
  onClose() {
    this.dialogRef.close();
  }


}
