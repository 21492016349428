<form [formGroup]="importEventForm" (keydown.enter)="importEvent()">
  <div *ngIf="!isLoading">
    <h2 mat-dialog-title translate>Import event from Facebook</h2>
    <div mat-dialog-content>
      <mat-form-field class="url-field full-width" appearance="outline">
        <mat-label translate>Facebook event URL</mat-label>
        <input matInput tyle="url" formControlName="facebookURL">
        <mat-hint align="end" translate>Please, insert the facebook event URL</mat-hint>
        <mat-error *ngIf="importEventForm.hasError('pattern',['facebookURL'])" translate>
          Event URL must be a valid URL (i.e. https://www.facebook.com/events/249886985791185/)
        </mat-error>
      </mat-form-field>
    </div>
    <div class="margin-bottom">
      <div class="error-field" *ngIf="errorImporting" translate>
        There was an error importing the event. Please contact us.
      </div>
      <div class="error-field" *ngIf="errorURL" translate>
        Event URL must be a valid URL (i.e. https://www.facebook.com/events/249886985791185/)
      </div>
    </div>
    <div align="end" mat-dialog-actions>
      <span class="fill-spacer"></span>
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" type="submit" (click)="importEvent()"><span translate>Import</span></button>
    </div>
  </div>
  <div *ngIf="isLoading">
    <h3 mat-dialog-title translate>Importing event from Facebook...</h3>
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</form>
