<div fxLayout="row" class="full-width" fxLayoutAlign="start start">
    <mat-form-field fxFlex="grow" appearance="outline">
        <mat-label translate>{{'Wheelchair, sign language interpreter, food allergies (when serving), etc.' | translate}}</mat-label>
        <mat-hint>{{hint}}</mat-hint>
        <input type="text" [formControl]="customerComments" matInput maxlength="300" />
        <mat-hint align="end">{{customerComments?.value?.length || 0}} / 300</mat-hint>
        <mat-error *ngIf="customerComments.hasError('maxlength')">
            {{'Maximum 300 chars' | translate }}
        </mat-error>
    </mat-form-field>
</div>
