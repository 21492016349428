import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Team } from '../models/team';
import { QuestionWarningComponent } from '../question-warning/question-warning.component';
import { AuthService } from '../services/auth.service';
import { TeamService } from '../services/team.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss']
})
export class EditTeamComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  editTeamForm: FormGroup;
  formIsWorking: boolean = false;
  teamId: string;
  team: Team;
  teamSubs: Subscription;
  environment: any;

  constructor(private teamService: TeamService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setupTeamInfo();
    }
  }

  setupTeamInfo(): void {
    this.environment = environment;
    this.route.paramMap.pipe(first()).subscribe(
      (params: ParamMap) => {
        this.teamId = params.get('teamId');
        this.teamSubs = this.teamService.getTeam(this.teamId).subscribe(
          (team) => {
            this.team = team;
            if (!team.users_bookings) {
              this.team['users_bookings'] = [...team.users];
            }
            this.setupForm(this.team);
          }
        )
      }
    );
  }

  ngOnDestroy(): void {
    this.teamSubs?.unsubscribe();
  }

  setupForm(team: Team) {
    this.editTeamForm = this.fb.group({
      id: new FormControl(),
      name: new FormControl('', [Validators.required]),
      editors: new FormArray(team.editors?.map(() => new FormControl()) || []),
      users: new FormArray(team.users?.map(() => new FormControl()) || []),
      users_bookings: new FormArray(team.users_bookings?.map(() => new FormControl()) || []),
    });
    this.editTeamForm.patchValue(team);
  }

  isAdmin(memberEmail: string): boolean {
    return this.team?.admin?.email == memberEmail;
  }

  get usersControls(): FormArray {
    return this.editTeamForm.controls.users as FormArray;
  }

  get users_teamsControls(): FormArray {
    return this.editTeamForm.controls.editors as FormArray;
  }

  get users_bookingsControls(): FormArray {
    return this.editTeamForm.controls.users_bookings as FormArray;
  }

  addTeamEditor(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add a member
    if ((value || '').trim() && this.isValidEmail(value)) {
      this.users_teamsControls.push(this.fb.control(value.trim()));
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  removeTeamEditor(teamEditorEmail: string): void {
    const index = this.users_teamsControls.value.indexOf(teamEditorEmail);
    if (index >= 0) {
      this.users_teamsControls.removeAt(index);
    }
  }

  addEventEditor(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add a member
    if ((value || '').trim() && this.isValidEmail(value)) {
      this.usersControls.push(this.fb.control(value.trim()));
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  removeEventEditor(eventEditorEmail: string): void {
    const index = this.usersControls.value.indexOf(eventEditorEmail);
    if (index >= 0) {
      this.usersControls.removeAt(index);
    }
  }


  addBookingEditor(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add a member
    if ((value || '').trim() && this.isValidEmail(value)) {
      this.users_bookingsControls.push(this.fb.control(value.trim()));
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  removeBookingEditor(bookingEditorEmail: string): void {
    const index = this.users_bookingsControls.value.indexOf(bookingEditorEmail);
    if (index >= 0) {
      this.users_bookingsControls.removeAt(index);
    }
  }

  isValidEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  saveTeam() {
    this.formIsWorking = true;
    if (this.editTeamForm.valid) {
      this.teamService.updateTeam(this.editTeamForm.value.id, { ... this.editTeamForm.value })
        .then(() => {
          this.formIsWorking = false;
          this.snackBar.open(this.translate.instant("Team saved successfully"), null, {
            duration: 4000,
          });
          this.router.navigate(['/my-teams/']);
        })
        .catch(error => {
          this.formIsWorking = false;
          console.error(error);
          this.snackBar.open(this.translate.instant("There was an error while saving your team"), null, {
            duration: 4000,
          });
        });
    }
  }

  deleteTeam(): void {
    let dialogRef = this.dialog.open(
      QuestionWarningComponent, {
      width: '400px',
      data: {
        title: this.translate.instant("Delete team"),
        question: this.translate.instant("Are you sure that you want to remove this team?")
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.teamService.deleteTeam(this.teamId)
          .then(
            () => this.snackBar.open(this.translate.instant("Your team was successfully deleted."), null, {
              duration: 4000,
            })
          )
          .catch(
            () => this.snackBar.open(this.translate.instant("There was a problem deleting your team. Please contact us."), null, {
              duration: 4000,
            })
          );
      }
    });
  }

}
