import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Booking } from '../models/booking';
import { Venue } from '../models/venue';
import { VenueService } from '../services/venue.service';

@Component({
  selector: 'app-booking-venue-editor',
  templateUrl: './booking-venue-editor.component.html',
  styleUrls: ['./booking-venue-editor.component.scss']
})
export class BookingVenueEditorComponent implements OnInit {

  //Venue
  changeVenueForm: FormControl = new FormControl();
  venueFilterCtrl: FormControl = new FormControl();
  newVenue: string;
  venues: Venue[];
  filteredVenues: Observable<Venue[]>;

  
  @Input()
  booking: Booking;

  @Output()
  onVenueChange: EventEmitter<string> = new EventEmitter();

  @Output()
  onValidityChange: EventEmitter<boolean> = new EventEmitter();

  venueControl: FormControl = new FormControl();

  constructor(
    private venueService: VenueService) { }

  ngOnInit(): void {
    this.venueService.getAllVenues().subscribe(
      (venuesOrdered) => {
        this.venues = venuesOrdered;
        //Select the one from the booking if there is a booking
        if (this.booking?.venueName) {
          this.venueControl.setValue(this.booking?.venueName);
        }
        this.filteredVenues = this.venueFilterCtrl.valueChanges
          .pipe(
            startWith<string | Venue>(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name.trim() ? this._filterVenues(name) : this.venues.slice())
          );
      });
    
    this.venueControl.valueChanges.subscribe((newVenue) => {
      this.onVenueChange.emit(newVenue);
      this.onValidityChange.emit(this.venueControl.valid);
    });
  }

  private _filterVenues(name: string): Venue[] {
    const filterValue = name.toLowerCase();
    return this.venues.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1);
  }


}
