import { Component, OnInit } from '@angular/core';
import { SeoService } from '../services/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-image-guidelines',
  templateUrl: './image-guidelines.component.html',
  styleUrls: ['./image-guidelines.component.scss']
})
export class ImageGuidelinesComponent implements OnInit {

  environment: any;

  constructor(private seoService: SeoService,
    public translate: TranslateService) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.seoService.generateTags({ title: this.translate.instant("Guidelines for images") });
  }

}
