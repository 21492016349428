<div fxLayout="column" fxLayoutAlign="center center" *ngIf="environment.theme == 'trdevents'" class="trdevents">
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div *ngIf="!loading" class="full-width favorites">
        <div fxLayout="row" fxLayoutAlign="start start">
            <h2 >{{'My favorites' | translate}} {{totalHits > 0 ? '(' + totalHits + '):' : ''}}</h2>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="1%"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="0">
            <div class="favorites-text" *ngIf="favoritesList?.length == 0">
                {{'No favorite events yet :-)' | translate}}
            </div>
            <app-event-tile fxFlex="32%" fxFlex.lt-md="100%" *ngFor="let favorite of favoritesList; let last = last"
                [event]="favorite" (afterViewInit)="last ? lastEventIsRendered() : null"></app-event-tile>
        </div>
    </div>
    
</div>