import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Booking } from '../models/booking';
import { BookingStatusService } from '../services/booking-status.service';
import { TicketTypeService } from '../services/ticket-type.service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-booking-log',
  templateUrl: './booking-log.component.html',
  styleUrls: ['./booking-log.component.scss']
})
export class BookingLogComponent implements OnInit {

  @Input()
  booking: Booking;

  logEntries: any;

  constructor(
    private utilsService: UtilsService,
    private bookingStatusService: BookingStatusService,
    private ticketTypeService: TicketTypeService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.initLog();
  }

  initLog() {
    const actions = this.booking.actions?.map((action) => ({
      type: 'action',
      author: action.authorEmail ? this.utilsService.getAuthor(action.authorEmail) : this.utilsService.getAuthor(this.booking.authorEmail),
      name: this.translate.instant('Action') + ": " + this.getLogName(action.name) + " " + ((action.name == 'booking' || action.name == 'cancelling' || action.name == 'updating' || action.notifySMS) ? this.translate.instant('with') : this.translate.instant('without')) + " " + this.translate.instant("SMS notification"),
      status: action.status,
      timestamp: action.timestamp || this.booking.bookingDate,
      content: this.getActionContent(action)
    })) || [];
    const messages = this.booking.messages?.map((message) => ({
      type: 'message',
      author: '',
      name: this.translate.instant('SMS delivered'),
      status: 'delivered',
      timestamp: message.dateSent || message.sentDate || message.sendingDate,
      content: "SMS: " + message.content
    })) || [];
    this.logEntries = [...actions, ...messages].sort((a, b) => (a.timestamp?.seconds || a.timestamp?._seconds) > (b.timestamp?.seconds || b.timestamp?._seconds) ? -1 : 1);
  }

  getLogType(type) {
    if (type == 'action') {
      return this.translate.instant('Action');
    }
    if (type == 'message') {
      return this.translate.instant('SMS')
    }
    return type;
  }

  getLogName(name) {
    if (name == 'booking') {
      return this.translate.instant('Booking');
    }
    if (name == 'cancelling') {
      return this.translate.instant('Cancelling');
    }
    if (name == 'updating') {
      return this.translate.instant('Updating');
    }
    if (name == 'notification') {
      return this.translate.instant('Notification');
    }
    if (name == 'error') {
      return this.translate.instant('Error');
    }
    if (name == 'confirmation') {
      return this.translate.instant('Confirmation');
    }
    if (name == 'update') {
      return this.translate.instant('Update');
    }
    if (name == 'admin-change-status') {
      return this.translate.instant('Admin status change');
    }
    if (name == 'admin-change-venue') {
      return this.translate.instant('Admin venue change');
    }
    if (name == 'admin-change-start-date') {
      return this.translate.instant('Admin start date change');
    }
    if (name == 'admin-send-sms') {
      return this.translate.instant('Admin SMS sending');
    }
    if (name == 'admin-cancel-booking') {
      return this.translate.instant('Booking cancelled by admin');
    }
    if (name == 'guest-cancel-booking') {
      return this.translate.instant('Booking cancelled by guest');
    }
    if (name == 'admin-new-booking') {
      return this.translate.instant('New booking by admin');
    }
    if (name == 'guest-new-booking') {
      return this.translate.instant('New booking by guest');
    }
    if (name == 'change-tickets') {
      return this.translate.instant('Tickets change');
    }
    if (name == 'change-customer-name') {
      return this.translate.instant('Customer name change');
    }
    if (name == 'change-customer-address') {
      return this.translate.instant('Customer address change');
    }
    if (name == 'change-customer-comments') {
      return this.translate.instant('Customer comments change');
    }
    if (name == 'system-auto-active') {
      return this.translate.instant('Automatic change to active');
    }
    if (name == 'system-auto-title-change') {
      return this.translate.instant('Automatic change of title');
    }
    if (name == 'system-auto-startdate-change') {
      return this.translate.instant('Automatic change of start date');
    }
    if (name == 'system-auto-venue-change') {
      return this.translate.instant('Automatic change of venue');
    }
    if (name == 'system-send-cancellation-reminder') {
      return this.translate.instant('Automatic cancellation reminder');
    }
    return name;
  }

  getActionContent(action: Action) {
    if (action.parameters?.length > 0) {
      if (action.name == 'admin-change-status') {
        return this.translate.instant("New status") +": "+this.bookingStatusService.getBookingStatusName(action.parameters[1]);
      }
      if (action.name == 'admin-change-venue' || action.name == 'system-auto-venue-change') {
        return this.translate.instant("New venue") +": "+ action.parameters[1];
      }
      if (action.name == 'admin-change-start-date' || action.name == 'system-auto-startdate-change') {
        return this.translate.instant("New start date") +": "+ formatDate(new Date(action.parameters[1].seconds * 1000), "short", 'nb-NO');
      }
      if (action.name == 'change-tickets') {
        const tickets = JSON.parse(action.parameters[1]);
        const ticketsLog = this.translate.instant("New tickets") +": "+ tickets.map((ticket) => (ticket.numberTickets + 'x ' + this.ticketTypeService.getTicketTypeName(ticket))).join(", ");
        const statusLog = this.translate.instant("New status") +": "+ this.bookingStatusService.getBookingStatusName(action.parameters[2]);
        return ticketsLog + " | " + statusLog;
      }
      if (action.name == 'change-customer-name') {
        return this.translate.instant("New name") +": "+ action.parameters[1];
      }
      if (action.name == 'change-customer-address') {
        return this.translate.instant("New address") +": "+ action.parameters[1];
      }
      if (action.name == 'change-customer-comments') {
        return this.translate.instant("New comments") +": "+ action.parameters[1];
      }
      if (action.name == 'system-auto-title-change') {
        return this.translate.instant("New title") +": "+ action.parameters[1];
      }
    }
    return null;
  }

}