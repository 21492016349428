import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NewsletterService } from '../services/newsletter.service';

@Component({
  selector: 'app-newsletter-footer',
  templateUrl: './newsletter-footer.component.html',
  styleUrls: ['./newsletter-footer.component.scss']
})
export class NewsletterFooterComponent implements OnInit {
  newsletterForm = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.required, Validators.email], updateOn: "blur"
    }),
    agree: new FormControl(false, [Validators.requiredTrue]),
  });
  sending: boolean = false;
  result: any;
  environment: any;

  constructor(
    private newsletterService: NewsletterService,
    public translate: TranslateService,
    private snackBar: MatSnackBar) {
    this.environment = environment;
  }

  ngOnInit() {
  }

  addSubscriber(formDirective: FormGroupDirective) {
    if (this.newsletterForm.valid) {
      let email = this.newsletterForm.controls['email'].value;
      this.sending = true;
      this.newsletterService.addSubscriber(email).then(
        (response) => {
          const result = response.data;
          this.result = result;
          this.sending = false;
          if (this.result.success) {
            this.snackBar.open(this.translate.instant("Thanks for subscribing!"), null, {
              duration: 4000,
            });
            formDirective.resetForm();
            this.newsletterForm.reset();
          } else {
            this.snackBar.open(this.translate.instant("Something went wrong while subscribing your email! :("), null, {
              duration: 4000,
            });
          }
        },
        () => {
          this.sending = false;
          this.snackBar.open(this.translate.instant("Something went wrong while subscribing! :("), null, {
            duration: 4000,
          });
        }
      );
    }
  }


}
