<div class="wrapper">
  <div class="account mat-elevation-z1">
    <h1 class="mat-h1 margin-bottom" translate>Account</h1>
    <div *ngIf="authService.user | async as user">
      <div fxLayout="column">
        <h2 class="mat-h3 bold" translate>Login info</h2>
        <div fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="10px" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start start" class="full-width">
            <div fxFlex.gt-sm="grow" fxLayoutGap="10px">
              <div fxFlex="grow" fxLayout="column" fxLayoutGap="5px">
                <div>{{'Email' | translate}}: {{user.providerData[0].email}}</div>
                <div>ID: {{user.uid}}</div>
                <div fxLayout="row" fxLayoutGap="10px" class="user-meta" fxLayoutAlign="start center">
                  <div><strong><span translate>Login type</span>:</strong> {{getLoginType(user.providerData[0].providerId)}}</div>
                  <div> | </div>
                  <div><strong><span translate>Created at</span>:</strong> {{user.metadata.creationTime | date:'shortDate':'CET':translate.currentLang}}</div>
                  <div> | </div>
                  <div><strong><span translate>Last sign in</span>:</strong> {{user.metadata.lastSignInTime | date:'short':'CET':translate.currentLang}}</div>
                </div>
              </div>
            </div>
            <div class="actions" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
              <button mat-stroked-button *ngIf="user.providerData[0].providerId == 'password'" (click)="resetPassword(user.providerData[0].email)"><span translate>Reset Password</span></button>
              <button mat-stroked-button (click)="logOut()"><span translate>Sign Out</span></button>
            </div>
          </div>
          <div *ngIf="resetLinkSent" class="password-sent">{{'A reset link was sent to your email. Please follow the instructions.' | translate}}</div>
        </div>
      </div>
      <div class="margin-top"></div>
      <mat-divider></mat-divider>
      <div class="margin-top" fxLayout="column">
        <h2 class="mat-h3 bold" translate>Export of data</h2>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="10px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" class="full-width">
          <div fxFlex.gt-sm="grow">
            <div>{{'Export all data from the account' | translate }}</div>
            <div class="hint">{{'You will receive an email with your account data attached' | translate}}.</div>
          </div>
          <button fxFlex.gt-sm="noshrink" fxFlex.lt-md="nogrow" mat-stroked-button (click)="exportData()">{{'Export data' | translate }}</button>
        </div>
        <div *ngIf="!user.email" fxLayout="row">
          <mat-form-field fxFlex="100">
            <input matInput [placeholder]="'Email' | translate" [formControl]="emailFormControl" required type="email">
            <mat-hint>{{'Introduce an email address to send the exported data' | translate}}</mat-hint>
            <mat-error *ngIf="emailFormControl.hasError('required')">{{'You must introduce an email address to receive the exported data' | translate }}</mat-error>
            <mat-error *ngIf="emailFormControl.hasError('email')">{{'You must introduce a valid email address' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="margin-top"></div>
      <mat-divider></mat-divider>
      <div class="margin-top">
        <h2 class="mat-h3 bold" translate>Account deletion</h2>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="10px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" class="full-width">
          <div fxFlex.gt-sm="grow">
            <mat-checkbox [formControl]="deleteAllData">
              <span class="wrap-mat-checkbox-label">
                {{'Delete events, teams, pictures, venues and organizers created by this account' | translate }}
              </span>
            </mat-checkbox>
          </div>
          <div fxFlex.gt-sm="noshrink">
            <button mat-flat-button color="warn" (click)="warningDeleteAccount()">{{'Delete account' | translate }}</button>
          </div>
        </div>
        <div class="hint">{{'You will be asked to reauthenticate' | translate }}. {{'Your account will be removed from the teams where you are a member' | translate }}. {{'You will receive an email with the confirmation of your data deletion' | translate}}.</div>
      </div>
    </div>
  </div>
</div>
