import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Category } from '../models/category';
import allCategories from '../../assets/data/categories.json';

@Injectable()
export class CategoryService {

  constructor(private translate: TranslateService) { }

  //Returns the list of categories ordered by the current language
  getAllCategories(): Category[] {
    const lanName = "name_" + (this.translate.currentLang || 'nb');
    const lanSlug = "slug_" + (this.translate.currentLang || 'nb');
    let sortedCats: Category[] = Object.keys(allCategories)
      .map(function (k) {
        return {
          id: k,
          parentCategory: null,
          name: allCategories[k][lanName],
          slug: allCategories[k][lanSlug],
          name_nb: allCategories[k].name_nb,
          name_en: allCategories[k].name_en,
          slug_nb: allCategories[k].slug_nb,
          slug_en: allCategories[k].slug_en,
          visible: allCategories[k].visible == undefined ? true : false,
          subCategories: allCategories[k].subcategories?.map(function (cat) {
            return {
              id: cat.id,
              parentCategory: k,
              name: cat[lanName],
              slug: cat[lanSlug],
              name_nb: cat.name_nb,
              name_en: cat.name_en,
              slug_en: cat.slug_en,
              slug_nb: cat.slug_nb,
              visible: cat.visible == undefined ? true : false,
            };
          }) || []
        };
      })
      .sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
    return sortedCats;
  }

  //Returns the list of categories ordered by the current language flattened (without hierarchy)
  getAllFlattenCategories(): Category[] {
    const allCats = this.getAllCategories();
    let sortedCats: Category[] = allCats;
    for (let cat of allCats) {
      if (cat.subCategories?.length > 0) {
        sortedCats = [...sortedCats, ...cat.subCategories];
      }
    }
    //Reorder by name
    sortedCats.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
    return sortedCats.filter(c => c.visible);
  }

  getCategory(categoryID: string): Category {
    const allCats = this.getAllCategories();
    const superCat = allCats.find((c) => (c.id.toLowerCase() == categoryID.toLowerCase()));
    if (!superCat) {
      for (let superCat of allCats) {
        const maybeSubCat = superCat?.subCategories.find((c) => (c.id.toLowerCase() == categoryID.toLowerCase()));
        if (maybeSubCat) {
          return maybeSubCat;
        }
      }
    }
    return superCat;
  }

  getCategoryBySlug(catSlug: string): Category {
    const allCats = this.getAllCategories();
    const superCat = allCats.find((c) => (c.slug_en.toLowerCase() == catSlug.toLowerCase() || c.slug_nb.toLowerCase() == catSlug.toLowerCase()));
    if (!superCat) {
      for (let superCat of allCats) {
        const maybeSubCat = superCat?.subCategories.find((c) => (c.slug_en.toLowerCase() == catSlug.toLowerCase() || c.slug_nb.toLowerCase() == catSlug.toLowerCase()));
        if (maybeSubCat) {
          return maybeSubCat;
        }
      }
    }
    return superCat;
  }

  getCategoryByName(catName: string): Category {
    const allCats = this.getAllCategories();
    const superCat = allCats.find((c) => (c.name_en.toLowerCase() == catName.toLowerCase() || c.name_nb.toLowerCase() == catName.toLowerCase()));
    if (!superCat) {
      for (let superCat of allCats) {
        const maybeSubCat = superCat?.subCategories.find((c) => (c.name_en.toLowerCase() == catName.toLowerCase() || c.name_nb.toLowerCase() == catName.toLowerCase()));
        if (maybeSubCat) {
          return maybeSubCat;
        }
      }
    }
  }

  getCategoryName(categoryID: string) {
    const cat = this.getCategory(categoryID);
    return (this.translate.currentLang == 'nb' && cat?.name_nb) || cat?.name_en
  }

  getCategorySlug(categoryID: string) {
    const cat = this.getCategory(categoryID);
    return (this.translate.currentLang == 'nb' && cat?.slug_nb) || cat?.slug_en
  }

  getCategoryIDsForSuperSlug(slug: string): string[] {
    const cat = this.getCategoryBySlug(slug);
    if (cat) {
      if (cat.subCategories?.length > 0) {
        return [cat.id, ...cat.subCategories.map(c => c.id)];
      }
      return [cat.id];
    }
    return [];
  }

  getSuperCategoryBySlug(slug: string): Category {
    const allCats = this.getAllCategories();
    const sCat = allCats.find((c) => (c.slug_en == slug || c.slug_nb == slug));
    if (!sCat) {
      for (let superCat of allCats) {
        const maybeSubCat = superCat?.subCategories.find((c) => (c.slug_en == slug || c.slug_nb == slug));
        if (maybeSubCat) {
          return superCat || null;
        }
      }
    }
    return sCat || null;
  }

  getSubcategoriesBySlug(slug: string): Category[] {
    const allCats = this.getAllCategories();
    const sCat = allCats.find((c) => (c.slug_en == slug || c.slug_nb == slug));
    let subCategories = sCat?.subCategories?.filter(c => c.visible)?.length > 0 ? [sCat, ...sCat.subCategories] : [];
    if (!sCat) {
      for (let superCat of allCats) {
        const maybeSubCat = superCat?.subCategories.find((c) => (c.slug_en == slug || c.slug_nb == slug));
        if (maybeSubCat) {
          subCategories = [superCat, ...(superCat.subCategories?.filter(c => c.visible) || [])];
        }
      }
    }
    return subCategories?.filter(c => c.visible);
  }
}
