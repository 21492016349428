<div id="book-tickets" *ngIf="bookingAvailable; else notAvailable" class="margin-top">

    <div *ngIf="show != 'my-tickets'" class="margin-bottom full-width" fxLayout="row"
        fxLayoutAlign="space-between start" fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="start start"
        fxLayoutGap.lt-lg="20px">
        <div fxLayout="column" fxLayoutAlign="start start">
            <h3 class="mat-subheading-2">{{ 'Book tickets' | translate }}</h3>
            <div class="hint" style="margin-bottom: 0">{{'You can order your tickets with the form below' | translate}}.
            </div>
        </div>
        <a mat-flat-button color="accent" *ngIf="show != 'my-tickets'" (click)="show = 'my-tickets'">{{'My tickets' | translate}}</a>
    </div>

    <div *ngIf="show != 'new-tickets'" class="margin-bottom full-width" fxLayout="row" fxLayoutAlign="space-between end"
        fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="start start" fxLayoutGap.lt-lg="20px">
        <h3 class="mat-subheading-2">{{ 'My tickets' | translate }}</h3>
        <a mat-flat-button color="accent" *ngIf="show != 'new-tickets'" (click)="show = 'new-tickets'">{{'New
            tickets' | translate}}</a>
    </div>

    <app-booking-form *ngIf="show == 'new-tickets'" [event]="event"></app-booking-form>

    <app-my-tickets *ngIf="show == 'my-tickets'" [event]="event"></app-my-tickets>


</div>

<ng-template #notAvailable>
    <div id="book-tickets" class="tickets-available-from">
        {{'Tickets booking will open on' | translate}} <strong>{{printDate(event.ticketsFromDate)}}</strong> {{'at' |
        translate}} <strong>{{printTime(event.ticketsFromDate)}}</strong>
    </div>
</ng-template>