<div class="wrapper">
  <div class="my-events mat-elevation-z1">
    <div fxLayout="row" class="margin-bottom" fxLayoutAlign="space-between center">
      <h2 class="mat-title" translate>My events</h2>
      <a *ngIf="!pastEvents" class="top-link blue" routerLink='/my-events/past' translate>Past</a>
      <a *ngIf="pastEvents" class="top-link blue" routerLink='/my-events' translate>Upcoming</a>
    </div>
    <div *ngIf="!events" class="loading">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div *ngIf="(events | async); let events;">
      <div *ngIf="events.length == 0" class="mat-body text-center">
        {{'You have no events' | translate }}
      </div>
      <mat-list *ngIf="events.length > 0">
        <app-my-events-list-item *ngFor="let event of events" [event]="event"></app-my-events-list-item>
      </mat-list>
    </div>
    <div fxLayout="row" class="margin-top" fxLayoutAlign="end center">
      <a mat-raised-button color="primary" routerLink="/add-an-event"><span translate>Add an event</span></a>
    </div>
  </div>
</div>
