<h2 mat-dialog-title>{{'Inappropriate content' | translate}}</h2>
<div mat-dialog-content>
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{'Describe what is wrong with this event' | translate}}</mat-label>
        <textarea [formControl]="reason" required rows="5" cdkTextareaAutosize matInput></textarea>
        <mat-error *ngIf="reason.hasError('required')">{{'Please explain what is wrong with this event'}}</mat-error>
    </mat-form-field>
</div>

<div mat-dialog-actions *ngIf="sending" fxLayoutAlign="center center">
    <mat-spinner diameter="30"></mat-spinner>
</div>

<div mat-dialog-actions *ngIf="!sending" fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
    <button mat-raised-button color="primary" (click)="report()">{{'Report' | translate}}</button>
</div>