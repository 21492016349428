import { Component, OnInit } from '@angular/core';
import { TeamService } from '../services/team.service';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AddTeamComponent } from '../add-team/add-team.component';
import { Team } from '../models/team';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-my-teams',
  templateUrl: './my-teams.component.html',
  styleUrls: ['./my-teams.component.scss']
})
export class MyTeamsComponent implements OnInit {

  teams$: Observable<Team[]>;

  constructor(public authService: AuthService,
    private teamService: TeamService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.teams$ = this.teamService.getTeamsForUser();
  }

  addTeam(): void {
    this.dialog.open(
      AddTeamComponent, {
        width: '500px'
      });
  }

}
