<div [formGroup]="changeStartDateForm" fxLayoutGap="15px">
    <mat-form-field fxFlex="200px" appearance="outline">
        <mat-label translate>New start date</mat-label>
        <input required (click)="picker.open()" matInput [matDatepicker]="picker"
            formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>event</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="changeStartDateForm.hasError('required',['startDate'])"
            translate>
            The start date is required
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="200px" appearance="outline">
        <mat-label translate>Start time</mat-label>
        <input type="text" required [placeholder]="'Start time' | translate"
            aria-label="Start time" matInput formControlName="startTime"
            [matAutocomplete]="startTimeAuto">
        <mat-autocomplete #startTimeAuto="matAutocomplete">
            <mat-option *ngFor="let hour of filteredStartHours | async"
                [value]="hour.value">
                <span [innerHTML]="hour.viewValue | highlight: hourToHighlight"></span>
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="changeStartDateForm.hasError('required',['startTime'])"
            translate>
            The start time is required
        </mat-error>
        <mat-error *ngIf="changeStartDateForm.hasError('pattern',['startTime'])"
            translate>
            Time format is HH:mm (14:30)
        </mat-error>
    </mat-form-field>
</div>