<div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex="300px" appearance="outline">
        <mat-label translate>{{'Name' | translate}}</mat-label>
        <mat-hint>{{'Customer name and surname' | translate}}</mat-hint>
        <input type="text" required [formControl]="customerFormControl" matInput [matAutocomplete]="auto">
        <mat-error *ngIf="customerFormControl.hasError('required')">
            {{'You must introduce your customer name' | translate }}
        </mat-error>

        <mat-autocomplete (optionSelected)="onCustomerSelected($event)" autoActiveFirstOption #auto="matAutocomplete"
            [displayWith]="displayFn">
            <mat-option *ngFor="let customer of filteredOptions | async" [value]="customer">
                {{customer.name}} ({{customer.phone}})
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <app-customer-phone-editor fxFlex="200px" [phone]="phone" (onPhoneChange)="onCustomerPhoneChange($event)" [hint]="'Customer phone' | translate" [toucher]="toucher"
        (onValidityChange)="validComponents[0] = $event"></app-customer-phone-editor>
    <app-customer-address-editor fxFlex="grow" [address]="address" [hint]="'Customer address' | translate" [toucher]="toucher"
        (onCustomerAddressChange)="onCustomerAddressChange($event)" [isRequired]="addressCompulsory"
        (onValidityChange)="validComponents[0] = $event"></app-customer-address-editor>
</div>