import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { Category } from '../models/category';
import { CategoryService } from '../services/category.service';

import moment from 'moment';
import { LocalStorageService } from 'ngx-localstorage';
import { UtilsService } from '../services/utils.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input()
  user$: Observable<User | boolean>;

  @Input()
  loading: boolean = true;

  @Input()
  showRsvp: boolean = false;

  @Input()
  drawer: any;

  user: User;
  private userSub: Subscription;
  public environment: any;
  public allCategories: Category[] = [];
  public otherCategories: Category[] = [];
  public otherCategory: Category;
  public othetCategoriesExpanded: boolean = false;

  routerEvents: Subscription;
  selectedCat: string;
  isVMFest: boolean = false;

  constructor(private authService: AuthService,
    public snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: LocalStorageService,
    private utilsService: UtilsService,
    private categoryService: CategoryService,
    @Inject(PLATFORM_ID) private platformId: any,
    public translate: TranslateService,) {
    this.environment = environment;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setupURLVMFestListener();
      this.userSub = this.user$.subscribe((u: User) => { this.user = u; });
      if (this.environment.theme == 'trdevents') {
        this.loadCategories();
      }
    }
  }

  ngOnDestroy(): void {
    this.userSub?.unsubscribe();
    this.routerEvents?.unsubscribe();
  }

  setupURLVMFestListener() {
    this.routerEvents =  this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.route;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      map(route => route.snapshot.data)
    )
    .subscribe(data => {
      this.isVMFest = data?.custom === 'vm-fest';
    });
  }

  logOut() {
    this.loading = true;
    this
      .authService
      .signOut()
      .then(() => {
        this.snackBar.open(this.translate.instant("Signing out successful"), null, {
          duration: 4000,
        });
      })
      .catch((error) => {
        console.error(error);
        this.snackBar.open(this.translate.instant("An error occurred while signing out"), null, {
          duration: 4000,
        });
      });
  }

  switchLanguage(language: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(language);
      this.authService.changeLanguage(language);
      if (this.utilsService.storageAvailable()) {
        this.storageService.set('lan', language, 'hvaskjerkalender');
      }
      //Setting the locale for momentjs
      this.setMomentLocale(language);
      if (this.environment.theme == 'trdevents') {
        this.loadCategories();
      }
    }
  }

  setMomentLocale(language: string) {
    if (language != 'nb') {
      moment.locale('en-GB');
    } else {
      moment.locale(language);
    }
  }

  loadCategories() {
    this.allCategories = [];
    for (let sCat of this.categoryService.getAllCategories()) {
      if (sCat.id != 'OTHER') {
        this.allCategories.push(sCat);
      }
    }
    //So category other is being shown in the bottom
    this.otherCategory = this.categoryService.getAllCategories().find(c => c.id == 'OTHER');

    this.otherCategories = [];
    this.otherCategories.push({ ...this.otherCategory, parentCategory: this.otherCategory.id });
    for (let cat of this.otherCategory.subCategories) {
      if (cat.visible) {
        this.otherCategories.push(cat);
      }
    }
  }

}
