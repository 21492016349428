<div class="full-width guest-editor" fxLayout="column" fxLayoutGap="30px" >
    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
        <div class="info-block" fxLayout="column">
            <span class="label">{{'Status' | translate}}</span>
            <span class="value">{{getStatus(booking.status)}}</span>
        </div>
        <div class="info-block" fxLayout="column">
            <span class="label">{{'Booking number' | translate}}</span>
            <span class="value">{{booking.bookingNumber}}</span>
        </div>
        <div class="info-block" fxLayout="column">
            <span class="label">{{'Name' | translate}}</span>
            <span class="value">{{booking.customerName}}</span>
        </div>
        <div class="info-block" fxLayout="column">
            <span class="label">{{'Phone' | translate}}</span>
            <span class="value">{{booking.customerPhone}}</span>
        </div>
        <div class="info-block" fxLayout="column" *ngIf="booking.customerAddress?.formattedAddress">
            <span class="label">{{'Address' | translate}}</span>
            <span class="value">{{trim(booking.customerAddress.formattedAddress)}}</span>
        </div>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
        <div class="info-block" fxFlex="100" fxFlex.lt-lg="auto" fxLayout="column">
            <span class="label">{{'Comments' | translate}}</span>
            <span class="value">{{booking.comments || ('None' | translate)}}</span>
        </div>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
        <div class="info-block" fxLayout="column">
            <span class="label">{{'Event' | translate}}</span>
            <span class="value">{{trim(booking.eventTitle)}}</span>
        </div>
        <div class="info-block" fxLayout="column">
            <span class="label">{{'Venue' | translate}}</span>
            <span class="value">{{trim(booking.venueName)}}</span>
        </div>
        <div class="info-block" fxFlex="130px" fxFlex.lt-lg="auto" fxLayout="column">
            <span class="label">{{'Start date' | translate}}</span>
            <span class="value">{{getDateTime(booking.startDate?.seconds)}}</span>
        </div>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
        <div class="info-block" fxLayout="column">
            <span class="label">{{'Payment method' | translate}}</span>
            <span class="value">{{ booking.paymentMethod == 'invoice' ? ('Invoice' | translate) : ('At the door' | translate)}}</span>
        </div>
        <div class="info-block" fxLayout="column">
            <span class="label">{{'Cancellation deadline' | translate}}</span>
            <span class="value" *ngIf="maxCancellationDate">{{ maxCancellationDate }}</span>
        </div>
        <div class="info-block" fxLayout="column">
            <span class="label">{{'Tickets' | translate}}</span>
            <span class="value"><span *ngFor="let ticket of booking.tickets">{{ ticket.numberTickets > 0 ?
                    (ticket.numberTickets + 'x ' + getTicketType(ticket)) : ''}}</span></span>
        </div>
        <div class="info-block" fxLayout="column">
            <span class="label">{{'Total' | translate}}</span>
            <span class="value">{{ booking.totalAmount | currency:'NOK':'symbol':'0.2-2':'nb' }}</span>
        </div>
    </div>

    <div>
        <div class="full-width" fxLayoutGap="15px" fxLayout="column">
            <app-booking-edition [isAdmin]="false" [booking]="booking"></app-booking-edition>
        </div>
    </div>

</div>