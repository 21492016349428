import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-terms-and-conditions-rsvp',
  templateUrl: './terms-and-conditions-rsvp.component.html',
  styleUrls: ['./terms-and-conditions-rsvp.component.scss']
})
export class TermsAndConditionsRSVPComponent implements OnInit {

  environment: any;

  constructor(public translate: TranslateService) {
    this.environment = environment;
  }

  ngOnInit(): void {
  }

}
