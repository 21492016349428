import { Component, Input, OnInit } from '@angular/core';
import { Booking, Ticket } from '../models/booking';
import { formatDate } from '@angular/common';
import { BookingStatusService } from '../services/booking-status.service';
import { TicketTypeService } from '../services/ticket-type.service';
import { BookingService } from '../services/booking.service';
import { EventService } from '../services/event.service';
import { Event } from '../models/event';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-booking-guest-editor',
  templateUrl: './booking-guest-editor.component.html',
  styleUrls: ['./booking-guest-editor.component.scss']
})
export class BookingGuestEditorComponent implements OnInit {

  @Input()
  booking: Booking;

  maxCancellationDate: string;

  constructor(
    private utilsService: UtilsService,
    private bookingService: BookingService,
    private eventService: EventService,
    private bookingStatusService: BookingStatusService,
    private ticketTypeService: TicketTypeService) { }

  ngOnInit(): void {
    this.listenOnBookingChanges();
    this.setupDeadlineForCancellation();
  }

  listenOnBookingChanges() {
    this.bookingService.getBooking(this.booking.id).then((updatedBooking: Booking) => this.booking = updatedBooking);
  }

  setupDeadlineForCancellation() {
    const sub = this.eventService.getEventFromFirestore(this.booking.eventId, (event: Event) => {
      sub();
      const period: number = event.cancellationPeriod;
      this.maxCancellationDate = formatDate(new Date((this.booking?.startDate?.seconds - event.cancellationPeriod * 24 * 60 * 60) * 1000), "short", 'nb-NO');
    });
  }

  getDateTime(seconds: number): string {
    return formatDate(new Date(seconds * 1000), "short", 'nb-NO');
  }

  getStatus(status: string): string {
    return this.bookingStatusService.getBookingStatusName(status);
  }

  getTicketType(ticket: Ticket): string {
    return this.ticketTypeService.getTicketTypeName(ticket);
  }

  trim(myStr?: string, numChats?: number): string {
    return this.utilsService.trimWithMax(myStr || '', numChats || 40);
  }

}
