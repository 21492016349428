import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NewsletterService } from '../services/newsletter.service';

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss']
})
export class NewsletterFormComponent implements OnInit {

  result: any;

  newsletterForm = new FormGroup({
    email: new FormControl('', [ Validators.required, Validators.email ]),
  });

  constructor(private newsletterService: NewsletterService) { }

  ngOnInit() {
  }

  addSubscriber() {
    if (this.newsletterForm.valid) {
      let email = this.newsletterForm.controls['email'].value;
      this.newsletterService.addSubscriber(email).then(
        (result) => {
          this.result = result;
        }
      );
    }
  }

}
