import { Injectable, inject } from '@angular/core';

import { Observable, from } from 'rxjs';
import { map, mergeMap, last } from 'rxjs/operators';

import { Event } from '../models/event';
import { Venue } from '../models/venue';
import { environment } from '../../environments/environment';

import { UtilsService } from '../services/utils.service';
import { MapsService } from '../services/maps.service';
import { Storage, getDownloadURL, ref, uploadString } from '@angular/fire/storage';
import { collectionData, Firestore, collection, getDoc, doc, DocumentSnapshot, query, where, addDoc, deleteDoc } from '@angular/fire/firestore';


@Injectable()
export class VenueService {

  includeID = map((action: any) => {
    if (action.payload.data()) {
      return {
        id: action.payload.id,
        ...action.payload.data()
      }
    }
  });

  includeIDArray = map((actions: any) =>
    actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id;
      return {
        id,
        ...data,
      };
    })
  );

  orderVenuesByName = map((venues: Venue[]) => venues.sort((a, b) => { return a.name < b.name ? -1 : 1; }));

  //Returns a venue which name is at least 90% similar to the search term
  filterVenuesByNameSimilarity = (venueName: string) => map((venues: Venue[]) => {
    let maxSimilarityVenue = venues?.reduce((prev, current) => {
      return (this.utilsService.similarity(prev.name?.toLowerCase() || '', venueName?.toLowerCase() || '') > this.utilsService.similarity(current.name?.toLowerCase() || '', venueName?.toLowerCase() || '')) ? prev : current
    }) //returns object
    if (this.utilsService.similarity(maxSimilarityVenue?.name?.toLowerCase() || '', venueName?.toLowerCase() || '') >= 0.9) {
      return maxSimilarityVenue;
    }
    return null;
  });

  //Returns a venue which matches the address provided
  filterVenueByAddress = (venueAddress: string) => mergeMap((venues: Venue[]) =>
    this.mapsService.getLocation(venueAddress).pipe(
      map((response: any) => {
        if (response && response.status == 'OK' && response.results
          && response.results.length > 0) {
          const filteredVenues: Venue[] = venues.filter((venue: Venue) =>
            venue.location.latitude == response.results[0].geometry.location.lat
            && venue.location.longitude == response.results[0].geometry.location.lng);
          return filteredVenues.length > 0 ? filteredVenues[0] : null;
        }
        return null;
      })
    )
  );

  private firestore: Firestore = inject(Firestore);
  private storage: Storage = inject(Storage);

  constructor(
    private mapsService: MapsService,
    private utilsService: UtilsService) { }

  //Venues
  getAllVenues(): Observable<any> {
    return collectionData(collection(this.firestore, 'venues'), { idField: "id" })
      .pipe(
        this.orderVenuesByName
      );
  }

  getVenueByEvent(event: Observable<Event>): Observable<Venue> {
    return event
      .pipe(
        mergeMap(event =>
          from(getDoc(doc(this.firestore, "venues", event.venue)).then((snap: DocumentSnapshot<Venue>) => ({ id: snap.id, ...snap.data() })))
        )
      );
  }

  getVenueBySlug(venueSlug: string): Observable<Venue> {
    const colRef = collection(this.firestore, "venues");
    const w = where('slug', '==', venueSlug);
    const q = query(colRef, w);
    return collectionData(q, { idField: "id" }).pipe(map((venues: Venue[]) => venues[0]));
  }

  getVenueFromVenueName(venueName: string): Observable<Venue | null> {
    return collectionData(collection(this.firestore, "venues"), { idField: "id" })
      .pipe(
        this.filterVenuesByNameSimilarity(venueName)
      );
  }

  getVenueFromVenueAddress(venueAddress: string): Observable<Venue | null> {
    return collectionData(collection(this.firestore, "venues"), { idField: "id" })
      .pipe(
        this.filterVenueByAddress(venueAddress)
      );
  }

  downloadImageForVenue(venue: Venue): Observable<any> {
    let staticMapImageURL = 'https://maps.googleapis.com/maps/api/staticmap?center=' + venue.location.latitude + ',' + venue.location.longitude + '&scale=2&markers=' + venue.location.latitude + ',' + venue.location.longitude + '&zoom=16&size=600x300&key=' + environment.firebase.apiKey;
    const fileName = (venue.name + Math.random().toString().replace('0.', '')) + '.jpg';
    const filePath = '/venues/images/' + fileName;
    const r = ref(this.storage, filePath);

    return this.utilsService.getBase64ImageFromURL(staticMapImageURL)
      .pipe(
        map((base64data) => 'data:image/jpg;base64,' + base64data),
        mergeMap(async (base64Image: string) => { 
          const res = await uploadString(r, base64Image, 'data_url'); 
          return getDownloadURL(res.ref);
        })
      );
  }

  addVenue(venue: Venue): Observable<any> {
    return this.downloadImageForVenue(venue)
      .pipe(
        mergeMap((imageURL: string) => {
          venue.mapImageURL = imageURL;
          venue.mapImage_at = new Date();
          return from(addDoc(collection(this.firestore, "venues"), venue));
        })
      );
  }

  deleteVenue(venueId: string): Promise<any> {
    return deleteDoc(doc(this.firestore, "venues", venueId));
  }
}
