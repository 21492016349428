import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Event } from '../models/event';
import { BookingService } from '../services/booking.service';
import { Booking } from '../models/booking';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.scss']
})
export class MyTicketsComponent implements OnInit {

  @Input()
  event: Event;


  //Booking number form
  bookingNumberForm: FormGroup = new FormGroup({
    bookingNumber: new FormControl(),
    phoneReminder: new FormControl(null, [Validators.pattern(/^\d{8}$/i)]),
  });

  //Helpers
  loading: boolean = false;
  bookingNotFound: boolean = false;
  reminderResultCode: string;
  showPhoneReminder: boolean = false;
  showCancellationWarning: boolean = false;
  environment: any;

  //Booking
  booking: Booking;


  constructor(private bookingService: BookingService) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.showCancellationWarning = (((this.booking?.startDate?.seconds - (this.event?.cancellationPeriod * 24 * 60 * 60)) * 1000) < (new Date()).getTime()) && this.environment?.rsvpOptions?.defaultSMS?.supportPhone;
  }

  //Get the tickets from the phone and the booking number
  getTickets(): void {
    if (this.bookingNumberForm.get('bookingNumber').valid) {
      this.loading = true;
      const sub = this.bookingService.getBookingByBookingNumber(this.bookingNumberForm.get("bookingNumber").value).subscribe(
        (booking: Booking | null) => {
          this.loading = false;
          if (booking) {
            this.booking = booking;
            this.bookingNotFound = false;
          } else {
            this.bookingNotFound = true;
          }
        }
      )
    }
  }


  //Resend to the user the booking number of the tickets for this events
  resendBookingNumber() {
    if (this.bookingNumberForm.get('phoneReminder').valid) {
      this.loading = true;
      const phone_number = this.bookingNumberForm.controls.phoneReminder.value;
      const event_id = this.event.id;
      const sub = this.bookingService.resendBookingNumber(phone_number, event_id).subscribe(
        (result: any) => {
          sub?.unsubscribe();
          this.reminderResultCode = result.resultCode;
          if (result.resultCode == 'sms-sent') {
            this.showPhoneReminder = false;
          }
          this.loading = false;
        }
      );
    }
  }
}
