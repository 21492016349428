import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-manual-repetition',
  templateUrl: './manual-repetition.component.html',
  styleUrls: ['./manual-repetition.component.scss']
})
export class ManualRepetitionComponent implements OnInit {

  @Input()
  placeholderSingle: string;
  @Input()
  minDate: any;
  @Input()
  hours: any;
  @Input()
  index: number;

  @Output()
  onDateAdded = new EventEmitter();

  @Output()
  onDateRemoved = new EventEmitter();

  @Input()
  manualRepetitionForm: FormGroup;

  constructor() { }

  ngOnInit() {
    this.manualRepetitionForm.updateValueAndValidity();
  }

  static differentFromEventStart(startDateParent: any, startTimeParent: any, utilsService: UtilsService): ValidatorFn {
    return function(control: AbstractControl): { [key: string]: any } | null {
      const startDate = control.value.startDate;
      const startTime = control.value.startTime;
      if (startDate && startTime) {
        const formDateTime = utilsService.buildStartDateWithTime(startDate, startTime);
        const parentDateTime = utilsService.buildStartDateWithTime(startDateParent, startTimeParent);
        if (formDateTime.isSame(parentDateTime)) {
          return { 'noParentDate': true };
        }
      }
      return null;
    };
  }

  static laterThanEventStart(startDateParent: any, startTimeParent: any, utilsService: UtilsService): ValidatorFn {
    return function(control: AbstractControl): { [key: string]: any } | null {
      const startDate = control.value.startDate;
      const startTime = control.value.startTime;
      if (startDate && startTime) {
        const formDateTime = utilsService.buildStartDateWithTime(startDate, startTime);
        const parentDateTime = utilsService.buildStartDateWithTime(startDateParent, startTimeParent);
        if (formDateTime.isBefore(parentDateTime)) {
          return { 'afterParentDate': true };
        }
      }
      return null;
    };
  }

  addManualRepetition() {
    this.manualRepetitionForm.markAllAsTouched();
    if (this.manualRepetitionForm.valid) {
      this.onDateAdded.emit();
    }
  }

  removeManualRepetition() {
    this.onDateRemoved.emit(this.index);
  }

}
