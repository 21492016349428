import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';

import { AuthService } from '../services/auth.service';
import { FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-provider',
  templateUrl: './login-provider.component.html',
  styleUrls: ['./login-provider.component.scss']
})
export class LoginProviderComponent implements OnInit {

  accountExistsWithDifferentCredential: boolean = false;
  otherError: boolean = false;

  constructor(public authService: AuthService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Reached After redirect
      this.authService.getRedirectResult().then((result) => {
        if (result?.user) {
          this.openSnackBar(this.translate.instant("Logged in successfully!"), null);
        }
      }).catch(error => {
          console.error(error);
          // An error happened.
          if (error.code === 'auth/account-exists-with-different-credential') {
            this.accountExistsWithDifferentCredential = true;
          } else {
            this.otherError = true;
          }
        });
    }
  }

  signInGoogle() {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
    this.authService.loginWithProvider(googleProvider);
  }

  signInFacebook() {
    const facebookProvider = new FacebookAuthProvider();
    facebookProvider.addScope("email");
    this.authService.loginWithProvider(facebookProvider);
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
