import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { EventRepetition } from '../models/event-repetition';
import { UtilsService } from './utils.service';
import { TranslateService } from '@ngx-translate/core';

import moment from 'moment';


@Injectable()
export class RepetitionService {

  transformEventsToRepetitions = map((actions: any[]) => {
    return actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id;
      return {
        duration: data.duration,
        eventCancelled: data.eventCancelled,
        eventSoldOut: data.eventSoldOut,
        event_id: id,
        mode: data.mode || null,
        startDate: new Date(data.startDate.seconds * 1000),
        startTime: data.startTime,
        streamingURL: data.streamingURL || null,
        ticketsURL: data.ticketsURL,
        venue: data.venue || null,
        venueObj: data.venueObj || null
      };
    });
  });

  orderRepetitionsByStartDate = map((events: EventRepetition[]) => events.length > 0 ? events.sort((a, b) => { return a.startDate < b.startDate ? -1 : 1; }) : []);

  constructor(
    private translate: TranslateService,
    private utilsService: UtilsService) { }

  //Gets a JSON form and converts it to a list of events
  repetitionFormToEventRepetitions(stardDate: any, form: any): EventRepetition[] {
    let reps: EventRepetition[] = [];
    if (form.type == 'manual') {
      for (let manual of form.singleDates) {
        if (manual.startDate && manual.startTime && reps.indexOf(manual) == -1) {
          reps.push({
            startDate: this.utilsService.buildStartDateWithTime(manual.startDate, manual.startTime).toDate(),
            startTime: manual.startTime,
          });
        }
      }
    } else {
      let newDay = moment(stardDate);
      let indexDay: number = 1;
      let jumper: number = form.every;

      while ((form.ends == 'on' && moment(form.endDate).diff(newDay, 'days') > 0) || (form.ends == 'after' && reps.length < form.numOccurrences)) {
        newDay.add(indexDay, 'days');
        if (form.timeUnit == 'week') {
          if (form.weekDays.indexOf(newDay.day()) > -1) {
            reps.push({
              startDate: this.utilsService.buildStartDateWithTime(newDay, form.startTime).toDate(),
              startTime: form.startTime,
            });
          }
          if (newDay.day() == 6) {
            indexDay = (7 * (jumper - 1)) + 1;
          } else {
            indexDay = 1;
          }
        } else {
          reps.push({
            startDate: this.utilsService.buildStartDateWithTime(newDay, form.startTime).toDate(),
            startTime: form.startTime,
          });
          indexDay = jumper;
        }
      }
    }
    return reps;
  }

  getNextRepetitions(event, maxNum) {
    if (event.repetitions?.length > 0 && event.endDate?.seconds) {
      let endDateInSeconds = event.endDate.seconds;
      let reps = event.repetitions.sort((a, b) => { return a.startDate.seconds < b.startDate.seconds ? -1 : 1; }).filter(rep => rep.startDate?.seconds > endDateInSeconds);
      if (maxNum > 0) {
        return reps.slice(0, maxNum);
      }
      return reps;
    }
    return [];
  }

  getSlugForRep(rep) {
    let hour = +rep.startTime?.split(':')[0] || '';
    let minutes = +rep.startTime?.split(':')[1] || '';
    let startDate = moment(rep.startDate.seconds * 1000);
    return startDate.format('DDMMYYYY') + '_' + hour + '_' + minutes;
  }

  getStartDateForRep(rep) {
    let startDate = moment(rep.startDate.seconds * 1000);
    if (this.translate.currentLang == 'nb') {
      return startDate.format("ddd D. MMM");
    } else {
      return startDate.format("ddd, MMM D");
    }
  }

  getTimesForRep(rep) {
    let startDate = moment(rep.startDate.seconds * 1000);
    let displayedDate = startDate.format(this.utilsService.getTimeFormat());

    if (rep.duration && rep.duration > 0) {
      displayedDate += " - " + moment(rep.startDate.seconds * 1000).add(rep.duration, 'minutes').format(this.utilsService.getTimeFormat());
    }
    return displayedDate;
  }

  transformRepetitionFromGraphQL(repetitions: EventRepetition[]): any {
    return repetitions.map((rep) => {
      return { ...rep, startDate: { seconds: moment(rep.startDate, "YYYY-MM-DD HH:mm:ssZ").unix() }, endDate: { seconds: moment(rep.endDate, "YYYY-MM-DD HH:mm:ssZ").unix() } }
    });
  }
}
