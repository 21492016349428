import { Injectable, inject } from '@angular/core';
import { Functions, HttpsCallableResult, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class NewsletterService {


  private functions: Functions = inject(Functions);
  constructor() {}

  addSubscriber(email: string) : Promise<HttpsCallableResult> {
    const callable = httpsCallable(this.functions, 'addSubscriber');
    return callable({ email });
  }


}
