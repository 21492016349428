import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-report-event',
  templateUrl: './report-event.component.html',
  styleUrls: ['./report-event.component.scss']
})
export class ReportEventComponent implements OnInit {

  reason = new FormControl('', [Validators.required]);
  sending: boolean = false;

  constructor(
    private eventService: EventService,
    @Inject(MAT_DIALOG_DATA) public data: { eventId: string },
    public dialogRef: MatDialogRef<ReportEventComponent>) { }

  ngOnInit(): void {
  }

  report() {
    if (this.reason.valid) {
      this.sending = true;
      this.eventService.reportEvent(this.data.eventId, this.reason.value).then(
        (success: boolean) => {
          this.sending = false;
          this.dialogRef.close({ success });
        },
        () => {
          this.dialogRef.close({ success: false });
        }
      );
    }
  }
}
