import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customized-sms',
  templateUrl: './customized-sms.component.html',
  styleUrls: ['./customized-sms.component.scss']
})
export class CustomizedSMSComponent implements OnInit, OnChanges {

  @Input()
  action: string;

  @Input()
  status: string;

  @Input()
  numberSMS: number;

  @Input()
  isAdmin: boolean = true;

  @Output()
  smsContentChange: EventEmitter<string> = new EventEmitter();

  @Output()
  sendSmsChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  onValidityChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  //CONTROLS
  //SMS Content
  smsControl: FormControl = new FormControl();
  //Notify via SMS or not
  notifyBySMSControl: FormControl = new FormControl();

  ngOnInit(): void {
    if (this.isAdmin) {
      this.notifyBySMSControl.setValue(this.action == 'admin-send-sms');
      this.smsControl.valueChanges.subscribe((newSMSContent) => {
        this.smsContentChange.emit(newSMSContent.trim());
        this.onValidityChange.emit(!this.notifyBySMSControl.value || this.smsControl.valid);
      });
      this.notifyBySMSControl.valueChanges.subscribe((newSendSMS) => this.sendSmsChange.emit(newSendSMS));
      if (environment.rsvpOptions.defaultSMS[this.action]?.length > 0) {
        this.smsControl.setValue(environment.rsvpOptions.defaultSMS[this.action]);
      }
      if (this.status && environment.rsvpOptions.defaultSMS[this.action + '-' + this.status]?.length > 0) {
        this.smsControl.setValue(environment.rsvpOptions.defaultSMS[this.action + '-' + this.status].trim());
      }
    } else {
      this.sendSmsChange.emit(true);
      this.smsContentChange.emit(environment.rsvpOptions.defaultSMS[this.action]);
      this.onValidityChange.emit(true);
    }
    
  }


  ngOnChanges(changes: SimpleChanges) {
    //Only execute when there is a change
    if (changes.status?.currentValue && environment.rsvpOptions.defaultSMS[this.action + '-' + changes.status?.currentValue]?.length > 0) {
      this.smsControl.setValue(environment.rsvpOptions.defaultSMS[this.action + '-' + changes.status?.currentValue].trim());
    }
  }

  insert(keyword: string): void {
    this.smsControl.setValue((this.smsControl.value || '') + " " + keyword);
  }


}
