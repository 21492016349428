<div class="wrapper" [ngClass]="environment.theme == 'trdevents' ? 'trdevents' : 'classic'">
  <div class="page mat-elevation-z1" >
    <div *ngIf="translate.currentLang != 'nb'">
      <h1 class="mat-h1">What is "{{environment.content.siteNameTranslatable.en}}"</h1>
      <div class="mat-body">
        <p *ngIf="environment.theme == 'trdevents'">"{{environment.content.siteNameTranslatable.en}}" is Trondheim's largest event listing for activities in Trondheim.</p>
        <p *ngIf="environment.theme == 'classic'">"{{environment.content.siteNameTranslatable.en}}" is an event website with an overview of activities in {{environment.town.name}}.</p>
        <p>The organizers can <a class="blue" routerLink="/add-an-event">register their own events</a> on "{{environment.content.siteNameTranslatable.en}}"</p>
        <p>Here you can keep up to date on what's going on in the city.</p>
        <p *ngIf="environment.newsletterEnabled">You can also subscribe to the <a routerLink="newsletter">newsletter</a>, which is sent weekly.</p>
        <p>With "{{environment.content.siteNameTranslatable.en}}" is easy to navigate through the many events. You can search for the desired date, place or category.</p>
        <p>There is also useful information, such as maps, ticket prices, age limits and if the event has still tickets available.</p>
      </div>
    </div>
    <div *ngIf="translate.currentLang == 'nb'">
      <h1 class="mat-h1">Hva er "{{environment.content.siteNameTranslatable.nb}}"</h1>
      <div class="mat-body">
        <p *ngIf="environment.theme == 'trdevents'">"{{environment.content.siteNameTranslatable.nb}}" er Trondheims største arrangementsoversikt over aktiviteter i Trondheim.</p>
        <p *ngIf="environment.theme == 'classic'">"{{environment.content.siteNameTranslatable.nb}}" er en oversikt over aktiviteter i {{environment.town.name}}.</p>
        <p>Arrangører <a class="blue" routerLink="/add-an-event">registrerer selv sine arrangementer</a> på "{{environment.content.siteNameTranslatable.nb}}".</p>
        <p>Her kan du holde deg oppdatert på hva som skjer i {{environment.town.name}}.</p>
        <p *ngIf="environment.newsletterEnabled">Du kan også abonnere på <a class="blue" routerLink="/newsletter">nyhetsbrevet</a>, som sendes ut ukentlig.</p>
        <p>På "{{environment.content.siteNameTranslatable.nb}}" er det enkelt å navigere seg gjennom arrangementer. Du kan søke på ønsket dato, sted eller kategori.</p>
        <p>Her finnes også nyttig informasjon som beliggenhet på kart, billettpriser, aldersgrense og om arrangementet det fortsatt finnes billetter til arrangementet</p>
      </div>
    </div>
  </div>
</div>
