<div class="navigation-wrapper" fxLayout="column" fxLayoutAlign="start center">
  <div class="navigation-toolbar full-width" fxLayout="row" fxLayoutAlign="space-between end">
    <div *ngIf="isLoading">{{'Loading' | translate}}...</div>
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!isLoading" fxLayoutGap="5px">
      {{eventListService.getTotalHits() + " " + ('found' | translate)}}
    </div>   
    <a routerLink="/add-an-event" *ngIf="environment.ui.showAddEventForVisitors || (authService.user | async)" class="add-event-button" color="primary" mat-raised-button>
      <img class="icon-button" src="assets/icons/add_circle.svg"/> <span fxShow="true" fxShow.xs="false"  translate>Add an event</span><span fxShow="false" fxShow.xs="true" translate>Event</span>
    </a>
  </div>
</div>
<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="wrapper event-list" #eventListContainer>
  <div class="list-container mat-elevation-z1" *ngIf="eventList && !isLoading">
    <div *ngIf="eventList.length == 0">
      <div class="no-events" translate>No events found with this criteria</div>
    </div>
    <div *ngIf="eventList.length > 0" fxLayout="column" fxLayoutAlign="start center">
      <app-event-list-item *ngFor="let event of eventList; let last = last; let i = index" [indexInList]="i" [event]="event" [last]="last" (afterViewInit)="last ? lastEventIsRendered() : null" class="full-width"></app-event-list-item>
    </div>
  </div>
</div>
<div class="navigation-wrapper" fxLayout="column" fxLayoutAlign="start center" *ngIf="!isLoading && eventList && eventList.length > 0">
  <div class="navigation-toolbar full-width" fxFlex="60px" fxLayout="row" fxLayoutAlign="center center">
    <div class="button-nav-container">
      <button *ngIf="moreEventsAvailable() && !loadMoreIsLoading" (click)="loadMoreEvents()" mat-raised-button>
        <mat-icon>cached</mat-icon> {{'Load more events' | translate}}
      </button>
      <div *ngIf="loadMoreIsLoading">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
  </div>
</div>
