<div class="wrapper classic" *ngIf="environment.theme == 'classic'">
  <div class="login-screen mat-elevation-z1" *ngIf="!loading && !(authService.user | async)">
    <h1 *ngIf="environment.registration.allowPublic" class="mat-h1 margin-bottom" translate>Login or register</h1>
    <h1 *ngIf="!environment.registration.allowPublic" class="mat-h1" translate>Login</h1>
    <div fxLayout.xs="column" fxLayout="row" fxLayoutAlign="space-evenly center">
      <div fxFlex="270px" fxFlex.xs="auto" fxFlexOrder="1" fxFlexOrder.xs="3" ngClass.xs="full-width">
        <app-login></app-login>
      </div>
      <div *ngIf="environment.registration.allowPublic && environment.registration.allowLoginProviders" fxHide.xs="true" fxFlexOrder="2" fxFlex="nogrow">
        <mat-divider [vertical]="true"></mat-divider>
      </div>
      <div *ngIf="environment.registration.allowPublic && environment.registration.allowLoginProviders" fxFlex="50px" class="margin-top" fxShow.xs="true" fxFlexOrder.xs="2" fxShow.gt-sm="false">
        - <span translate>OR</span> -
      </div>
      <div *ngIf="environment.registration.allowPublic && environment.registration.allowLoginProviders" fxFlex="270px" fxFlex.xs="auto" fxFlexOrder="3" fxFlexOrder.xs="1">
        <app-login-provider></app-login-provider>
      </div>
    </div>
    <div class="agreement"><span translate>By registering on our site, you are agreeing to our</span>&nbsp;<a class="blue" routerLink="/privacy-policy"><span translate>privacy policy</span></a></div>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>

<div class="wrapper trdevents" *ngIf="environment.theme == 'trdevents'">
  <div *ngIf="!loading && !(authService.user | async)">
    <div fxLayout.lt-md="column" fxLayout="row" fxLayoutAlign="space-evenly center">
      <div fxFlex="270px" fxFlex.lt-md="auto" fxFlexOrder="1" fxFlexOrder.lt-md="3" ngClass.lt-md="padding-login">
        <app-login></app-login>
      </div>
      <div fxHide.lt-md="true" fxFlexOrder="2" fxFlex="nogrow">
        <mat-divider [vertical]="true"></mat-divider>
      </div>
      <div fxFlex="50px" class="margin-top or" fxShow.lt-md="true" fxFlexOrder.lt-md="2" fxShow.gt-sm="false">
        - <span translate>OR</span> -
      </div>
      <div fxFlex="270px" fxFlex.lt-md="auto" fxFlexOrder="3" fxFlexOrder.lt-md="1">
        <app-login-provider></app-login-provider>
      </div>
    </div>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>

