<div class="wrapper">
  <div *ngIf="isLoading$ | async" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="!(isLoading$ | async)">
    <div class="add-event-form mat-elevation-z1" *ngIf="user">
      <h2 *ngIf="isEdit" class="mat-title" translate>Edit event</h2>
      <app-event-form [event]="event" [isEdit]="isEdit"></app-event-form>
    </div>
  </div>
</div>
