import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { map, first, tap, catchError } from 'rxjs/operators';
import { BookingService } from '../services/booking.service';

@Component({
  selector: 'app-customer-phone-editor',
  templateUrl: './customer-phone-editor.component.html',
  styleUrls: ['./customer-phone-editor.component.scss']
})
export class CustomerPhoneEditorComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  phone: number;

  @Input()
  eventId: string;

  @Input()
  eventStartDateSeconds: number;

  @Input()
  eventVenueName: string;

  @Input()
  hint?: string = this.translate.instant("You will get an SMS with the confirmation");

  @Input()
  toucher: Observable<any>;

  @Output()
  onPhoneChange: EventEmitter<string> = new EventEmitter();

  @Output()
  onValidityChange: EventEmitter<boolean> = new EventEmitter();

  customerPhone: FormControl = new FormControl(null, { validators: [Validators.pattern(/^\d{8}$/i), Validators.required], asyncValidators: this.validPhoneNumber(this.bookingService) });
  sub: Subscription;
  
  constructor(private bookingService: BookingService, private translate: TranslateService) { }

  ngOnInit(): void {
    if (this.phone) {
      this.customerPhone.setValue(this.phone);
    }
    this.customerPhone.valueChanges.subscribe((newPhone) => {
      this.onPhoneChange.emit(newPhone);
      this.onValidityChange.emit(this.customerPhone.status == 'VALID');
    });
    this.customerPhone.statusChanges.subscribe((newStatus) => {
      this.onValidityChange.emit(newStatus == 'VALID');
    });
    this.sub = this.toucher?.subscribe(() => this.customerPhone.markAllAsTouched());
  }
  
  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.phone?.currentValue) {
      this.customerPhone.setValue(changes.phone.currentValue);
    }
    this.customerPhone.updateValueAndValidity();
  }

  validPhoneNumber(bookingService: BookingService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      if (this.eventId && this.eventStartDateSeconds && this.eventVenueName) {
        return bookingService.existsPhoneForEvent(control.value, this.eventId, this.eventStartDateSeconds, this.eventVenueName).pipe(
          map(exists => exists ? { "phoneExistsForActiveBooking": true } : null),
          first(),
          catchError(() => of({ "phoneExistsForActiveBooking": true }))
        );  
      } else {
        return of(null);
      }
    }
      
  }


}
