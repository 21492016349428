import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import Rollbar from 'rollbar';
import { Customer } from '../models/customer';
import { Address } from '../models/venue';
import { RollbarService } from '../modules/rollbar';
import { CustomerService } from '../services/customer.service';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {

  @Input()
  customer: Customer;

  @Output()
  onExecutionDone: EventEmitter<Customer> = new EventEmitter();

  updatedCustomer: Customer;

  //SMS, first content param, second content param
  validComponents: boolean[] = [];

  constructor(private customerService: CustomerService,
    public snackBar: MatSnackBar,
    @Inject(RollbarService) private rollbar: Rollbar,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.updatedCustomer = this.customer || new Customer();
  }

  //FORM LISTENERS
  onCustomerNameChange(newName: string) {
    this.updatedCustomer.name = newName;
  }

  onCustomerPhoneChange(newPhone: number) {
    this.updatedCustomer.phone = newPhone;
  }

  onAddressChange(newAddress: Address) {
    this.updatedCustomer.address = newAddress;
  }

  onCustomerCommentsChange(newComments: string) {
    this.updatedCustomer.comments = newComments;
  }

  async saveCustomer() {
    if (this.validComponents.every((v) => v == true)) {
      try {
        if (this.customer) {
          await this.customerService.updateCustomer(this.customer.id, this.updatedCustomer);
          this.onExecutionDone.emit(this.updatedCustomer);
        } else {
          const cost: Customer = await this.customerService.addCostumer(this.updatedCustomer);
          this.onExecutionDone.emit(cost);
        }
      } catch (e) {
        this.snackBar.open(this.translate.instant("There was an error while saving your customer information. Please try again or contact us."), null, {
          duration: 4000,
        });
        this.rollbar.error('Error addCostumer or updateCustomer' + JSON.stringify(e));
        console.error(e);
      }
    }
  }
}
