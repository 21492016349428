import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

@Pipe({
  name: 'firestoreTimestamp'
})
export class FirestoreTimestampPipe implements PipeTransform {

  transform(value: any): Date | null {
    if (!value) return null;
    // Check if value is a Firestore Timestamp
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    // Check if value is a Date
    if (value instanceof Date) {
      return value
    }
    // Check if value is an Object coming from a JSON parse
    if (value instanceof Object) {
      return this.toFirestoreTimestamp(value).toDate();
    }
    return null;
  }

  // Method to "convert" or "rebuild" a Timestamp from such an object
  toFirestoreTimestamp(obj: { _seconds: number; _nanoseconds: number }): Timestamp {
    return new Timestamp(obj._seconds, obj._nanoseconds);
  }

}
