<div class="label">{{'Log entries' | translate}}</div>
<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
    <div *ngFor="let log of logEntries" class="log" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <div class="status">
            <mat-icon class="success" *ngIf="log.status == 'success'">
                check_circle</mat-icon>
            <mat-icon class="success" *ngIf="log.status == 'delivered'">
                sms</mat-icon>
            <mat-icon class="warn" *ngIf="log.status == 'error'">cancel</mat-icon>
            <mat-icon class="rotating" *ngIf="log.status == 'executing' || log.status == 'waiting-sms-response'">
                autorenew</mat-icon>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
            <div><strong>{{ log.timestamp | firestoreTimestamp |
                date:'short':'CET':translate.currentLang }}</strong>: <span class="title">{{log.name}} <span
                        *ngIf="log.author">{{'by' | translate}}</span> {{ log.author}}</span></div>
            <div class="content" *ngIf="log.content">{{
                log.content }}</div>
        </div>
    </div>
</div>