import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-booking-status-editor',
  templateUrl: './booking-status-editor.component.html',
  styleUrls: ['./booking-status-editor.component.scss']
})
export class BookingStatusEditorComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  status: string;

  @Input()
  hint?: string;

  @Input()
  toucher: Observable<any>;

  @Output()
  onStatusChange: EventEmitter<string> = new EventEmitter();

  @Output()
  onValidityChange: EventEmitter<boolean> = new EventEmitter();

  statusForm: FormControl = new FormControl();
  sub: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.statusForm.setValue(this.status || '');
    this.statusForm.valueChanges.subscribe((newStatus) => {
      this.onStatusChange.emit(newStatus);
      this.onValidityChange.emit(this.statusForm.valid);
    });
    this.sub = this.toucher?.subscribe(() => this.statusForm.markAllAsTouched());
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.status?.currentValue) {
      this.statusForm.setValue(changes.status.currentValue);
    }
    if (changes?.hint?.currentValue) {
      this.hint = changes.hint.currentValue;
    }
  }

}
