import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: "phone"
})
export class PhonePipe implements PipeTransform {
  transform(rawNum: any) {
    if (rawNum?.toString().trim().length == 8) {
      //Split with spaces
      return rawNum.toString().substring(0, 3) + " " + rawNum.toString().substring(3, 5) + " " + rawNum.toString().substring(5);
    }
    return rawNum;
  }
}