<h2 mat-dialog-title>{{'Change start date' | translate}}</h2>
<form [formGroup]="startDateForm" (keydown.enter)="preventClosing($event)">
    <div mat-dialog-content>

        <mat-form-field  appearance="outline">
            <mat-label translate>Start date</mat-label>
            <input required (click)="picker.open()" matInput [matDatepicker]="picker" [min]="minDate"
                formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>event</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="startDateForm.hasError('required',['startDate'])" translate>
                The start date is required
            </mat-error>
            <mat-error *ngIf="startDateForm.hasError('matDatepickerMin',['startDate'])" translate>
                The start date can not be before today
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label translate>Start time</mat-label>
            <input type="text" [placeholder]="'Start time' | translate" aria-label="Start time" matInput
                formControlName="startTime" [matAutocomplete]="startTimeAuto">
            <mat-autocomplete #startTimeAuto="matAutocomplete">
                <mat-option *ngFor="let hour of filteredStartHours | async" [value]="hour.value">
                    <span [innerHTML]="hour.viewValue | highlight: hourToHighlight"></span>
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="startDateForm.hasError('required',['startTime'])" translate>
                The start time is required
            </mat-error>
            <mat-error *ngIf="startDateForm.hasError('pattern',['startTime'])" translate>
                Time format is HH:mm (14:30)
            </mat-error>
        </mat-form-field>

    </div>
    <div align="end" mat-dialog-actions>
        <span class="fill-spacer"></span>
        <button mat-button (click)="onCancel()">Cancel</button>
        <button [disabled]="!startDateForm.valid" mat-raised-button color="primary" type="button"
            (click)="changeStartDate()">{{'Change' | translate}}</button>
    </div>
</form>