<mat-form-field appearance="outline">
    <mat-label>{{'New booking status' | translate }}</mat-label>
    <mat-select [formControl]="statusForm" required>
        <mat-option value="active">{{'Active' | translate}}</mat-option>
        <mat-option value="waiting_list">{{'Waiting list' | translate}}</mat-option>
        <mat-option value="event_cancelled">{{'Event cancelled' | translate}}
        </mat-option>
        <mat-option value="cancelled">{{'cancelled_ticket' | translate}}</mat-option>
        <mat-option value="attended">{{'Attended' | translate}}</mat-option>
        <mat-option value="no_show">{{'No show' | translate}}</mat-option>
    </mat-select>
    <mat-hint *ngIf="hint">
        {{hint}}
    </mat-hint>
    <mat-error *ngIf="statusForm.hasError('required')" translate>
        The new status is required
    </mat-error>
</mat-form-field>