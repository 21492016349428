import { Component, OnInit, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SeoService } from '../services/seo.service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  environment: any;
  formIsWorking: boolean = false;

  constructor(
    private utilsService: UtilsService,
    private translate: TranslateService,
    private seo: SeoService,
    public snackBar: MatSnackBar) {
    this.environment = environment;
  }

  contactForm = new FormGroup({
    email: new FormControl('', [Validators.email]),
    message: new FormControl('', []),
  });

  ngOnInit() {
    this.generateSEOTags();
  }

  generateSEOTags() {
    this.seo.generateTags({
      title: this.translate.instant("Contact") + ' | ' + environment.content.siteName,
      description: this.translate.instant("Contact us with the form below"),
      slug: 'contact'
    })
  }

  send() {
    if (this.contactForm.valid) {
      this.formIsWorking = true;
      const email = this.contactForm.controls['email'].value;
      const message = this.contactForm.controls['message'].value;
      const lan = this.translate.currentLang;
      this.utilsService.contact({ message, email, lan }).then(
        () => {
          this.contactForm.reset();
          this.formIsWorking = false;
          this.snackBar.open(this.translate.instant("Your message was sent successfully."), null, {
            duration: 4000,
          });
        },
        () => {
          this.contactForm.reset();
          this.formIsWorking = false;
          this.snackBar.open(this.translate.instant('There was a problem sending your message. Please contact us at') + " " + (environment.contact.email || environment.contact.phone), null, {
            duration: 4000,
          });
        }
      );
    }
  }

}
