import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Booking } from '../models/booking';
import { SelectableEvent } from '../models/selectable-event';
import { UtilsService } from '../services/utils.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-booking-start-date-editor',
  templateUrl: './booking-start-date-editor.component.html',
  styleUrls: ['./booking-start-date-editor.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class BookingStartDateEditorComponent implements OnInit {

  @Input()
  booking: Booking;

  @Output()
  onStartDateChange: EventEmitter<Date> = new EventEmitter();

  @Output()
  onValidityChange: EventEmitter<boolean> = new EventEmitter();

  startDate: FormControl = new FormControl();

  //Start date 
  regexpHour = /^\d{1,2}:\d{2}$/i;
  changeStartDateForm: FormGroup = new FormGroup({
    startDate: new FormControl(),
    startTime: new FormControl(null, [Validators.pattern(this.regexpHour)])
  });
  hours: SelectableEvent[];
  filteredStartHours: Observable<any[]>;
  filteredEndHours: Observable<any[]>;
  hourToHighlight: string = '';

  constructor(public utilsService: UtilsService,
    private adapter: DateAdapter<any>,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.initFilterHours();
    this.changeStartDateForm.valueChanges.subscribe((newStartDate) => {
      this.onValidityChange.emit(this.changeStartDateForm.valid);
      if (this.changeStartDateForm?.valid) {
        const startDate = this.utilsService.buildStartDateWithTime(newStartDate.startDate, newStartDate.startTime).toDate();
        if (startDate) {
          this.onStartDateChange.emit(startDate);
        }
      }
    });
    if (this.booking) {
      const bookingDate = new Date(this.booking.startDate?.seconds * 1000);
      this.changeStartDateForm.setValue({ startDate: bookingDate, startTime: bookingDate.getHours() + ":" + (bookingDate.getMinutes() == 0 ? '00' : bookingDate.getMinutes()) });
    }

    this.setAdapterLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((newLan) => {
      //If the language change while we are on this screen
      this.setAdapterLocale(this.translate.currentLang);
    });

  }

  setAdapterLocale(language: string) {
    if (language != 'nb') {
      this.adapter.setLocale('en-GB');
    } else {
      this.adapter.setLocale(language);
    }
  }


  initFilterHours(): void {
    this.hours = this.utilsService.getAllHours();
    this.filteredStartHours = this.changeStartDateForm.controls.startTime.valueChanges
      .pipe(
        startWith(''),
        map(userInput => this.filterHours(userInput))
      );
  }

  filterHours(userInput: string) {
    this.hourToHighlight = userInput;
    return this.hours.filter(hour => hour.value.indexOf(userInput) === 0);
  }

}
