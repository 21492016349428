<div class="wrapper" [ngClass]="environment.theme == 'trdevents' ? 'trdevents' : 'classic'">
  <div class="page mat-elevation-z1" *ngIf="translate.currentLang == 'en'">
    <h1 class="mat-h1">Advertising in the newsletter</h1>
    <div class="mat-body" *ngIf="environment.newsletterEnabled">
      <p>We offer advertising space for organizers in the weekly newsletter.</p>
      <p>Practical information for advertising in the newsletter:</p>
      <ul>
        <li>The newsletter is sent every week, night to Wednesday</li>
        <li>Ad size: 600 x 600 pixels</li>
        <li>Remember to add link to ticket sales / landing page when the advertisement is submitted</li>
        <li *ngIf="environment.adsPrice != 0">Price per week: NOK {{environment.adsPrice}}</li>
        <li>Please book your ad space in good time</li>
        <li *ngIf="environment.content?.numberSubscribers">More than {{environment.content.numberSubscribers}} will receive the newsletter</li>
      </ul>
      <p><span>To order advertisement contact us on</span>&nbsp;<a class="underline" [href]="'mailto:' + environment.contact.email">{{environment.contact.email}}</a></p>
    </div>
    <div class="mat-body" *ngIf="!environment.newsletterEnabled">
      <p>As of today, there is no newsletter connected {{environment.content.siteName}}.</p>
      <p>Contact {{environment.owner.name}} if you have any question: {{environment.contact.email}}</p>
    </div>
  </div>
  <div class="page mat-elevation-z1" *ngIf="translate.currentLang == 'nb'">
    <h1 class="mat-h1">Annonsering på nyhetsbrev</h1>
    <div class="mat-body" *ngIf="environment.newsletterEnabled">
      <p>Vi tilbyr annonseplass for arrangører i det ukentlige nyhetsbrevet.</p>
      <p>Praktisk informasjon for annonsering i nyhetsbrevet:</p>
      <ul>
        <li>Nyhetsbrevet sendes ut hver uke, natt til onsdag</li>
        <li>Annonsestørrelse: 600 x 600 pixler</li>
        <li>Husk å legge ved link til billettsalg/landingsside når annonsen sendes inn</li>
        <li *ngIf="environment.adsPrice != 0">Pris per uke: NOK {{environment.adsPrice}}</li>
        <li>Book gjerne annonseplass i god tid</li>
        <li *ngIf="environment.content?.numberSubscribers">Mer enn {{environment.content.numberSubscribers}} vil få nyhetsbrevet</li>
      </ul>
      <p><span>For å bestille annonseplass kontakt:</span>&nbsp;<a class="underline" [href]="'mailto:' + environment.contact.email">{{environment.contact.email}}</a></p>
    </div>
    <div class="mat-body" *ngIf="!environment.newsletterEnabled">
      <p>Per dags dato er det ingen nyhetsbrev koblet til {{environment.content.siteName}}.</p>
      <p>Kontakt {{environment.owner.name}} om du har spørsmål: {{environment.contact.email}}</p>
    </div>
  </div>
</div>
