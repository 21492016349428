import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ScriptService } from 'ngx-script-loader';

declare const Mazemap: any;

@Component({
  selector: 'app-mazemap-visor',
  templateUrl: './mazemap-visor.component.html',
  styleUrls: ['./mazemap-visor.component.scss']
})
export class MazemapVisorComponent implements OnInit {

  @Input()
  poiID: string;

  //Map variables
  myMap: any;
  mazeMarker: any;

  room: string;
  building: string;

  constructor(private scriptService: ScriptService) { }

  //INIT
  ngOnInit() {
    //Setup google maps for usage
    this.scriptService.runScript('https://api.mazemap.com/js/v2.0.19/mazemap.min.js').subscribe(() => this.onMazemapAPIReady());
  }

  onMazemapAPIReady(): void {
    //Add marker if it exists
    if (this.poiID) {
      Mazemap.Data.getPoi(this.poiID).then(poi => {
        this.room = poi.properties.title;
        this.building = poi.properties.buildingName;
        this.initializeMap(poi);
      });
    }
  }

  initializeMap(poi: any) {
    //Initializing map
    this.myMap = new Mazemap.Map({
      // The DOM element ID for the map
      container: 'mazemap-container',
      // Initial position of map
      center: Mazemap.Util.getPoiLngLat(poi),
      zoom: 17
    });

    this.myMap.on('load', () => {
      // Initialize a Highlighter for POIs
      // Storing the object on the map just makes it easy to access for other things
      this.myMap.highlighter = new Mazemap.Highlighter(this.myMap, {
        showOutline: true,
        showFill: true,
        outlineColor: Mazemap.Util.Colors.MazeColors.MazeBlue,
        fillColor: Mazemap.Util.Colors.MazeColors.MazeBlue
      });

      //Add marker
      this.placePoiMarker(poi);
    });
  }


  placePoiMarker(poi) {
    // Get a center point for the POI, because the data can return a polygon instead of just a point sometimes
    var lngLat = Mazemap.Util.getPoiLngLat(poi);
    var zLevel = poi.properties.zLevel;
    this.mazeMarker = new Mazemap.MazeMarker({
      color: '#bcd025',
      innerCircle: true,
      innerCircleColor: '#FFF',
      size: 34,
      innerCircleScale: 0.5,
      zLevel: zLevel
    })
      .setLngLat(lngLat)
      .setZLevel(zLevel)
      .addTo(this.myMap);
    this.myMap.zLevel = zLevel;
    // If we have a polygon, use the default 'highlight' function to draw a marked outline around the POI.
    if (poi.geometry.type === "Polygon") {
      this.myMap.highlighter.highlight(poi);
    }
  }

}
