import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'firebase/auth';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-more',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.scss']
})
export class MoreComponent implements OnInit {

  user: User;
  private userSub: Subscription;
  public environment: any;

  constructor(private authService: AuthService,
    public snackBar: MatSnackBar,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    public translate: TranslateService,) {
    this.environment = environment;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.userSub = this.authService.getCurrentUser().subscribe((u: User) => {
        this.user = u;
        if (this.router.url == '/organizers') {
          if (this.user) {
            this.router.navigate(['organizers', { outlets: { organizers: 'my-events' } }]);
          } else {
            this.router.navigate(['organizers', { outlets: { organizers: 'login' } }]);
          }  
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.userSub?.unsubscribe();
  }

  logOut() {
    this
      .authService
      .signOut()
      .then(() => {
        this.router.navigate(['/']);
        this.snackBar.open(this.translate.instant("Signing out successful"), null, {
          duration: 4000,
        });
      })
      .catch((error) => {
        console.error(error);
        this.snackBar.open(this.translate.instant("An error occurred while signing out"), null, {
          duration: 4000,
        });
      });
  }
}
