import { Injectable, inject } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Team } from '../models/team';
import { Event } from '../models/event';
import { from, of, zip } from 'rxjs';
import { User } from '../models/user';
import { Firestore, addDoc, collection, collectionData, deleteDoc, doc, getDoc, or, query, updateDoc, where } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  //Order by startDate
  orderTeamsAlphabetically = map((teams: Team[]) => teams?.length > 0 && teams?.sort((a, b) => { return a.name < b.name ? -1 : 1; }) || []);

  private firestore: Firestore = inject(Firestore);

  constructor(
    private authService: AuthService) { }


  //Get all the teams for which a user is an event editor or a booking editor
  getTeamsForUser(): Observable<Team[]> {
    return this.authService.getCurrentUser().pipe(
      mergeMap((user: any) => {
        if (user?.email) {
          const colRef = collection(this.firestore, "teams");
          const w = where('users', 'array-contains', user.email);
          const w1 = where('users_bookings', 'array-contains', user.email);
          const w2 = where('editors', 'array-contains', user.email);
          const q = query(colRef, or(w, w1, w2));
          return collectionData(q, { idField: "id" });
        } else {
          return of([]);
        }
      }),
      this.orderTeamsAlphabetically
    );
  }

  //All the teams for which a user is an event editor
  getTeamsForUserAsEventEditor(): Observable<Team[]> {
    return this.authService.getCurrentUser().pipe(
      mergeMap((user: any) => {
        if (user?.email) {
          const colRef = collection(this.firestore, "teams");
          const w = where('users', 'array-contains', user.email);
          const q = query(colRef, w);
          return collectionData(q, { idField: "id" });
        } else {
          return of([]);
        }
      }),
      this.orderTeamsAlphabetically
    );
  }

  //All the teams for which a user is a booking editor
  getTeamsForUserAsBookingEditor(): Observable<Team[]> {
    return this.authService.getCurrentUser().pipe(
      mergeMap((user: any) => {
        const colRef = collection(this.firestore, "teams");
        const w = where('users_bookings', 'array-contains', user.email);
        const q = query(colRef, w);
        return collectionData(q, { idField: "id" });
      }),
      this.orderTeamsAlphabetically
    );
  }

  userInTeamAsEventEditor(event: Event): Observable<boolean> {
    return this.getTeamsForUserAsEventEditor().pipe(
      map((teams: Team[] | null) => (teams?.filter(team => team.id == event.editableBy).length > 0) || false)
    );
  }

  hasUserPermissions(event: Event): Observable<boolean> {
    return this.authService.getCurrentUser()
      .pipe(
        mergeMap(
          (user: any) => {
            if (user?.uid) {
              return this.authService.getUserInfo(user.uid)
                .pipe(
                  map((userInfo: User) => (userInfo?.roles?.admin || userInfo?.roles?.owner || (userInfo?.roles?.author && event.author_id == userInfo?.uid)))
                );
            }
            return of(false);
          })
      );
  }

  public canUserEditEvent(event: Event) {
    return zip(this.userInTeamAsEventEditor(event), this.hasUserPermissions(event)).pipe(
      map((userPermissions: boolean[]) => userPermissions.reduce((prev, curr, idx, array) => prev || curr)),
    );
  }


  getTeam(teamId: string): Observable<Team> {
    const docRef = doc(this.firestore, 'teams', teamId);
    return from(getDoc(docRef).then((snap) => (snap.exists() ? (<Team>{ id: snap.id, ...snap.data() }) : null)));
  }

  addTeam(team: Team): Promise<any> {
    return addDoc(collection(this.firestore, "teams"), team);
  }

  updateTeam(teamID: string, team: Team): Promise<any> {
    return updateDoc(doc(this.firestore, "teams", teamID), { ...team });
  }

  deleteTeam(teamID: string): Promise<any> {
    return deleteDoc(doc(this.firestore, "teams", teamID));
  }

}
