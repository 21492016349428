<h2 mat-dialog-title>Send a test campaign</h2>
<form (keydown.enter)="preventClosing($event)">
  <div mat-dialog-content>
    <div id="send-test" *ngIf="formIsWorking">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start">
      <mat-form-field appearance="outline">
        <mat-chip-list #emailsList aria-label="Test emails">
          <mat-chip removable
                  (removed)="removeTest(testEmail)"
                  *ngFor="let testEmail of testEmails">
            {{testEmail}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input [placeholder]="'New email...' | translate"
                 [matChipInputFor]="emailsList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addTestEmail($event)">
        </mat-chip-list>
        <mat-hint align="end" translate>Introduce the test email addresses</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div align="end" mat-dialog-actions>
    <span class="fill-spacer"></span>
    <button [disabled]="formIsWorking" mat-button (click)="onCancel()">Cancel</button>
    <button [disabled]="testEmails.length == 0 || formIsWorking" mat-raised-button color="primary" type="button" (click)="sendTestEmails()">{{"Send" | translate}}</button>
  </div>
</form>
