import { AuthService } from '../services/auth.service';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable()
export class MaybeOnlyRegisteredGuard implements CanActivate {


  constructor(private _authService: AuthService, private _router: Router,
    @Inject(PLATFORM_ID) private platformId: any,) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (environment.onlyForRegisteredUsers) {
        return this._authService.getCurrentUser().pipe(
          map((user: any) => (user && true) || (this._router.navigate(['/login'], {
            queryParams: {
              redirectTo: state.url
            }
          }) && false))
        );
      }
    } else {
      if (environment.onlyForRegisteredUsers) {
        return (this._router.navigate(['/login'], {
          queryParams: {
            redirectTo: state.url
          }
        }) && false)
      }
    }
    return true;
  }

}
