import { Component, Input, OnChanges, OnInit, SimpleChanges, EventEmitter } from '@angular/core';;
import { first } from 'rxjs/operators';
import { Ticket } from '../models/booking';
import { Event } from '../models/event';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { UtilsService } from '../services/utils.service';
import { BookingService } from '../services/booking.service';

@Component({
  selector: 'app-in-door-check-in',
  templateUrl: './in-door-check-in.component.html',
  styleUrls: ['./in-door-check-in.component.scss']
})
export class InDoorCheckInComponent implements OnInit, OnChanges {

  @Input()
  event: Event;

  authorEmail: string;
  eventId: string;
  venueName: string;
  startDateSeconds: number;
  formResetter: EventEmitter<any> = new EventEmitter();

  ticketsValid: boolean = false;
  tickets: Ticket[];
  reset: boolean = true;

  constructor(
    private authService: AuthService,
    private bookingService: BookingService,
    private utilsService: UtilsService) { }

  ngOnInit(): void {
    if (this.event) {
      this.createTicketsSelector();
      this.authService.getCurrentUserInfo().pipe(first()).subscribe(
        (user: User) => {
          this.authorEmail = user?.email || 'Unknown' ;
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.event.currentValue) {
      this.createTicketsSelector();
    } else {
      this.event = null;
    }
  }

  createTicketsSelector() {
    this.eventId = this.event.id;
    this.venueName = this.event.venueObj?.name;
    this.startDateSeconds = this.event.startDate?.seconds;
  }

  onTicketsChange({newTicketsValue, _}) {
    this.tickets = newTicketsValue;
  }

  onValidityChange(newValidity) {
    this.ticketsValid = newValidity;
  }
  
  addTickets() {
    if (this.ticketsValid) {
      const totalAmount: number = this.tickets.reduce((p, c) => (c.numberTickets * c.price) + p, 0);
      const numTickets: number = this.tickets.reduce((p, c) => c.numberTickets + p, 0);
      const booking = {
        actions: [{  
          authorEmail: this.authorEmail || 'Unknown',
          name: 'in-door-booking',
          notifySMS: false,
          status: 'success',
          timestamp: new Date()}],
        authorEmail: this.authorEmail || 'Unknown',
        bookingDate: new Date(),
        bookingNumber: this.utilsService.makeBookingId(5),
        checkinDates: [...Array(numTickets).keys()].map(() => new Date()),
        customerName: 'In-Door-Booking',
        eventId: this.event.id,
        eventTitle: this.event.title_nb,
        endDate: this.event.endDate,
        paymentMethod: 'door',
        status: 'attended',
        startDate: this.event.startDate,
        termsAndConditionsAccepted: true,
        tickets: this.tickets,
        totalAmount,
        venueName: this.event.venueObj.name
      };
      this.bookingService.addBooking(booking);
      //Reset the tickets from
      this.formResetter.emit();
      //Form is not valid anymore (is empty)
      this.ticketsValid = false;
    }
  }
}
