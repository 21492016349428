import { Component, PLATFORM_ID, OnInit, Inject, OnDestroy } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from 'ngx-localstorage';

import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { UtilsService } from '../services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  consentAccepted: boolean = true;
  warningBrowser: boolean = true;
  environment: any;
  today: number = Date.now();
  currentVersion: string = null;
  showReloadWarning: boolean = false;

  //VMFEST
  routerEvents: Subscription;
  isVMFest: boolean;

  constructor(
    private storageService: LocalStorageService,
    public sanitazer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any) {
    this.environment = environment;
    
    if (isPlatformBrowser(this.platformId)) {
      if (this.utilsService.storageAvailable()) {
        this.consentAccepted = this.storageService.get('consentAccepted', 'hvaskjerkalender') == 'true';
      }
      this.warningBrowser = /msie\s|trident/i.test(window.navigator.userAgent);
      //Listen to new app versions
      this.listenToNewAppVersions();
    }
    this.setupURLVMFestListener();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.routerEvents?.unsubscribe();
  }

  setupURLVMFestListener() {
    this.routerEvents =  this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.route;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      map(route => route.snapshot.data)
    )
    .subscribe(data => {
      this.isVMFest = data?.custom === 'vm-fest';
    });
  }

  acceptConsent() {
    if (isPlatformBrowser(this.platformId)) {
      this.consentAccepted = true;
      if (this.utilsService.storageAvailable()) {
        this.storageService.set('consentAccepted', 'true', 'hvaskjerkalender');
      }
    }
  }

  reloadPage() {
    window.location.reload();
  }

  listenToNewAppVersions() {
    if (this.environment.ui.showOnForm.rsvp) {
      this.utilsService.getApp().subscribe(
        (appDocument: any) => {
          const lastVersion = appDocument?.lastVersion;
          if (this.currentVersion != null && lastVersion != this.currentVersion) {
            this.showReloadWarning = true;
          } else {
            this.currentVersion = lastVersion;
          }
        }
      );
    }
  }
}
