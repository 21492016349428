<div class="wrapper">
    <div class="mat-body privacy-policy mat-elevation-z1 page">
        <div *ngIf="translate.currentLang == 'en'">
            <h1 class="mat-h1">Terms and conditions for RSVP</h1>
            <p>Effective date: June 8, 2022</p>
            <p>Margareto Software AS ("us", "we", or "our") operates the {{environment.siteURL}} website (the "Service").</p>
            <p>This page informs you of our policies regarding the order and management of tickets at our Service and
                the choices you have associated with that data.</p>
            <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection
                and use of information in accordance with <a target="_blank" href="/privacy-policy">the privacy policy
                    of our service</a></p>

            <h2 class="mat-h2">Information Collection And Use</h2>
            <p>We collect several different types of information for various purposes to provide and improve our Service
                to you.</p>

            <h3 class="mat-h3">Data Collected</h3>
            <p>While using our Service, we may ask you to provide us with certain personally identifiable information
                that can be used to contact or identify you ("Personal Data"). Personally identifiable information may
                include, but is not limited to:</p>
            <ul>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Personal address</li>
            </ul>

            <h2 class="mat-h2">Use of Data</h2>
            <p>Margareto Software AS uses the collected data for various purposes:</p>
            <ul>
                <li>To provide and maintain the Service</li>
                <li>To notify you about the confirmation, updates or cancellation of your tickets</li>
                <li>To provide customer care and support</li>
                <li>To send invoices if the organizer of the event requires so</li>
            </ul>

            <h2 class="mat-h2">Export and deletion of your Data</h2>
            <p>You can always require us to export or delete your stored data by contacting us at
                {{environment.contact?.email || 'post@hvaskjerkalender.no'}}</p>

            <h2 class="mat-h2">Cancellation of your tickets</h2>
            <p>Different events have different policies for the cancellation of your tickets. Every event set a period
                before its start date in which you can not cancel your tickets. The last date in which you cancel your
                tickets is the "last cancellation date".</p>
            <ul>
                <li>Different events have differen last cancellation dates. Most of the events let you cancel the
                    tickets 7 days before the event starts. It is the responsability of the person who order the tickets
                    to check this last cancellation date.</li>
                <li>The organizer has the right to ask for the due amount if you want to cancel the tickets after the
                    last cancellation date.</li>
                <li>The organizer has the right to ask for the due amount if you do not show at the event and your
                    tickets were not cancelled.</li>
            </ul>

            <h2 class="mat-h2">Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <ul>
                <li>By email: {{environment.contact?.email || 'post@hvaskjerkalender.no'}}</li>
                <li>By phone: {{environment.contact?.phone || 'N/A'}}</li>
            </ul>
        </div>

        <div *ngIf="translate.currentLang == 'nb'">
            <h1 class="mat-h1">Vilkår for RSVP</h1>
            <p>Ikrafttredelsesdato: 8. juni 2022</p>
            <p>Margareto Software AS ("oss", "vi" eller "vår") driver nettstedet til {{environment.siteURL}} ("tjenesten").
            </p>
            <p>Denne siden informerer deg om våre retningslinjer angående bestilling og administrasjon av billetter på vår tjeneste og valgene du har knyttet til disse dataene.</p>
            <p>Vi bruker dataene dine til å tilby og forbedre tjenesten. Ved å bruke tjenesten godtar du innsamlingen og bruk av informasjon i samsvar med <a target="_blank" href="/privacy-policy">personvernreglene av tjenesten vår</a></p>

            <h2 class="mat-h2">Informasjonsinnsamling og bruk</h2>
            <p>Vi samler inn flere forskjellige typer informasjon for ulike formål for å tilby og forbedre tjenesten vår til deg.</p>

            <h3 class="mat-h3">Data som er samlet inn</h3>
            <p>Når vi bruker tjenesten vår, kan vi be deg om å gi oss visse personlig identifiserbare opplysninger som kan brukes til å kontakte eller identifisere deg ("Personopplysninger"). Personlig identifiserbar informasjon kan inkluderer, men er ikke begrenset til:</p>
            <ul>
                <li>Fornavn og etternavn</li>
                <li>Telefonnummer</li>
                <li>Personlig adresse</li>
            </ul>

            <h2 class="mat-h2">Bruk av data</h2>
            <p>Margareto Software AS bruker de innsamlede dataene til ulike formål:</p>
            <ul>
                <li>For å tilby og vedlikeholde tjenesten</li>
                <li>For å varsle deg om bekreftelse, oppdateringer eller avbestilling av billettene dine</li>
                <li>For å gi kundeservice og støtte</li>
                <li>For å sende fakturaer hvis arrangøren av arrangementet krever det</li>
            </ul>

            <h2 class="mat-h2">Eksport og sletting av dataene dine</h2>
            <p>Du kan alltid kreve at vi eksporterer eller sletter de lagrede dataene dine ved å kontakte oss på {{environment.contact?.email || 'post@hvaskjerkalender.no'}}</p>

            <h2 class="mat-h2">Avbestilling av billettene dine</h2>
            <p>Retningslinene for avbestilling av billetter kan variere fra arrangement til arrangement. I forkant av arrangementer er det en periode der du ikke kan kansellere billettene dine. Den siste datoen du kan kansellere dine billetter er “siste avbestillingsdato”.</p>
            <ul>
                <li>Ulike arrangementer har forskjellige siste avbestillingsdato. De fleste av arrangementene lar deg kansellere billetter 7 dager før arrangementet starter. Det er den som bestiller billettene som har ansvaret for å sjekke siste avbestillingsdato.</li>
                <li>Arrangøren har rett til å be om forfalt beløp dersom du ønsker å kansellere billettene etter siste avbestillingsdato.</li>
                <li>Arrangøren har rett til å be om skyldig beløp dersom du ikke møter på arrangementet og din billetter ble ikke avbestilt.</li>
            </ul>

            <h2 class="mat-h2">Kontakt oss</h2>
            <p>Hvis du har spørsmål om disse vilkårene, vennligst kontakt oss:</p>
            <ul>
                <li>På e-post: {{environment.contact?.email || 'post@hvaskjerkalender.no'}}</li>
                <li>På telefon: {{environment.contact?.phone || 'N/A'}}</li>
            </ul>
        </div>
    </div>
</div>