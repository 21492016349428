import { Component, OnInit } from '@angular/core';
import { SeoService } from '../services/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-publishing-rules',
  templateUrl: './publishing-rules.component.html',
  styleUrls: ['./publishing-rules.component.scss']
})
export class PublishingRulesComponent implements OnInit {

  environment: any;

  constructor(private seoService: SeoService,
    public translate: TranslateService) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.seoService.generateTags({ title: this.translate.instant("Publishing rules") });
  }

}
