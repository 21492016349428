import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class ScrollService {

  lastScrollingForEventsList: number = 0;
  lastIDForEventsList: string = '';

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public setLastPositionForEventList(scrollingPosition: number) {
    this.lastScrollingForEventsList = scrollingPosition;
  }
  public getLastPositionForEventList() {
    return this.lastScrollingForEventsList;
  }

  public setLastIDForEventList(eventID: string) {
    this.lastIDForEventsList = eventID;
  }
  public getLastIDForEventList() {
    return this.lastIDForEventsList;
  }

}
