import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ActivityType } from '../models/activity-type';
import allActivityTypes from '../../assets/data/activityTypes.json';

@Injectable()
export class ActivityTypeService {

  constructor(private translate: TranslateService) {}

  //Returns the list of activity types ordered by the current language
  getAllActivityTypes(): ActivityType[] {
    const lanName = "name_" + this.translate.currentLang;
    const lanSlug = "slug_" + this.translate.currentLang;
    let sortedActs: ActivityType[] = Object.keys(allActivityTypes)
      .map(function(k) { return { id: k, name: allActivityTypes[k][lanName], slug: allActivityTypes[k][lanSlug], name_nb: allActivityTypes[k].name_nb, name_en: allActivityTypes[k].name_en }; })
      .sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
    return sortedActs;
  }

  getActivityType(activityTypeID: string): ActivityType {
    if (allActivityTypes[activityTypeID]) {
      const lanName = "name_" + this.translate.currentLang;
      const lanSlug = "slug_" + this.translate.currentLang;
      return { id: activityTypeID, name: allActivityTypes[activityTypeID][lanName], slug: allActivityTypes[activityTypeID][lanSlug] };
    }
    return null;
  }

  getActivityTypeBySlug(activityTypeSlug: string): ActivityType {
    for (var key in allActivityTypes) {
      var value = allActivityTypes[key];
      if (value.slug_en == activityTypeSlug || value.slug_nb == activityTypeSlug) {
        const lanName = "name_" + this.translate.currentLang;
        const lanSlug = "slug_" + this.translate.currentLang;
        return {
          id: key,
          name: value[lanName],
          slug: value[lanSlug]
        }
      }
    }
    return null;
  }

  getActivityTypeByName(activityTypeName: string): ActivityType {
    for (var key in allActivityTypes) {
      var value = allActivityTypes[key];
      if (value.name_en.toLowerCase() == activityTypeName.toLowerCase() || value.name_nb.toLowerCase() == activityTypeName.toLowerCase()) {
        const lanName = "name_" + this.translate.currentLang;
        const lanSlug = "slug_" + this.translate.currentLang;
        return {
          id: key,
          name: value[lanName],
          slug: value[lanSlug]
        }
      }
    }
    return null;
  }

  getActivityTypeName(activityTypeID: string) {
    if (this.translate.currentLang == 'en') {
      return (allActivityTypes[activityTypeID] && allActivityTypes[activityTypeID].name_en) || '';
    }
    return (allActivityTypes[activityTypeID] && allActivityTypes[activityTypeID].name_nb) || '';
  }

  getActivityTypeSlug(activityTypeID: string) {
    if (this.translate.currentLang == 'en') {
      return (allActivityTypes[activityTypeID] && allActivityTypes[activityTypeID].slug_en) || '';
    }
    return (allActivityTypes[activityTypeID] && allActivityTypes[activityTypeID].slug_nb) || '';
  }

}
