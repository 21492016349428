<h2 mat-dialog-title translate>Reauthentication</h2>
<form>
  <div mat-dialog-content>
    <mat-form-field class="full-width">
      <input matInput [placeholder]="'Password' | translate" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
    </mat-form-field>
  </div>
  <div align="end" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false"><span translate>No</span></button>
    <button mat-raised-button color="primary" [mat-dialog-close]="password" type="submit"><span translate>Send</span></button>
  </div>
</form>
