<div fxLayout="column" fxLayoutGap="40px">
    <div fxLayout="column" fxLayoutGap="20px">
        <app-customer-name-editor [name]="customer?.name || null" (onNameChange)="onCustomerNameChange($event)"
            (onValidityChange)="validComponents[0] = $event" [hint]="'Customer name' | translate"></app-customer-name-editor>
        <app-customer-address-editor [address]="customer?.address || null"
            (onCustomerAddressChange)="onAddressChange($event)" [isRequired]="false" [hint]="'Customer address' | translate"
            (onValidityChange)="validComponents[0] = $event"></app-customer-address-editor>
        <app-customer-phone-editor [phone]="customer?.phone || null" (onPhoneChange)="onCustomerPhoneChange($event)"
            (onValidityChange)="validComponents[0] = $event" [hint]="'Customer phone' | translate"></app-customer-phone-editor>
        <app-customer-comments-editor [comments]="customer?.comments || null"
            (onCustomerCommentsChange)="onCustomerCommentsChange($event)" [hint]="'Customer comments. Visible for the customer.' | translate"
            (onValidityChange)="validComponents[0] = $event"></app-customer-comments-editor>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <a mat-stroked-button [routerLink]="'/customers'">{{'Cancel' | translate }}</a>
        <button type="submit" mat-flat-button (click)="saveCustomer()" color="primary">{{'Save' | translate}}</button>
    </div>
</div>