import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MapsService {

  constructor(private http: HttpClient) { }

  key: string = environment.firebase.apiKey;

  getAddress(lat: number, lng: number) : Observable<any> {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + "," + lng + "&key=" + this.key);
  }

  getLocation(address: string) : Observable<any> {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + "&key=" + this.key);
  }
}
