import { Pipe, PipeTransform } from '@angular/core';
import { BookingStatus } from '../models/booking-status';
import allBookingStatuses from '../../assets/data/bookingStatuses.json';

@Pipe({
  name: 'bookingStatus'
})
export class BookingStatusPipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    if (value) {
      if (value) {
        const lanName = "name_" + (args[0] || 'nb');
        return allBookingStatuses[value][lanName] || '';  
      }
      return 'Error';
    }
    return null;
  }

}
