import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, interval } from 'rxjs';
import { first, debounceTime, map, switchMap, startWith } from 'rxjs/operators';
import { Booking, Ticket } from '../models/booking';
import { Event } from '../models/event';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { BookingService } from '../services/booking.service';
import { UtilsService } from '../services/utils.service';
import { TicketTypeService } from '../services/ticket-type.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DateAdapter } from '@angular/material/core';
import { BookingStatus } from '../models/booking-status';
import { BookingStatusService } from '../services/booking-status.service';

@Component({
  selector: 'app-bookings-list',
  templateUrl: './bookings-list.component.html',
  styleUrls: ['./bookings-list.component.scss']
})
export class BookingsListComponent implements OnInit, OnDestroy {

  //User
  user: User;

  //Search in bookings
  searchFieldControl: FormControl = new FormControl();
  bookingsSearchResults: Booking[] = [];
  searchingBookings: boolean = false;
  searchBookingsSubscriber: Subscription;
  showSearchInBookingsError = false;

  //Status
  filterStatus: FormControl = new FormControl();
  allStatus: BookingStatus[] = [];

  //Selection
  initialSelection = [];
  allowMultiSelect = true;
  selection: SelectionModel<Booking> = new SelectionModel<Booking>(this.allowMultiSelect, this.initialSelection);

  //Bookings
  bookings: Booking[] = [];
  unfilteredBookings: Booking[] = [];
  expandedBooking: Booking;
  bookingsSubscription: Subscription;
  fetchingBookings: boolean = true;

  //Events
  eventsSubscription: Subscription;
  expandedEvent: Event;
  fetchingEvents: boolean = true;
  events: Event[];
  unfilteredEvents: Event[];
  eventSelectorControl: FormControl = new FormControl();
  eventSelectorFilter: any = {
    fromDate: new Date(),
    untilDate: null,
    eventID: null
  };
  queryEventId: string;
  queryVenueName: string;
  queryStartDateSeconds: number;

  //Check-in mode
  checkinMode: boolean = false;


  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public translate: TranslateService,
    public utilsService: UtilsService,
    private titleService: Title,
    private router: Router,
    private _adapter: DateAdapter<any>,
    private route: ActivatedRoute,
    private ticketTypeService: TicketTypeService,
    private bookingStatusService: BookingStatusService,
    private bookingService: BookingService,
    public authService: AuthService) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      console.log("ngOnInit");
      this._adapter.setLocale(this.translate.currentLang);
      this.initComponents();
      //Subscribe to the language change event
      this.translate.onLangChange.subscribe(() => {
        //If the language change while we are on this screen
        this.initComponents();
      });
      this.initUserInfo();
      //Set the title of the page
      this.titleService.setTitle(this.translate.instant("Bookings") + ' | ' + environment.content?.siteName);
    }
  }

  ngOnDestroy(): void {
  }

  initComponents(): void {
    console.log("initComponents");
    this.initSearchInBookings();
    this.initEventsFilter();
    this.initQueryParamsListener();
    this.initStatusFiltering();
  }

  initQueryParamsListener() {
    console.log("initQueryParamsListener");
    this.route.queryParams.subscribe((qParams) => {
      console.log("queryParams", qParams);
      this.queryEventId = qParams.eventId || null;
      this.queryVenueName = qParams.venueName || null;
      this.queryStartDateSeconds = this.isInteger(qParams.startDateSeconds) ? parseInt(qParams.startDateSeconds) : null;
      if (this.queryStartDateSeconds) {
        console.log("startDateSeconds", this.queryStartDateSeconds);
        if (this.queryStartDateSeconds * 1000 < (new Date()).getTime()) {
          //Trigger the filter for the events selector to fetch any event from within one hour of startDateSeconds
          const fromDate = new Date((this.queryStartDateSeconds - (60 * 60)) * 1000);
          let untilDate = null;
          if (this.eventSelectorFilter.fromDate.getTime() < (new Date()).getTime()) {
            untilDate = new Date((this.queryStartDateSeconds + (60 * 60)) * 1000);
          }
          this.eventSelectorFilter = {
            fromDate,
            untilDate,
            eventID: null
          }
        } else {
          if (this.eventSelectorFilter.fromDate.getTime() <= (new Date()).getTime() && this.eventSelectorFilter.untilDate == null) {
            this.discoverSelectedEvent();
          }
        }

      }
    });
  }
  private isInteger(str) {
    return /^\d+$/.test(str); // Regular expression to check if the string contains only digits
  }

  private resetQueryParameters() {
    console.log("resetQueryParameters");
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {}
      });
  }

  //User init
  initUserInfo(): void {
    console.log("initUserInfo");
    this.authService.getCurrentUserInfo().pipe(first()).subscribe(
      (user: User) => {
        console.log("user");
        if (user) {
          this.user = user;
        }
      }
    );
  }

  //Init status filtering
  initStatusFiltering() {
    this.allStatus = this.bookingStatusService.getAllBookingStatuses();
    this.filterStatus.setValue(this.allStatus.map((s) => s.id));
    this.filterStatus.valueChanges.subscribe(
      (selectedStatus: string[]) => {
        this.bookings = this.unfilteredBookings.filter((b) => selectedStatus.indexOf(b.status) != -1);
      }
    )
  }

  //Search in bookings init
  initSearchInBookings(): void {
    this.searchFieldControl.valueChanges.pipe(debounceTime(500)).subscribe(
      (searchTerm: string) => {
        this.searchInBookings(searchTerm);
      }
    )
  }

  searchInBookings(searchTerm: string, silent: boolean = false) {
    if (searchTerm?.length > 0) {
      this.showSearchInBookingsError = false;
      this.searchingBookings = !silent;
      console.log(searchTerm);
      this.searchBookingsSubscriber?.unsubscribe();
      this.searchBookingsSubscriber = this.bookingService.searchInBookings(searchTerm, Math.floor(this.eventSelectorFilter.fromDate?.getTime() / 1000) || null, Math.floor(this.eventSelectorFilter.untilDate?.getTime() / 1000) || null, this.eventSelectorControl.value || null).subscribe(
        (response: any) => {
          console.log(response);
          this.searchBookingsSubscriber?.unsubscribe();
          if (response.data.error) {
            this.showSearchInBookingsError = true;
          } else {
            this.bookingsSearchResults = response.data.bookings as Booking[];
          }
          this.searchingBookings = false;
        }
      );
    }
  }

  //Events
  onEventsLoaded(events: Event[]) {
    console.log("onEventsLoaded");
    console.log(events);
    const transformedEvents: Event[] = this.includeRepetitions(events);
    this.replaceNewEvents(transformedEvents);
    this.fetchingEvents = false;
    if (this.searchFieldControl.value?.length > 0) {
      this.searchInBookings(this.searchFieldControl.value, true);
    }
    if (this.queryEventId && this.queryStartDateSeconds && this.queryVenueName) {
      this.discoverSelectedEvent();
    }
  }

  replaceNewEvents(transformedEvents) {
    console.log("replaceNewEvents", this.eventSelectorControl.value, this.events?.length);
    //Assign unfiltered events always
    this.unfilteredEvents = transformedEvents;
    //Initial assignation or list is showing search results
    this.events = transformedEvents;
  }

  discoverSelectedEvent() {
    console.log("discoverSelectedEvent");
    this.searchFieldControl.setValue(null);
    const filteredEvent = this.events?.find((e) => e.id == this.queryEventId && e.startDate?.seconds == this.queryStartDateSeconds && e.venueObj?.name == this.queryVenueName) || null;
    this.eventSelectorControl.setValue(filteredEvent);
    if (this.events?.length > 0) {
      this.resetQueryParameters();
    } 
  }

  onEventSelectorFilterChanged(newFilter: any) {
    this.eventSelectorFilter = newFilter;
  }

  initEventsFilter(): void {
    //Filter over the events range.
    this.eventSelectorControl.valueChanges.subscribe(
      (selectedEvent: Event) => {
        if (selectedEvent) {
          this.events = this.unfilteredEvents.filter((e) => this.instancesEqual(selectedEvent, e));
          if (this.events?.length == 1) {
            this.toggleBookings(this.events.find((e) => this.instancesEqual(selectedEvent, e)));
          }
        } else {
          this.events = this.unfilteredEvents;
          this.expandedEvent = null;
          this.expandedBooking = null;
        }
        if (this.searchFieldControl.value?.length > 0) {
          this.searchInBookings(this.searchFieldControl.value);
        }
      }
    )
  }

  includeRepetitions(events: Event[]) {
    return [].concat.apply([], events.map((event: Event) => {
      if (event.repetitions?.length > 0) {
        return [event].concat(event.repetitions.map((r) => ({ ...event, ...r })));
      }
      return [event];
    }));
  }

  //Selector for the rows
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    console.log("isAllSelected");
    const numSelected = this.selection.selected.length;
    const numRows = this.bookings.length;
    return numSelected == numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    console.log("masterToggle");
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.bookings.forEach(booking => this.selection.select(booking));
    }
  }
  selectBooking(booking: any) {
    console.log("selectRow");
    this.selection.toggle(booking);
  }

  //Bookings in an event
  toggleBookings(event: Event) {
    console.log("toggleBookings", this.expandedEvent, event);
    if (this.instancesEqual(this.expandedEvent, event)) {
      this.bookingsSubscription?.unsubscribe();
      this.expandedEvent = null;
      this.expandedBooking = null;
    } else {
      this.expandedEvent = event;
      this.fetchBookings();
    }
    this.selection.clear();
    this.checkinMode = false;
  }

  instancesEqual(ev1: Event, ev2: Event): boolean {
    console.log("instancesComparator");
    return ev1?.id === ev2?.id && ev1?.startDate?.seconds == ev2?.startDate?.seconds && ev1?.venueObj?.name == ev2?.venueObj?.name;
  }

  //Bookings
  fetchBookings(): void {
    console.log("fetchBookings");
    this.fetchingBookings = true;
    this.bookingsSubscription?.unsubscribe();
    this.bookingsSubscription = this.bookingService.getBookings(
      this.expandedEvent.id,
      this.expandedEvent.startDate,
      this.expandedEvent.venueObj.name).subscribe(
        (bookings: Booking[]) => {
          bookings.sort((e1, e2) => (e1.actions[0]?.timestamp?.seconds || e1.actions[0]?.timestamp?._seconds || e1.bookingDate?.seconds) <= (e2.actions[0]?.timestamp?.seconds || e2.actions[0]?.timestamp?._seconds || e2.bookingDate?.seconds) ? 1 : -1);
          this.bookings = bookings;
          this.unfilteredBookings = this.bookings;
          this.allStatus = this.bookingStatusService.getAllBookingStatuses().filter((s) => bookings.find((b) => b.status == s.id));
          this.filterStatus.setValue(this.allStatus.map((s) => s.id));
          this.fetchingBookings = false;
        }
      );
  }


  getTicketType(ticket: Ticket): string {
    return this.ticketTypeService.getTicketTypeName(ticket);
  }

  //Checkin alternatives
  async saveCheckinList(format: string) {
    console.log("saveCheckinList");
    const event = this.expandedEvent;
    if (event) {
      //Filter bookings
      let filteredBookings = this.bookings.filter(b => ['active', 'attended', 'partially_attended'].indexOf(b.status) != -1);
      //Order bookings
      filteredBookings.sort((b1, b2) => {
        const cost1 = b1.customerName?.trim().toLowerCase().split(" ");
        const cost2 = b2.customerName?.trim().toLowerCase().split(" ");
        if (cost1.length > 0 && cost2.length > 0) {
          return (cost1[cost1.length - 1] <= cost2[cost2.length - 1]) ? -1 : 1;
        }
        return 0;
      });
      if (format == 'csv') {
        this.utilsService.saveAsCSV(filteredBookings, event);
      }
      if (format == 'pdf') {
        this.utilsService.saveAsPDF(filteredBookings, event);
      }
    }
  }

  toggleCheckinMode() {
    this.checkinMode = !this.checkinMode;
    if (this.checkinMode) {
      this.bookings.sort((b1, b2) => b1.customerName.trim().toLowerCase() > b2.customerName.trim().toLowerCase() ? 1 : -1);
    }
  }

  removeCheckedIn(e, booking: Booking): void {
    console.log("removeCheckedIn");
    e.stopPropagation();
    const checkedIn: number = this.getCheckedIn(booking);
    if (checkedIn > 0) {
      const checkinDates: any[] = (booking.checkinDates || []);
      checkinDates.splice(-1);
      let status = booking.status;
      if (checkinDates.length > 0) {
        status = 'partially_attended';
      }
      if (checkinDates.length == 0) {
        status = 'active';
      }
      this.bookingService.updateBooking(booking.id, { checkinDates, status });
    }
  }

  addCheckedIn(e, booking: Booking): void {
    console.log("addCheckedIn");
    e.stopPropagation();
    const checkedIn: number = this.getCheckedIn(booking);
    const numTickets: number = this.getNumTickets(booking);
    if (checkedIn < numTickets) {
      const checkinDates: any[] = (booking.checkinDates || []);
      checkinDates.splice(-1, 0, new Date());
      let status = booking.status;
      if (checkinDates.length > 0) {
        status = 'partially_attended';
      }
      if (checkinDates.length == numTickets) {
        status = 'attended';
      }
      this.bookingService.updateBooking(booking.id, { checkinDates, status });
    }
  }

  getNumTickets(booking: Booking): number {
    return booking.tickets.reduce((c, p) => (c + p.numberTickets), 0);
  }

  getCheckedIn(booking: Booking): number {
    console.log("getCheckedIn");
    return booking.checkinDates?.length || 0;
  }


  //Booking details
  getBookingAddress(booking) {
    console.log("getBookingAddress");
    if (booking.customerAddress?.street && booking.customerAddress?.number && booking.customerAddress?.postal && booking.customerAddress?.municipality) {
      return booking.customerAddress?.street + " " + booking.customerAddress?.number + ", " + booking.customerAddress?.postal + " " + booking.customerAddress?.municipality;
    }
    return booking.customerAddress?.formattedAddress;
  }

  toggleBookingDetails(booking) {
    console.log("toggleBookingDetails");
    if (this.expandedBooking?.id == booking.id) {
      this.expandedBooking = null;
    } else {
      this.expandedBooking = booking;
    }
  }

  trim(myStr?: string, numChats?: number): string {
    return this.utilsService.trimWithMax(myStr || '', numChats || 40);
  }

}
