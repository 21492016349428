import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AddTicketTypeComponent } from '../add-ticket-type/add-ticket-type.component';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SelectableEvent } from '../models/selectable-event';
import { UtilsService } from '../services/utils.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
  selector: 'app-change-start-date',
  templateUrl: './change-start-date.component.html',
  styleUrls: ['./change-start-date.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ChangeStartDateComponent implements OnInit {

  regexpHour = /^\d{1,2}:\d{2}$/i;

  filteredStartHours: Observable<any[]>;
  minDate = new Date();
  hours: SelectableEvent[];
  filteredEndHours: Observable<any[]>;
  hourToHighlight: string = '';

  startDateForm = new FormGroup({
    startDate: new FormControl('', []),
    startTime: new FormControl('12:00', [Validators.pattern(this.regexpHour)]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilsService: UtilsService,
    private translate: TranslateService,
    private adapter: DateAdapter<any>,
    public dialogRef: MatDialogRef<AddTicketTypeComponent>,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if (this.data) {
      this.setAdapterLocale(this.translate.currentLang);
      this.startDateForm.patchValue(this.data);
      this.minDate = this.data.minDate;
      this.hours = this.utilsService.getAllHours();
      this.filteredStartHours = this.startDateForm.controls.startTime.valueChanges
        .pipe(
          startWith(''),
          map(userInput => this.filterHours(userInput))
        );
    }
  }

  setAdapterLocale(language: string) {
    if (language != 'nb') {
      this.adapter.setLocale('en-GB');
    } else {
      this.adapter.setLocale(language);
    }
  }

  filterHours(userInput: string) {
    this.hourToHighlight = userInput;
    return this.hours.filter(hour => hour.value.indexOf(userInput) === 0);
  }

  //Prevent closing the dialog when the user press Enter focused on the "name" field
  preventClosing(e) {
    e.preventDefault();
  }

  onCancel() {
    this.dialogRef.close();
  }

  changeStartDate() {
    if (this.startDateForm.valid) {
      const startDate = this.utilsService.buildStartDateWithTime(this.startDateForm.value.startDate, this.startDateForm.value.startTime).toDate();
      this.dialogRef.close({ startDate, startTime: this.startDateForm.value.startTime });
      this.snackBar.open(this.translate.instant("Start date changed successfully"), null, {
        duration: 4000,
      });
    }
  }

}
