<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" [formGroup]="manualRepetitionForm">
  <div fxFlex="90px">
    <input class="single-date" [placeholder]="placeholderSingle" (click)="pickerSingle.open()" matInput [matDatepicker]="pickerSingle" [min]="minDate" formControlName="startDate">
    <mat-datepicker #pickerSingle></mat-datepicker>
  </div>
  <select fxFlex="90px" fxFlex.xs="80px" formControlName="startTime">
    <option *ngFor="let hour of hours" [value]="hour.value">
      {{hour.viewValue}}
    </option>
  </select>
  <button color="warn" mat-icon-button (click)="removeManualRepetition()">
    <mat-icon aria-label="Remove this repetition">remove</mat-icon>
  </button>
  <button mat-icon-button (click)="addManualRepetition()">
    <mat-icon aria-label="Add another repetition">add</mat-icon>
  </button>
</div>
<div class="error-field" *ngIf="manualRepetitionForm.touched && manualRepetitionForm.hasError('required',['startDate'])" translate>
  You must select a date
</div>
<div class="error-field" *ngIf="manualRepetitionForm.touched && manualRepetitionForm.hasError('noParentDate')" translate>
  Date and time must be different from the parent event
</div>
<div class="error-field" *ngIf="manualRepetitionForm.touched && manualRepetitionForm.hasError('afterParentDate')" translate>
  Date and time must be later than the parent event
</div>
