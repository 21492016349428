import { Component, OnInit, Input } from '@angular/core';

import { Team } from '../models/team';
import { QuestionWarningComponent } from '../question-warning/question-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TeamService } from '../services/team.service';
import { AuthService } from '../services/auth.service';
import { AddTeamComponent } from '../add-team/add-team.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-teams-list-item',
  templateUrl: './my-teams-list-item.component.html',
  styleUrls: ['./my-teams-list-item.component.scss']
})
export class MyTeamsListItemComponent implements OnInit {

  @Input()
  team: Team;

  environment: any;

  constructor(
    private translate: TranslateService,
    public authService: AuthService,
    private teamService: TeamService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.environment = environment;
  }

  editTeam(): void {
    this.dialog.open(
      AddTeamComponent, {
        width: '500px',
        data: {
          team: this.team
        }
      });
  }

  deleteTeam(teamID: string): void {
    let dialogRef = this.dialog.open(
      QuestionWarningComponent, {
        width: '400px',
        data: {
          title: this.translate.instant("Delete team"),
          question: this.translate.instant("Are you sure that you want to remove this team?")
        }
      });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.teamService.deleteTeam(teamID)
          .then(
            () => this.snackBar.open(this.translate.instant("Your team was successfully deleted."), null, {
              duration: 4000,
            })
          )
          .catch(
            () => this.snackBar.open(this.translate.instant("There was a problem deleting your team. Please contact us."), null, {
              duration: 4000,
            })
          );
      }
    });
  }
}
