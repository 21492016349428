<div class="wide-wrapper">
    <div class="page mat-elevation-z1" fxLayout="column" fxLayoutGap="30px">
        <div fxLayout="row" fxLayoutAlign="space-between center" >
            <h1 class="mat-h1" translate>Customers</h1>
            <a mat-raised-button color="primary" routerLink="/customers/new"><img class="icon-button"
                    src="assets/icons/add_circle.svg" /> <span>{{'Add customer' | translate}}</span></a>

        </div>
        <div *ngIf="loadingFromUserInfo" class="loading">
            <mat-spinner></mat-spinner>
        </div>
        
        <div *ngIf="!loadingFromUserInfo" fxLayout="column" fxLayoutGap="20px">
            <mat-form-field appearance="outline">
                <mat-label>{{'Search' | translate}}</mat-label>
                <input matInput [formControl]="searchField"
                    [placeholder]="'Telephone, name or address' | translate" #filter>
                <mat-hint *ngIf="!filter.value">{{'Telephone, name or address' |
                    translate}}
                </mat-hint>
                <mat-hint align="end" *ngIf="filter.value?.length > 0"><a class="blue"
                        (click)="searchField.reset()">{{'Clear' | translate}}</a>
                </mat-hint>
            </mat-form-field>

            <table mat-table [dataSource]="dataSource" matSort class="full-width rwd-table">


                <!-- Phone column -->
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Telephone' | translate}} </th>
                    <td mat-cell  *matCellDef="let customer">
                        {{customer.phone | phone}}
                    </td>
                </ng-container>

                <!-- Name column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Name' | translate}} </th>
                    <td mat-cell  *matCellDef="let customer">
                        {{customer.name}}
                    </td>
                </ng-container>

                <!-- Phone column -->
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> {{'Address' | translate}} </th>
                    <td mat-cell *matCellDef="let customer">
                        {{customer.address?.formattedAddress}}
                    </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> {{'Actions' | translate}} </th>

                    <td mat-cell *matCellDef="let customer">
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                            <a mat-button [routerLink]="'edit/' + customer.id ">{{'Edit' | translate}}</a>
                            <button mat-button color="warn" (click)="deleteCustomer(customer.id)">{{'Remove' | translate}}</button>
                        </div>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">{{'No customers were found with this criteria' | translate}}
                    </td>
                </tr>
            </table>

            <mat-paginator [pageSize]="50" [pageSizeOptions]="[50, 100, 400]" showFirstLastButtons>
            </mat-paginator>


        </div>
    </div>
</div>