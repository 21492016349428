<h2 mat-dialog-title>Add an organizer</h2>
<form [formGroup]="addOrganizerForm" (keydown.enter)="preventClosing($event)">
  <div mat-dialog-content>
    <div id="adding-organizer" *ngIf="formIsWorking">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
    <mat-form-field appearance="outline">
      <mat-label translate>Organizer name</mat-label>
      <input trim="blur" required matInput #name [maxlength]="maxCharsOrganizer.toString()" formControlName="name">
      <mat-hint align="end">{{name.value.length}} / {{maxCharsOrganizer}}</mat-hint>
      <mat-error *ngIf="addOrganizerForm.hasError('required',['name'])" translate>
        The name is required
      </mat-error>
      <mat-error *ngIf="addOrganizerForm.hasError('maxlength',['name'])">
        {{'Maximum length is' | translate}} {{maxCharsOrganizer}} {{'chars' | translate}}
      </mat-error>
      <mat-error *ngIf="addOrganizerForm.hasError('validOrganizerName',['name'])" translate>
        There is already an organizer with that name
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label translate>Email address</mat-label>
      <input trim="blur" type="email" matInput formControlName="email">
      <mat-hint align="end" translate>An email address that can be public to reach you</mat-hint>
      <mat-error *ngIf="addOrganizerForm.hasError('required',['email'])" translate>
        The email is required
      </mat-error>
      <mat-error *ngIf="addOrganizerForm.hasError('email',['email'])" translate>
        This is not a valid email address
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label translate>Telephone number</mat-label>
      <input trim="blur" type="tel" matInput formControlName="telephoneNumber">
      <mat-hint align="end" translate>An telephone number that can be public to reach you</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label translate>Website</mat-label>
      <input trim="blur" type="url" matInput formControlName="website">
      <mat-hint align="end" translate>Organizer's website</mat-hint>
      <mat-error *ngIf="addOrganizerForm.hasError('pattern',['website'])" translate>
        Organizer URL must be a valid URL (i.e. https://example.com)
      </mat-error>
    </mat-form-field>
  </div>
  <div align="end" mat-dialog-actions>
    <span class="fill-spacer"></span>
    <button [disabled]="formIsWorking" mat-button (click)="onCancel()">Cancel</button>
    <button [disabled]="!addOrganizerForm.valid || formIsWorking" mat-raised-button color="primary" type="submit" (click)="addOrganizer()"><span translate>Add Organizer</span></button>
  </div>
</form>
