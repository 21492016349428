import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ScriptService } from 'ngx-script-loader';
import { Observable, Subscription } from 'rxjs';
import { Venue } from '../models/venue';

declare const Mazemap: any;

@Component({
  selector: 'app-mazemapeditor',
  templateUrl: './mazemapeditor.component.html',
  styleUrls: ['./mazemapeditor.component.scss']
})
export class MazemapeditorComponent implements OnInit, OnDestroy {

  @Output()
  onLocationEvaluated = new EventEmitter<boolean>();

  @Output()
  onPoiChange = new EventEmitter<string>();

  @Input()
  onVenueChange: Observable<any>;

  @Input()
  originalVenue: Venue;

  @Input()
  originalPoiID: string;



  private subs: Subscription;
  private currentCenter: any;

  //Map variables
  myMap: any;
  mazeMarker: any;
  hasMarker: boolean;
  mySearchInput: any;
  mySearch: any;

  constructor(
    private scriptService: ScriptService) { }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  //INIT
  ngOnInit() {
    //We are creating a new event and now POI has been selected yet. Listen to the venue selection event
    this.subs = this.onVenueChange.subscribe((venueObj: any) => this.loadMapWithVenue(venueObj));

    //We are opening an existing event with a venue
    if (this.originalVenue) {
      this.loadMapWithVenue(this.originalVenue);
    }
  }

  loadMapWithVenue(venueObj: any) {
    let venueLocation;
    if (venueObj?.location) {
      venueLocation = { lat: venueObj.location.latitude, lng: venueObj.location.longitude };
    }
    //Load the mazemap script
    this.scriptService.loadScript('https://api.mazemap.com/js/v2.0.19/mazemap.min.js').subscribe(() => this.initializeMap(venueObj?.id, venueLocation));
  }

  //Check if the provided venue is within a 50m range of any mazemap POI
  async venueLocationHasMazemap(venueLocation: any) {
    if (venueLocation) {
      const mySearch = new Mazemap.Search.SearchController({
        lng: venueLocation.lng,
        lat: venueLocation.lat,
        filterbybbox: 1,
        searchdiameter: 0.1
      });
      const searchResponse = await mySearch.search("1");
      const venueHasMazemap = searchResponse.results.length > 0;
      //Send a message to the parent container to show the map
      this.onLocationEvaluated.emit(venueHasMazemap);
      return venueHasMazemap;
    }
    return false;
  }

  //Use the data API to get a POI
  async getPoi(poiID: string) {
    let poi = null;
    if (poiID) {
      poi = await Mazemap.Data.getPoi(poiID);
    }
    return poi || null;
  }

  //Check if we should show the POI on the map
  showPoi(newVenueID: string) {
    return newVenueID && this.originalPoiID && this.originalVenue?.id == newVenueID;
  }

  //Initialize the map with all the properties and the search controller
  async initializeMap(venueID: string, venueLocation?: any) {
    const venueHasMazemap = await this.venueLocationHasMazemap(venueLocation);
    const showPoi = this.showPoi(venueID);
    let poi, poiLocation;
    if (showPoi) {
      poi = await this.getPoi(this.originalPoiID);
      poiLocation = poi && Mazemap.Util.getPoiLngLat(poi) || null;  
      this.currentCenter = poiLocation;
    } else {
      this.clearPoiMarker();
      this.currentCenter = venueLocation;
    }
    if (venueHasMazemap) {
      //If map exists, just move the center
      if (this.myMap) {
        this.myMap.flyTo({
          // Initial zoom of map
          zoom: 16,
          // Initial position of map
          center: this.currentCenter,
          speed: 4
        });
        //Add marker if it exists
        if (showPoi && poi) {
          this.placePoiMarker(poi);
        }
      } else {
        //Initializing map
        this.myMap = new Mazemap.Map({
          // The DOM element ID for the map
          container: 'mazemap-container',
          // Initial zoom of map
          zoom: 16,
          // Initial position of map
          center: this.currentCenter,
          // Set RTL Text plugin not to be loaded. Otherwise it generates an error when a new map instance is generated again
          autoSetRTLTextPlugin: false
        });
      }

      //Initialize the search controller. Searching within a range of 1km of the current center (venue or POI provided)
      this.mySearch = new Mazemap.Search.SearchController({
        rows: 10,
        lng: this.currentCenter.lng,
        lat: this.currentCenter.lat,
        withpois: true,
        withbuilding: false,
        withtype: false,
        withcampus: false,
        filterbybbox: 1,
        searchdiameter: 1,
        resultsFormat: 'geojson'
      });

      //Initialize the default search
      this.mySearchInput = new Mazemap.Search.SearchInput({
        container: document.getElementById('search-input-container'),
        input: document.getElementById('searchInput'),
        suggestions: document.getElementById('suggestions'),
        searchController: this.mySearch
      })
      this.mySearchInput.on('itemclick', (e) => {
        this.placePoiMarker(e.item);
      });
      this.mySearchInput.clearSearch();

      this.myMap.on('load', () => {

        //Resize the map to fullfill the div container
        this.myMap.resize();
        // Initialize a Highlighter for POIs
        // Storing the object on the map just makes it easy to access for other things
        this.myMap.highlighter = new Mazemap.Highlighter(this.myMap, {
          showOutline: true,
          showFill: true,
          outlineColor: Mazemap.Util.Colors.MazeColors.MazeBlue,
          fillColor: Mazemap.Util.Colors.MazeColors.MazeBlue
        });

        //Add marker if it exists
        if (showPoi && poi) {
          this.placePoiMarker(poi);
        }

        // Set up a map listener for click events
        this.myMap.on('click', (e) => this.onMapClick(e));

        //Hide the suggestions once the user clicks on an item
        this.myMap.getCanvas().addEventListener('focus', () => {
          this.mySearchInput?.hideSuggestions();
        });
        
      });

    }
  }
  
  //Hanlder when the user clicks on a room
  onMapClick(e) {
    var lngLat = e.lngLat;
    var zLevel = this.myMap.zLevel;

    // Fetching via Data API
    Mazemap.Data.getPoiAt(lngLat, zLevel).then((poi) => {
      // Place a marker on the map, or highlight the room
      this.placePoiMarker(poi);
    }).catch(function () { return false; });

  }

  //Clear any marker and highlight from the map
  clearPoiMarker() {
    this.mazeMarker?.remove();
    this.hasMarker = false;
    this.myMap?.highlighter.clear();
    this.onPoiChange.emit('');
  };

  //Place a marker on the map and highliths it if it is a polygon
  async placePoiMarker(poi) {
    //Clear previous markers and highlight
    this.clearPoiMarker();
    
    //If the POI is a point, lets find out the polygon
    if (poi.geometry?.type === "Point") {
      poi = await this.getPoi(poi.properties.poiId);
    }
    // Get a center point for the POI, because the data can return a polygon instead of just a point sometimes
    var lngLat = Mazemap.Util.getPoiLngLat(poi);
    var zLevel = poi.properties.zLevel;

    this.mazeMarker = new Mazemap.MazeMarker({
      color: '#bcd025',
      innerCircle: true,
      innerCircleColor: '#FFF',
      size: 34,
      innerCircleScale: 0.5,
      zLevel: zLevel
    })
      .setLngLat(lngLat)
      .setZLevel(zLevel)
      .addTo(this.myMap);
    this.hasMarker = true;

    this.myMap.zLevel = zLevel;

    // If we have a polygon, use the default 'highlight' function to draw a marked outline around the POI.
    if (poi.geometry.type === "Polygon") {
      this.myMap.highlighter.highlight(poi);
    }

    //Move the map to the new marker
    this.myMap.flyTo({ center: lngLat, zoom: 19, speed: 0.5 });

    //Output from the component to the container component to be saved as an event property: mazeMapPoi
    this.onPoiChange.emit(poi?.properties?.poiId || '');
  }

}
