import { Component, OnInit, Inject } from '@angular/core';

import { FormGroup, FormControl, AsyncValidatorFn, ValidationErrors, AbstractControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { OrganizerService } from '../services/organizer.service';
import { UtilsService } from '../services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-add-organizer',
  templateUrl: './add-organizer.component.html',
  styleUrls: ['./add-organizer.component.scss']
})
export class AddOrganizerComponent implements OnInit {
  regexpURL = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;


  maxCharsOrganizer: number = environment.ui.showOnForm.maxCharsOrganizer;

  addOrganizerForm = new FormGroup({
    name: new FormControl('', {
      validators: Validators.required,
      asyncValidators: this.validOrganizerName(this.organizerService, this.utilsService),
      updateOn: 'change'
    }),
    email: new FormControl('', [ Validators.email ]),
    website: new FormControl('', [ Validators.pattern(this.regexpURL) ]),
    telephoneNumber: new FormControl('', [ ]),
  });

  formIsWorking: boolean = false;

  currentUserInfo: User;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<AddOrganizerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizerService: OrganizerService,
    private authService: AuthService,
    private utilsService: UtilsService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.addOrganizerForm.controls["name"].setValue(this.data.organizerTitle);
    this.addOrganizerForm.controls["name"].markAsTouched();
    this.addOrganizerForm.updateValueAndValidity();
    //Setup user info
    this.initializeUserInfo();
  }

  initializeUserInfo(): void {
    let userInfoSubs = this.authService.getCurrentUserInfo().subscribe(
      (userInfo: User) => {
        this.currentUserInfo = userInfo;
        userInfoSubs.unsubscribe();
      }
    )
  }


  validOrganizerName(organizerService: OrganizerService, utilsService: UtilsService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> =>
      organizerService.getOrganizerBySlug(utilsService.slugify(control.value, "-")).pipe(
        map(venue => venue ? { "validOrganizerName": true } : null),
        first()
      );
  }

  onCancel() {
    this.dialogRef.close();
  }

  organizerFromForm() {
    let organizer = this.addOrganizerForm.value;
    organizer.slug = this.utilsService.slugify(organizer.name, "-");
    organizer.author_id = this.currentUserInfo.uid;
    return organizer;
  }

  //Prevent closing the dialog when the user press Enter focused on the "name" field
  preventClosing(e) {
    e.preventDefault();
  }

  addOrganizer() {
    this.formIsWorking = true;
    if (this.addOrganizerForm.valid) {
      this.organizerService.addOrganizer(this.organizerFromForm())
      .then(docRef => {
        this.formIsWorking = false;
        this.dialogRef.close(docRef.id);
        this.snackBar.open(this.translate.instant("Organizer added successfully"), null, {
          duration: 4000,
        });
      })
      .catch(error => {
        this.formIsWorking = false;
        console.error(error);
        this.snackBar.open(this.translate.instant("There was an error while creating your organizer"), null, {
          duration: 4000,
        });
      });
    }
  }

}
