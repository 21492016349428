import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'firebase/auth';
import { Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Ticket } from '../models/booking';
import { Customer } from '../models/customer';
import { Event } from '../models/event';
import { AuthService } from '../services/auth.service';
import { BookingService } from '../services/booking.service';
import { CustomerService } from '../services/customer.service';
import { EventService } from '../services/event.service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-admin-booking',
  templateUrl: './admin-booking.component.html',
  styleUrls: ['./admin-booking.component.scss']
})
export class AdminBookingComponent implements OnInit, OnDestroy {

  authorEmail: string = 'Unknown';
  currentCustomer: Customer;

  loading: boolean = true;
  bookingsArrayForm: FormArray;
  customerForm: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    address: new FormControl(),
    id: new FormControl(),
    phone: new FormControl(null, [Validators.required])
  });
  sub: Subscription;
  toucher: Subject<void> = new Subject<void>();
  errorRepeatedEvent: boolean = false;
  selectedEvent: Event;
  disabledConfirm: boolean = false;

  @ViewChild("form")
  formContainer: ElementRef;

  constructor(private bookingService: BookingService,
    private customerService: CustomerService,
    private eventService: EventService,
    private utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService.getCurrentUserInfo().pipe(first()).subscribe(
      (user: User) => {
        if (user) {
          this.authorEmail = user.email;
          this.bookingsArrayForm = new FormArray([this.newBookingForm()]);
          this.maybePrefillEvent();
          this.sub = this.customerForm.valueChanges.subscribe((newCustomer) => this.onCustomerChange(newCustomer));
          this.loading = false;
        }
      });

  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  maybePrefillEvent() {
    this.route.queryParams.subscribe((qParams) => {
      if (qParams) {
        const eventId = qParams.eventId;
        const startDateSeconds = qParams.startDateSeconds;
        const venueName = qParams.venueName;
        if (eventId && startDateSeconds && venueName) {
          this.eventService.getEventInstance(eventId, startDateSeconds, venueName).pipe(first()).subscribe(
            (event: Event) => {
              this.selectedEvent = event;
            }
          );
        }
      }
    });
  }

  newBookingForm(comments?: string, tickets?: Ticket[]): FormGroup {
    return new FormGroup({
      actions: new FormArray([new FormGroup({
        authorEmail: new FormControl(this.authorEmail || null),
        name: new FormControl('admin-new-booking'),
        notifySMS: new FormControl(true),
        status: new FormControl('executing'),
        timestamp: new FormControl(new Date()),
        parameters: new FormArray([new FormControl()])
      })]),
      bookingNumber: new FormControl(this.utilsService.makeBookingId(5)),
      comments: new FormControl(comments || null),
      customerAddress: new FormControl(this.currentCustomer?.address || null),
      customerId: new FormControl(this.currentCustomer?.id || null),
      customerName: new FormControl(this.currentCustomer?.name || null, [Validators.required]),
      customerPhone: new FormControl(this.currentCustomer?.phone || null, [Validators.required]),
      eventId: new FormControl(null, [Validators.required]),
      eventTitle: new FormControl(null, [Validators.required]),
      endDate: new FormControl(),
      //'invoice' or 'door'
      paymentMethod: new FormControl(),
      status: new FormControl(null, [Validators.required]),
      startDate: new FormControl(null, [Validators.required]),
      termsAndConditionsAccepted: new FormControl(true),
      tickets: new FormArray([]),
      venueName: new FormControl(null, [Validators.required])
    });
  }

  onCustomerChange(customer: Customer) {
    this.currentCustomer = customer;
    for (let bookingControl of this.bookingsArrayForm.controls) {
      bookingControl.get('customerAddress').setValue(customer.address);
      bookingControl.get('customerName').setValue(customer.name);
      bookingControl.get('customerPhone').setValue(customer.phone);
      bookingControl.get('customerId').setValue(customer.id);
    }
  }

  onDelete(index: number) {
    this.bookingsArrayForm.removeAt(index);
  }

  addBooking() {
    //Get the comment from the last item added in the array
    let comments: string = null;
    if (this.bookingsArrayForm.length > 0) {
      comments = this.bookingsArrayForm.get([this.bookingsArrayForm.length - 1, "comments"]).value;
    }
    this.bookingsArrayForm.push(this.newBookingForm(comments));
  }

  isThereRepeatedEvent() {
    const eventIds: Set<string> = new Set<string>(this.bookingsArrayForm?.value?.map(b => (b.eventId || '') + (b.startDate?.seconds || '') + (b.venueName || '')) || []);
    return eventIds.size != (this.bookingsArrayForm?.value?.length || 0);
  }

  saveCustomer() {
    if (this.currentCustomer?.id) {
      this.customerService.updateCustomer(this.currentCustomer?.id, this.currentCustomer);
    } else {
      this.customerService.addCostumer(this.currentCustomer);
    }
  }

  confirmBookings() {
    this.disabledConfirm = true;
    //Touch all the fields to show the relevant validation errors
    this.toucher.next();
    //Check if the there is two bookings for the same event in the array
    this.errorRepeatedEvent = this.isThereRepeatedEvent();
    if (this.errorRepeatedEvent) {
      let nativeElement = this.formContainer.nativeElement.querySelector('.error-repeated-booking');
      if (nativeElement) {
        nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      this.disabledConfirm = false;
    } else {
      if (this.bookingsArrayForm.controls.every(c => c.valid) && this.customerForm.valid) {
        //Save the customer information and the bookings
        this.saveCustomer();
        for (let booking of (this.bookingsArrayForm.value || [])) {
          this.bookingService.addBooking(booking);
        }
        this.router.navigate(['/bookings']);
      } else {
        let nativeElement = this.formContainer.nativeElement.querySelector('.ng-invalid');
        if (nativeElement) {
          nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        this.disabledConfirm = false;
      }
    }

  }
}
