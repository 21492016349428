import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-what-is',
  templateUrl: './what-is.component.html',
  styleUrls: ['./what-is.component.scss']
})
export class WhatIsComponent implements OnInit {

  environment: any;

  constructor(public translate: TranslateService) {
    this.environment = environment;
  }

  ngOnInit() {
  }

}
