<h2 mat-dialog-title>{{'Embed event' | translate}}</h2>
<div mat-dialog-content>
    <p class="mat-body">{{'Copy and paste the following code on your website' | translate}}:</p>
    <div class="code">{{code}}</div>
    <div class="visor">
        <iframe style="height: 400px;width: 100%;" frameborder="0" [src]="iframeURLSafe"></iframe>
    </div>
</div>
<div align="end" mat-dialog-actions>
    <button mat-button (click)="onClose()">{{'Close' | translate}}</button>
</div>