import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Booking, Ticket } from '../models/booking';
import { Event } from '../models/event';
import { Price } from '../models/price';
import { BookingService } from '../services/booking.service';

@Component({
  selector: 'app-admin-booking-row',
  templateUrl: './admin-booking-row.component.html',
  styleUrls: ['./admin-booking-row.component.scss']
})
export class AdminBookingRowComponent implements OnInit, OnChanges {

  @Input()
  bookingForm: FormGroup;

  @Input()
  toucher?: Observable<any>;

  @Input()
  index: number;

  @Input()
  selectedEvent: Event;
  
  @Input()
  tickets: Ticket[];

  @Output()
  onDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  //Event
  eventFormControl: FormControl = new FormControl(null, [Validators.required]);
  selectedInstance: Event;
  availableTickets: number;
  numActive: number;
  numWaitingList: number;
  sub: Subscription;
  ticketsInfo: string;

  //Status
  currentStatus: string;

  //Comments
  comments: string;

  //Bookings
  currentBookings: Booking[];
  sub2: Subscription;

  //Touchers
  sub1: Subscription;

  //Errors
  errors = {
    error_0: false,
    error_1: false,
    error_2: true,
    error_3: false
  };

  constructor(private bookingService: BookingService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.comments = this.bookingForm.get('comments').value;
    this.eventFormControl.valueChanges.subscribe(
      (selectedInstance: Event) => {
        this.selectedInstance = selectedInstance || null;
        this.bookingForm.get('eventId').setValue(selectedInstance?.id || null);
        this.bookingForm.get('paymentMethod').setValue(selectedInstance?.paymentMethod || null);
        this.bookingForm.get('eventTitle').setValue(selectedInstance?.title_nb || null);
        this.bookingForm.get('endDate').setValue(selectedInstance?.endDate || null);
        this.bookingForm.get('startDate').setValue(selectedInstance?.startDate || null);
        this.bookingForm.get('venueName').setValue(selectedInstance?.venueObj?.name || null);
        this.bookingForm.setControl('tickets', this.getTicketsControls(selectedInstance?.prices));
        this.availableTickets = selectedInstance?.availableTickets || 0;
        if (selectedInstance?.id && selectedInstance?.startDate?.seconds && this.selectedInstance?.venueObj?.name) {
          this.sub?.unsubscribe();
          this.sub = this.bookingService.getBookings(selectedInstance?.id, selectedInstance?.startDate, this.selectedInstance?.venueObj?.name, ['active', 'waiting_list']).subscribe(
            (bookings: Booking[]) => {
              this.currentBookings = bookings;
              this.numActive = bookings?.filter((b) => b.status == 'active')?.reduce((pV, cV) => cV.tickets.reduce((ppV, ccV) => ccV.numberTickets + ppV, 0) + pV, 0);
              this.numWaitingList = bookings?.filter((b) => b.status == 'waiting_list')?.reduce((pV, cV) => cV.tickets.reduce((ppV, ccV) => ccV.numberTickets + ppV, 0) + pV, 0);
              if (this.selectedInstance?.eventSoldOut) {
                this.ticketsInfo = this.translate.instant('Sold out') + ": " + this.numWaitingList + " " + this.translate.instant('in waiting list');
              } else {
                this.ticketsInfo = "(" + this.numActive + "/" + this.availableTickets + ")";
              }
              this.currentStatus = (((this.bookingForm?.value?.tickets?.reduce((ppV, ccV) => ccV.numberTickets + ppV, 0) || 0) + this.numActive) > this.availableTickets) ? 'waiting_list' : 'active';
              this.bookingForm.get('status').setValue(this.currentStatus);
              const smsContent = environment.rsvpOptions.defaultSMS['status-' + this.currentStatus]?.trim() || '' ;
              this.bookingForm.get(['actions', 0, 'parameters', 0]).setValue(smsContent);
              this.checkBookingExists();
            }
          );
          this.sub2?.unsubscribe();
          this.sub2 = this.bookingForm.valueChanges.subscribe(() => this.checkBookingExists());
        }
      }
    );
    if (this.selectedEvent) {
      this.eventFormControl.setValue(this.selectedEvent);
    }
  }

  checkBookingExists() {
    const customerPhone = this.bookingForm?.value?.customerPhone;
    if (customerPhone) {
      const alreadyBookedError = this.currentBookings?.filter((b) => b.status == 'active' && b.customerPhone == customerPhone).length > 0;
      const errors = this.eventFormControl.errors || {};
      if (errors['already_booked'] && !alreadyBookedError) {
        delete errors['already_booked'];
        this.validityOnComponent(true, 0);
      }
      if (!errors['already_booked'] && alreadyBookedError) {
        errors['already_booked'] = true;
        this.validityOnComponent(false, 0);
      }
      if (Object.keys(errors).length == 0) {
        this.eventFormControl.setErrors(null);
      } else {
        this.eventFormControl.setErrors(errors);
      }
    }
  }

  getTicketsControls(prices: Price[]): AbstractControl {
    if (prices?.length > 0) {
      return new FormArray(prices.map(p => new FormGroup({
        numberTickets: new FormControl(),
        price: new FormControl(),
        type: new FormControl(),
        name_nb: new FormControl(),
        name_en: new FormControl()
      })));
    }
    return new FormArray([]);
  }

  //Ticket selection has changed
  onTicketsChange({ newTicketsValue, newStatus }) {
    this.currentStatus = newStatus;
    console.log("onTicketsChange", newTicketsValue);
    this.bookingForm.get('status').setValue(newStatus);
    this.bookingForm.get('tickets').setValue(newTicketsValue);
  }

  //Customer comments have changed
  onCustomerCommentsChange(newComments: string) {
    this.bookingForm.get('comments').setValue(newComments);
  }

  onStatusChange(newStatus: string) {
    this.currentStatus = newStatus;
    this.bookingForm.get('status').setValue(newStatus);
    const smsContent = environment.rsvpOptions.defaultSMS['status-' + newStatus]?.trim() || '' ;
    this.bookingForm.get(['actions', 0, 'parameters', 0]).setValue(smsContent);
  }

  notifySMS(change: MatSlideToggleChange) {
    if (this.bookingForm?.value?.actions?.length > 0) {
      this.bookingForm.get(['actions', 0, 'notifySMS']).setValue(change.checked);
    }
  }

  validityOnComponent(isValid: boolean, index: number) {
    const errorName = 'error_' + index;
    this.errors[errorName] = !isValid;
    const formErrors = {};
    Object.keys(this.errors).forEach(e => { if (this.errors[e]) formErrors[e] = true; });
    if (Object.keys(formErrors).length == 0) {
      this.bookingForm.setErrors(null);
    } else {
      this.bookingForm.setErrors(formErrors);
    }
  }

  deleteBooking() {
    this.onDelete.emit(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedEvent?.currentValue) {
      this.eventFormControl.setValue(changes.selectedEvent.currentValue);
    }
  }
}
