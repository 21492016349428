<div fxLayoutWrap fxLayout="column" fxFill fxLayoutAlign="space-around center">
  <div fxFlex="70px" class="button-container">
    <button (click)="signInGoogle()" mat-raised-button class="full-width button-google" ><span translate>Sign in with Google</span></button>
  </div>
  <div fxFlex="70px" *ngIf="true" class="button-container">
    <button (click)="signInFacebook()" mat-raised-button class="full-width button-facebook" ><span translate>Sign in with Facebook</span></button>
  </div>
  <div class="mat-body-strong">
    <mat-error class="credentials-error" *ngIf="accountExistsWithDifferentCredential" translate>
      An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.
    </mat-error>
    <mat-error class="credentials-error" *ngIf="otherError" translate>
      There was an error while signing you in. Please contact us.
    </mat-error>
  </div>
</div>
