<div class="wrapper">
  <div class="mat-elevation-z1 page">
    <h1 class="mat-h1" translate>Feed to your website</h1>

    <form [formGroup]="feedForm">
      <mat-tab-group>
        <mat-tab label="iFrame">
          <div fxLayout="column" fxLayoutAlign="start start">
            <p class="mat-body">1. {{'Filter the events' | translate}}:</p>
            <mat-radio-group #filter (change)="updateCode()" formControlName="filter" fxLayout="row"
              fxLayoutAlign="start center" fxLayoutAlign.xs="start start" fxLayout.xs="column" fxLayoutGap="30px"
              fxFlex="100" class="margin-bottom" aria-label="Filter by">
              <mat-radio-button value="all">{{'All' | translate}}</mat-radio-button>
              <mat-radio-button value="organizer">{{'Organizer' | translate}}</mat-radio-button>
              <mat-radio-button value="venue">{{'Venue' | translate}}</mat-radio-button>
              <mat-radio-button value="superEvent">{{'Umbrella event' | translate}}</mat-radio-button>
            </mat-radio-group>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
              <mat-form-field *ngIf="feedForm.controls['filter'].value == 'venue'" fxFlex="250px" fxFlex.xs="100"
                class="margin-bottom" appearance="outline">
                <mat-label translate>Venues</mat-label>
                <mat-select (selectionChange)="updateCode()" formControlName="venues">
                  <mat-option *ngFor="let venue of venues | async" [value]="venue.slug">{{venue.name}}</mat-option>
                </mat-select>
                <mat-hint><span translate>Filter for only some venues</span></mat-hint>
              </mat-form-field>
              <mat-form-field *ngIf="feedForm.controls['filter'].value == 'superEvent'" fxFlex="250px" fxFlex.xs="100"
                class="margin-bottom" appearance="outline">
                <mat-label translate>Parent events</mat-label>
                <mat-select (selectionChange)="updateCode()" formControlName="superEvents">
                  <mat-option *ngFor="let superEvent of superEvents | async" [value]="superEvent.id">
                    {{utilsService.getTitle(superEvent)}}</mat-option>
                </mat-select>
                <mat-hint><span translate>Filter for only a parent event</span></mat-hint>
              </mat-form-field>
              <mat-form-field *ngIf="feedForm.controls['filter'].value == 'organizer'" fxFlex="250px" fxFlex.xs="100"
                class="margin-bottom" appearance="outline">
                <mat-label translate>Organizers</mat-label>
                <mat-select (selectionChange)="updateCode()" formControlName="organizers">
                  <mat-option *ngFor="let organizer of organizers | async" [value]="organizer.slug">{{organizer.name}}
                  </mat-option>
                </mat-select>
                <mat-hint><span translate>Filter for only some organizers</span></mat-hint>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
              <mat-form-field fxFlex="250px" fxFlex.xs="100"
                class="margin-bottom" appearance="outline">
                <mat-label translate>Template</mat-label>
                <mat-select (selectionChange)="updateCode()" formControlName="template">
                  <mat-option *ngFor="let template of environment.templates" [value]="template.id">{{template.name}}
                  </mat-option>
                </mat-select>
                <mat-hint><span translate>Choose a template</span></mat-hint>
              </mat-form-field>
              <mat-form-field fxFlex="250px" fxFlex.xs="100"
                class="margin-bottom" appearance="outline">
                <mat-label translate>Number of events</mat-label>
                <input type="number" matInput (keyup)="updateCode()" formControlName="numEvents" />
                <mat-hint><span translate>Number of events to be shown</span></mat-hint>
                <mat-error *ngIf="feedForm.hasError('min',['numEvents'])" translate>
                  Number of events must be greater than zero
                </mat-error>
                <mat-error *ngIf="feedForm.hasError('max',['numEvents'])" translate>
                  Maximum number of events is 999
                </mat-error>
                <mat-error *ngIf="feedForm.hasError('required',['numEvents'])" translate>
                  Number of events is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <p class="mat-body">2. {{'Copy and paste the following code on your website' | translate}}:</p>
          <div class="code">{{code}}</div>
          <div *ngIf="iframeURLsSafe.length > 1"> 
            <iframe *ngFor="let iframeURLSafe of iframeURLsSafe" style="width: 100%" frameborder="0" [src]="iframeURLSafe"></iframe>
          </div>
          <div *ngIf="iframeURLsSafe.length == 1"> 
            <iframe [style]="'height: ' + pxHeight + 'px; width: 100%;'" frameborder="0" [src]="iframeURLsSafe[0]"></iframe>
          </div>
        </mat-tab>
        <mat-tab label="GraphQL API">
          <iframe style="height: 2500px; width: 100%;"  frameborder="0" [src]="graphQLURLSafe"></iframe>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>
</div>