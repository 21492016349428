import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-name-editor',
  templateUrl: './customer-name-editor.component.html',
  styleUrls: ['./customer-name-editor.component.scss']
})
export class CustomerNameEditorComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  name: string;

  @Input()
  hint?: string = this.translate.instant("Your name and surname");

  @Input()
  toucher: Observable<any>;

  @Output()
  onNameChange: EventEmitter<string> = new EventEmitter();

  @Output()
  onValidityChange: EventEmitter<boolean> = new EventEmitter();

  customerName: FormControl = new FormControl();
  sub: Subscription;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    if (this.name) {
      this.customerName.setValue(this.name);
    }
    this.customerName.valueChanges.subscribe((newName) => {
      this.onNameChange.emit(newName);
      this.onValidityChange.emit(this.customerName.valid);
    });
    this.sub = this.toucher?.subscribe(() => this.customerName.markAllAsTouched());
  }
  
  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.name?.currentValue) {
      this.customerName.setValue(changes.name.currentValue);
    }
  }
}
