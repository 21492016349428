import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-question-password',
  templateUrl: './question-password.component.html',
  styleUrls: ['./question-password.component.scss']
})
export class QuestionPasswordComponent implements OnInit {

  password: string;
  hide: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<QuestionPasswordComponent>,) { }

  ngOnInit(): void {
  }

}
