<div class="background-primary classic" [attr.outage-count]="'red'" *ngIf="environment.theme == 'classic'">
  <mat-toolbar class="app-toolbar background-primary" role="heading" fxLayout="row" fxLayout.lt-lg="row wrap"
    fxLayoutAlign="space-between center">
    <div fxFlexOrder="1" fxLayout="row" fxLayoutAlign="center center">
      <button class="more-button" fxFlexAlign="center" mat-icon-button (click)="toggleDrawer()">
        <mat-icon>menu</mat-icon>
      </button>
      <a [routerLink]="['/']" fxFlexAlign="center" fxFlex="noshrink">
        <div class="app-name" fxFlexAlign="center" fxFlex="noshrink"><span
            *ngIf="translate.currentLang == 'nb'">{{environment.content.siteNameTranslatable.nb}}</span><span
            *ngIf="this.translate.currentLang != 'nb'">{{environment.content.siteNameTranslatable.en}}</span><span
            class="demo" *ngIf="demo">(DEMO)</span></div>
      </a>
    </div>
    <app-smart-filter fxFlexOrder="2" fxFlexOrder.lt-lg="3" fxFlex="noshrink"></app-smart-filter>
    <div fxFlexOrder="3" fxFlexOrder.lt-lg="2" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div *ngFor="let partner of environment.partners" fxHide.xs="true" fxLayout="column"
        fxLayoutAlign="center center">
        <a target="_blank" [ngClass]="partner.style === 'original' ? 'original' : 'round'"
          [style.background-image]="'url(/assets/logos/'+partner.logo+')'" [href]="partner.link" class="brand"></a>
      </div>
      <div *ngIf="environment.language.multilanguage">
        <button class="language-button" *ngIf="translate.currentLang == 'nb'" mat-icon-button
          (click)="switchLanguage('en')">EN</button>
        <button class="language-button" *ngIf="translate.currentLang != 'nb'" mat-icon-button
          (click)="switchLanguage('nb')">NO</button>
      </div>
    </div>
  </mat-toolbar>
</div>
<div class="placeholder" *ngIf="environment.theme == 'trdevents'"></div>
<div *ngIf="environment.theme == 'trdevents'" fxLayout="row" fxLayoutAlign="space-between center" class="trdevents"
[ngClass]="{'contrast': contrast || openedMenu,  'vmfest-font' : isVMFest}">
  <div *ngFor="let partner of environment.partners" fxLayout="row" fxLayoutAlign="center center">
    <a [ngClass]="partner.style === 'original' ? 'original' : 'round'"
      [style.background-image]="isVMFest ? 'url(/assets/logos/vmfest-'+partner.logo+')' : 'url(/assets/logos/'+partner.logo+')'" routerLink="/" fragment="top" class="brand"></a>
    <a [routerLink]="[isVMFest ? '/vm-fest': '/']" fragment="top">
      <h1 class="title">{{isVMFest ? 'VM-Fest Trondheim 2025': environment.content?.titleFrontpage?.nb}}</h1>
    </a>
  </div>
  <div fxHide.lt-md fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <a class="uppercase header-links" routerLinkActive="active-link" *ngFor="let category of superCategories"
        fragment="top" [routerLink]="[isVMFest ? '/vm-fest': '/', 'category', category.slug]">{{category.name}}</a>
      <a class="uppercase header-links" routerLinkActive="active-link" fragment="top"
        [routerLink]="[isVMFest ? '/vm-fest': '/', 'category', ('other' | translate)]">{{'Other' | translate}}</a>
      <a class="uppercase header-links" [fxShow]="!rla.isActive" routerLinkActive="active-link" #rla="routerLinkActive"
        [routerLink]="['/organizers']">{{'Organizers' | translate}}</a>
      <a class="uppercase header-links active-link" [fxHide]="!rla.isActive" [routerLink]="['/']">{{'Less' |
        translate}}</a>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div *ngIf="environment.favorites">
        <button class="favorite-button" fxFlexAlign="center" mat-icon-button [routerLink]="[isVMFest ? '/vm-fest': '/', 'favorites']">
          <mat-icon>favorite_outlined</mat-icon>
        </button>
      </div>
      <div *ngIf="environment.language.multilanguage">
        <button class="language-button" *ngIf="translate.currentLang == 'nb'" (click)="switchLanguage('en')">EN</button>
        <button class="language-button" *ngIf="translate.currentLang != 'nb'" (click)="switchLanguage('nb')">NO</button>
      </div>
    </div>

  </div>
  <div fxHide.gt-sm fxShow.lt-md fxLayout="row" fxLayoutAlign="start start">
    <div *ngIf="environment.favorites">
      <button class="favorite-button" fxFlexAlign="center" mat-icon-button [routerLink]="[isVMFest ? '/vm-fest': '/', 'favorites']">
        <mat-icon>favorite_outlined</mat-icon>
      </button>
    </div>
    <button class="menu-button" fxFlexAlign="center" mat-icon-button (click)="toggleDrawer()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

</div>