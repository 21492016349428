<mat-divider></mat-divider>
<div class="list-item">
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start start" ngClass.sm="full-width" ngClass.xs="full-width">
    <div fxFlex.sm="100" fxFlex.xs="100" fxFlex="grow" fxLayout="column" class="text-left" fxLayoutAlign="center start">
      <div fxFlex="auto" class="title mat-body-strong">{{team.name}}</div>
      <div fxFlex="10px"></div>
      <div fxFlex="grow" class="start mat-small" *ngIf="team.editors"><strong>{{'Team editors' | translate}}</strong>: {{ team.editors.length > 0 ? team.editors.join(', ') : ('None' | translate )}}</div>
      <div fxFlex="10px"></div>
      <div fxFlex="grow" class="start mat-small"><strong>{{'Event editors' | translate}}</strong>: {{ team.users.length > 0 ? team.users.join(', ') : ('None' | translate )}}</div>
      <div fxFlex="10px"></div>
      <div fxFlex="grow" class="start mat-small" *ngIf="environment.ui.showOnForm.rsvp && team.users_bookings"><strong>{{'Booking editors' | translate}}</strong>: {{ team.users_bookings.length > 0 ? team.users_bookings.join(', ') : ('None' | translate )}}</div>
      <div fxFlex="grow" class="start mat-small" *ngIf="environment.ui.showOnForm.rsvp && !team.users_bookings"><strong>{{'Booking editors' | translate}}</strong>: {{ team.users.length > 0 ? team.users.join(', ') : ('None' | translate )}}</div>
      <div fxFlex="20px" class="meta mat-small" *ngIf="team.admin.email"><span translate>Admin</span>: {{team.admin.email}}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start start" class="actions" *ngIf="(authService.user | async)?.uid == team.admin.uid || team.editors?.indexOf((authService.user | async)?.email) != -1">
      <a fxFlex="auto" [routerLink]="['/edit-team', team.id]">{{'Edit' | translate}}</a>
      <a fxFlex="auto" class="warn" (click)="deleteTeam(team.id)" *ngIf="(authService.user | async)?.uid == team.admin.uid" >{{'Delete' | translate}}</a>
    </div>
  </div>
</div>
