<div class="wrapper">
    <div class="page mat-elevation-z1">
        <h1 class="mat-h1 margin-bottom" translate>Warnings</h1>
        <div class="loading" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!loading && (user?.roles?.owner || user?.roles?.admin)">
            <div class="margin-top"></div>
            <div fxLayout="column" [formGroup]="warnings">
                <mat-slide-toggle formControlName="showWarnings">{{'Show the following warning' | translate}}</mat-slide-toggle>
              
                <div fxLayout="row" class="margin-top" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="space-between start">
                    <div fxFlex="50" fxLayout="column" class="full-width" fxLayoutAlign="start start">
                        <p translate>Norwegian version</p>
                        <ckeditor *ngIf="isBrowser" class="full-width" matInput [editor]="Editor" [config]="configEditorNb"
                        formControlName="warningContentNb"></ckeditor>
                        <div class="hint">{{warnings.controls.warningContentNb.value.length}} / {{maxCharsContent}}</div>
                        <div class="error-field" *ngIf="warnings.hasError('maxlength',['warningContentNb'])">
                        {{'Maximum length is' | translate}} {{maxCharsContent}} {{'chars' | translate}}
                        </div>
                    </div>
                    <div fxFlex="50" fxLayout="column" class="full-width" fxLayoutAlign="start start">
                        <p translate>English version</p>
                        <ckeditor *ngIf="isBrowser" class="full-width" matInput [editor]="Editor" [config]="configEditorEn"
                        formControlName="warningContentEn"></ckeditor>
                        <div class="hint">{{warnings.controls.warningContentEn.value.length}} / {{maxCharsContent}}</div>
                        <div class="error-field" *ngIf="warnings.hasError('maxlength',['warningContentEn'])">
                        {{'Maximum length is' | translate}} {{maxCharsContent}} {{'chars' | translate}}
                        </div>
                    </div>
                </div>

                <div class="full-width margin-top" fxLayoutAlign="start start">
                    <p>{{'The warning will be shown in events taking place from and to the following dates' | translate }}:</p>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="space-between start">
                    <div fxFlex="50" fxLayout="column" class="full-width" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="100" class="full-width" appearance="outline">
                            <mat-label translate>From date</mat-label>
                            <input (click)="pickerFrom.open()" matInput [matDatepicker]="pickerFrom" required [max]="warnings.controls.toDate.value || null"
                              formControlName="fromDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom">
                              <mat-icon matDatepickerToggleIcon>event</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                            <mat-error *ngIf="warnings.hasError('matDatepickerMax',['fromDate'])" translate>
                                'From date' must be before the 'to date'
                            </mat-error>
                            <mat-error *ngIf="warnings.hasError('required',['fromDate'])" translate>
                                'From date' is required
                            </mat-error>
                          </mat-form-field>
                    </div>

                    <div fxFlex="50" fxLayout="column" class="full-width" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="100" class="full-width" appearance="outline">
                            <mat-label translate>To date</mat-label>
                            <input (click)="pickerTo.open()" matInput [matDatepicker]="pickerTo" required [min]="warnings.controls.fromDate.value || minDate"
                              formControlName="toDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerTo">
                              <mat-icon matDatepickerToggleIcon>event</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerTo></mat-datepicker>
                            <mat-error *ngIf="warnings.hasError('matDatepickerMin',['toDate'])" translate>
                                'To date' must be after the 'from date'
                            </mat-error>
                            <mat-error *ngIf="warnings.hasError('required',['toDate'])" translate>
                                'To date' is required
                            </mat-error>
                          </mat-form-field>
                    </div>
                </div>


                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                    <button mat-raised-button (click)="cancel()">{{'Cancel' | translate}}</button>
                    <button mat-raised-button color="primary" (click)="save()">{{'Save' | translate}}</button>
                </div>
            </div>
        </div>
        <div *ngIf="!loading && !(user?.roles?.owner || user?.roles?.admin)" translate>
            You need to have admin privileges to access this page
        </div>
    </div>
</div>