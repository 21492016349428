<div class="newsletter-form">
  <form fxLayout="column" fxLayoutAlign="center center" [formGroup]="newsletterForm" (submit)="addSubscriber()" #formDir="ngForm" >
    <div fxFlex="70px" fxFlex.xs="grow" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="center center">
      <input fxFlex.xs="auto" class="email" [placeholder]="'Enter your email' | translate" fxFlex="180px" type="text" formControlName="email"/>
      <button fxFlex.xs="40px" [disabled]="formDir.pending" fxFlex="220px" mat-raised-button color="primary" type="submit"><span translate>Subscribe to our newsletter!</span></button>
    </div>
    <div class="agreement"><span translate>By subscribing to our newsletter, you are agreeing to our </span> <a routerLink="/privacy-policy"><span translate>privacy policy</span></a></div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex="50px">
      <mat-error class="success" fxFlex="100" *ngIf="result && result.success && formDir.submitted" translate>
        Thanks for subscribing to our newsletter! :)
      </mat-error>
      <mat-error class="error" fxFlex="100" *ngIf="result && !result.success && result.didYouMean && formDir.submitted">
        {{'Did you mean' | translate }} <strong>{{result.didYouMean}}</strong>?
      </mat-error>
      <mat-error class="success" fxFlex="100" *ngIf="result && !result.success && !result.didYouMean && formDir.submitted" translate>
        Something went wrong while subscribing you to our newsletter! :(
      </mat-error>
      <mat-error class="error" fxFlex="100" *ngIf="newsletterForm.hasError('email',['email']) && formDir.submitted" translate>
        You must introduce a valid email address
      </mat-error>
      <mat-error class="error" fxFlex="100" *ngIf="newsletterForm.hasError('required',['email']) && formDir.submitted" translate>
        You must introduce a valid email address
      </mat-error>
    </div>
  </form>
</div>
