<form [formGroup]="bookingNumberForm">
    <div fxLayout="column" class="full-width" fxLayoutAlign="start center">

        <div class="full-width message" *ngIf="loading">
            {{'Sending' | translate }}...
        </div>
        <div class="full-width message" *ngIf="!loading && bookingNotFound">
            {{'Your tickets were not found. Please check your booking number and try again.' | translate }}
        </div>
        <div class="full-width message" *ngIf="!loading && reminderResultCode == 'sms-sent'">
            {{'The booking code has been sent in an SMS to your phone number.' | translate }}
        </div>
        <div class="full-width message" *ngIf="!loading && reminderResultCode == 'booking-not-found'">
            {{'No booking found for this event and given phone number. Please check the information and try again.' |
            translate }}
        </div>
        <div class="full-width message" *ngIf="!loading && reminderResultCode == 'unknown-error'">
            {{'There was an error sending you the booking number. Please try again in a few minutes or contact us.' |
            translate }}
        </div>

        <div fxFlex="auto" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="!booking">
            <p *ngIf="!showPhoneReminder">{{'Introduce your booking number' | translate}}:</p>
            <mat-form-field *ngIf="!showPhoneReminder" class="full-width" appearance="outline">
                <mat-label translate>{{'Booking number' | translate}}</mat-label>
                <mat-hint>{{'For example: XCF3R' | translate}}</mat-hint>
                <input type="text" required formControlName="bookingNumber" matInput class="booking-number">
                <mat-error *ngIf="bookingNumberForm?.hasError('required',['bookingNumber'])">
                    {{'The booking number is required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="showPhoneReminder" appearance="outline">
                <mat-label>{{'Telephone number' | translate}}</mat-label>
                <mat-hint>{{'I.e.: 99999999' | translate}}</mat-hint>
                <input type="tel" required formControlName="phoneReminder" matInput>
                <mat-error *ngIf="bookingNumberForm?.hasError('required',['customerPhone'])">
                    {{'Phone number is required' | translate }}
                </mat-error>
                <mat-error *ngIf="bookingNumberForm?.hasError('pattern',['customerPhone'])">
                    {{'Introduce a valid norwegian telephone number' | translate }}
                </mat-error>
            </mat-form-field>
            <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                <button *ngIf="!showPhoneReminder" mat-raised-button color="primary" class="full-width" [disabled]="loading"
                    (click)="getTickets()">{{ 'See or change number of tickets' | translate}}</button>
                <button *ngIf="showPhoneReminder" mat-raised-button color="primary" class="full-width" [disabled]="loading"
                    (click)="resendBookingNumber()">{{ 'Resend booking number' | translate}}</button>
                <button *ngIf="!showPhoneReminder" mat-stroked-button class="full-width"
                    (click)="reminderResultCode = null; bookingNotFound = false; showPhoneReminder = true;">{{'Forgot booking number?' | translate}}</button>
                <button *ngIf="showPhoneReminder" mat-stroked-button class="full-width"
                    (click)="reminderResultCode = null; bookingNotFound = false; showPhoneReminder = false;">{{'Cancel' | translate}}</button>
            </div>
        </div>

        <div class="full-width" *ngIf="booking">
            <app-booking-guest-editor [booking]="booking"></app-booking-guest-editor>
            <div class="cancel-warning" *ngIf="showCancellationWarning">{{'Cancellation period is over for this event' | translate}}. {{'If you want to cancel or change your tickets, call to' | translate}}
                {{environment.rsvpOptions.defaultSMS.supportPhone}}.</div>
        </div>
    </div>
</form>