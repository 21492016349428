import { Component, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  emailFormControl = new FormControl('', {
    validators: [
      Validators.required,
      Validators.email,
    ], updateOn: "blur"
  });
  passwordFormControl = new FormControl('', {
    validators: [
      Validators.required,
      Validators.minLength(4)
    ], updateOn: "blur"
  });
  passwordConfirmFormControl = new FormControl('', {
    validators: [
      Validators.required,
      Validators.minLength(4)
    ], updateOn: "blur"
  });

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  email: string;
  password: string;
  forgot: boolean = false;
  isRegister: boolean = false;
  wrongPassword: boolean = false;
  wrongEmail: boolean = false;
  tooManyFails: boolean = false;
  passMismatch: boolean = false;
  alreadyRegistered: boolean = false;
  passTooWeak: boolean = false;
  invalidEmail: boolean = false;
  accountExistsWithDifferentCredential: boolean = false;
  hide: boolean = true;
  environment: any;
  isServer: boolean = true;

  @ViewChild("emailInput") emailIn: ElementRef;
  constructor(
    private translate: TranslateService,
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: any,
    public snackBar: MatSnackBar) {
    this.environment = environment;
    this.isServer = isPlatformServer(this.platformId);
  }


  forgotAccount(): void {
    this.forgot = true;
    this.resetForm();
    this.emailIn.nativeElement.focus();
  }

  login(): void {
    this.isLoading$.next(true);
    this.resetErrorMessages();
    this.authService.login(this.emailFormControl.value, this.passwordFormControl.value)
      .then(result => {
        this.resetForm();
        this.openSnackBar(this.translate.instant("Logged in successful"), null);
        this.isLoading$.next(false);
      })
      .catch(err => {
        console.log(err);
        if (err.code == "auth/wrong-password") {
          this.isLoading$.next(false);
          this.wrongPassword = true;

        }
        if (err.code == "auth/account-exists-with-different-credential") {
          this.isLoading$.next(false);
          this.accountExistsWithDifferentCredential = true;
        }
        if (err.code == "auth/user-not-found") {
          this.isLoading$.next(false);
          this.wrongEmail = true;
        }
        if (err.code == 'auth/too-many-requests') {
          this.isLoading$.next(false);
          this.tooManyFails = true;
        }
      });
  }

  reset(): void {
    this.isLoading$.next(true);
    this.authService.reset(this.emailFormControl.value)
      .then(result => {
        this.isLoading$.next(false);
        this.openSnackBar(this.translate.instant("A reset link was sent to your email"), null);
      })
      .catch(err => {
        this.isLoading$.next(false);
        if (err.code == 'auth/user-not-found') {
          this.openSnackBar(this.translate.instant("This email is not registered."), null);
        }
      });
    this.initialScreen();
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  submitForm(event): void {
    event?.preventDefault();
    if (this.emailFormControl.valid) {
      if (this.forgot) {
        this.reset();
      } else if (this.isRegister && this.passwordFormControl.valid && this.passwordConfirmFormControl.valid) {
        this.register();
      } else if (this.passwordFormControl.valid) {
        this.login();
      }
    }
  }

  startRegister(): void {
    this.resetForm();
    this.isRegister = true;
  }

  resetForm(): void {
    this.emailFormControl.reset();
    this.emailFormControl.setErrors(null);
    this.passwordFormControl.reset();
    this.passwordFormControl.setErrors(null);
    this.passwordConfirmFormControl.reset();
    this.passwordConfirmFormControl.setErrors(null);
    this.resetErrorMessages();
  }

  resetErrorMessages(): void {
    this.wrongPassword = false;
    this.wrongEmail = false;
    this.tooManyFails = false;
    this.passMismatch = false;
    this.alreadyRegistered = false;
    this.passTooWeak = false;
    this.invalidEmail = false;
    this.accountExistsWithDifferentCredential = false;
  }

  initialScreen(): void {
    this.isRegister = false;
    this.forgot = false;
    this.resetForm();
  }

  register(): void {
    this.isLoading$.next(true);
    this.alreadyRegistered = false;
    this.passTooWeak = false;
    this.invalidEmail = false;

    this.passMismatch = this.passwordFormControl.value != this.passwordConfirmFormControl.value;
    if (!this.passMismatch) {
      this.authService.signup(this.emailFormControl.value, this.passwordFormControl.value)
        .then(result => {
          this.resetForm();
          this.openSnackBar(this.translate.instant("Registration successful"), null);
          this.isLoading$.next(false);
        })
        .catch(err => {
          this.isLoading$.next(false);
          if (err.code == "auth/email-already-in-use") {
            this.alreadyRegistered = true;
          }
          if (err.code == "auth/weak-password") {
            this.passTooWeak = true;
            this.passwordFormControl.setValue("");
            this.passwordConfirmFormControl.setValue("");
          }
          if (err.code == "auth/invalid-email") {
            this.invalidEmail = true;
            this.emailFormControl.setValue("");
          }
        });
    } else {
      this.passwordFormControl.setValue("");
      this.passwordConfirmFormControl.setValue("");
      this.isLoading$.next(false);
    }
  }

}
