import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Campaign } from '../models/campaign';
import { User } from '../models/user';
import { SendTestCampaignComponent } from '../send-test-campaign/send-test-campaign.component';
import { AuthService } from '../services/auth.service';
import { CampaignsService } from '../services/campaigns.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {

  user: User;
  campaigns: Observable<Campaign[]>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private campaignsService: CampaignsService,
    public translate: TranslateService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public authService: AuthService) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setupUserInfo();
    }
  }

  setupUserInfo(): void {
    let sub = this.authService.getCurrentUserInfo().subscribe(
      (user: User) => {
        sub.unsubscribe();
        if (user) {
          this.user = user;
          if (user ?.roles ?.admin) {
            let lastUpdates = this.campaignsService.updateCampaignsStatistics().subscribe(() => lastUpdates.unsubscribe());
            this.campaigns = this.campaignsService.getLastCampaigns();
          }
        }
      }
    );
  }

  generateCampaign() {
    let subs = this.campaignsService.generateCampaign().subscribe(
      () => {
        this.snackBar.open(this.translate.instant("A new campaign is being generated..."), null, {
          duration: 4000,
        });
        subs.unsubscribe();
      }
    )
  }

  stopCampaign(campaignID: string) {
    let subs = this.campaignsService.stopCampaign(campaignID).subscribe(
      () => {
        this.snackBar.open(this.translate.instant("The campaign is being stopped..."), null, {
          duration: 4000,
        });
        subs.unsubscribe();
      }
    )
  }

  resumeCampaign(campaignId: string) {
    let subs = this.campaignsService.resumeCampaign(campaignId).subscribe(
      () => {
        this.snackBar.open(this.translate.instant("The campaign is being resumed..."), null, {
          duration: 4000,
        });
        subs.unsubscribe();
      }
    )
  }

  deleteCampaign(campaignId: string) {
    let subs = this.campaignsService.deleteCampaign(campaignId).subscribe(
      () => {
        this.snackBar.open(this.translate.instant("The campaign is being deleted..."), null, {
          duration: 4000,
        });
        subs.unsubscribe();
      }
    );
  }

  sendTest(campaignId: string) {
    this.dialog.open(
      SendTestCampaignComponent, {
        width: '500px',
        data: {
          campaignId
        }
      });
  }
}
