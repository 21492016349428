import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-why-use',
  templateUrl: './why-use.component.html',
  styleUrls: ['./why-use.component.scss']
})
export class WhyUseComponent implements OnInit {
  environment: any;
  constructor(public translate: TranslateService) {
    this.environment = environment;
  }

  ngOnInit() {
  }

}
