import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';


import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss']
})
export class LoginScreenComponent implements OnInit {

  environment: any;
  loading: boolean = false;

  constructor(
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private route: ActivatedRoute) {
    this.environment = environment;
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loading = true;
      this.authService.onAuthStateChanged((authChange) => {
        if (authChange) {
          const redRoute = environment.theme == 'classic' ? '/my-events' : '/organizers/(organizers:my-events)';
          this.router.navigate([this.route.snapshot.queryParams.redirectTo || redRoute])
        } else {
          this.loading = false;
        }
      }
      );
    }
  }

}
