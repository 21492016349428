import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { EventRepetition } from '../models/event-repetition';
import { UtilsService } from '../services/utils.service';
import { debounceTime, first } from 'rxjs/operators';

import moment from 'moment';
import { SelectableEvent } from '../models/selectable-event';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Venue } from '../models/venue';
import { MatDialog } from '@angular/material/dialog';
import { ChangeStartDateComponent } from '../change-start-date/change-start-date.component';

@Component({
  selector: 'app-event-repetition',
  templateUrl: './event-repetition.component.html',
  styleUrls: ['./event-repetition.component.scss']
})
export class EventRepetitionComponent implements OnInit {

  @Input() eventRepetition: EventRepetition;

  @Input() registrationEnabled: boolean;

  @Input() allVenues: Venue[];

  @Input() minDate: any;

  @Output() removed = new EventEmitter();

  @Output() updated = new EventEmitter<EventRepetition>();

  hasClicked: boolean = false;
  durations: SelectableEvent[];
  regexpURL = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  repetitionForm = new FormGroup({
    startDate: new FormControl(),
    startTime: new FormControl(),
    availableTickets: new FormControl(null, [Validators.required]),
    duration: new FormControl(0, []),
    eventCancelled: new FormControl(false, []),
    eventSoldOut: new FormControl(false, []),
    mode: new FormControl('offline', []),
    ticketsURL: new FormControl('', [Validators.pattern(this.regexpURL)]),
    venue: new FormControl('', []),
    venueObj: new FormControl('', []),
  });

  constructor(
    public dialog: MatDialog,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.durations = this.utilsService.getAllDurations();
    this.repetitionForm.patchValue(this.eventRepetition);
    this.repetitionForm.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.updated.emit({
        ... this.eventRepetition,
        ... this.repetitionForm.value,
        venueObj: this.allVenues.find(v => v.id == this.repetitionForm.controls["venue"].value) || null
      });
    });
  }

  getStartDate() {
    let startDate = moment(this.eventRepetition.startDate);
    if (!startDate.isValid()) {
      startDate = moment.unix(this.eventRepetition.startDate.seconds);
    }
    return startDate.format(this.utilsService.getDateFormat(true)) + " @ " + this.utilsService.getLocalizedHour(this.eventRepetition.startTime);
  }

  getEndTime() {
    let startDate = moment(this.eventRepetition.startDate);
    if (!startDate.isValid()) {
      startDate = moment.unix(this.eventRepetition.startDate.seconds);
    }
    if (this.repetitionForm.controls["duration"].value > 0) {
      return startDate.add(this.repetitionForm.controls["duration"].value, 'minutes').format(this.utilsService.getTimeFormat())
    }
  }

  remove() {
    this.removed.emit();
  }

  changeStartDate() {
    let dialogRef = this.dialog.open(
      ChangeStartDateComponent, {
      width: '375px',
      data: { startDate: this.eventRepetition.startDate, startTime: this.eventRepetition.startTime, minDate: this.minDate }
    });
    dialogRef.afterClosed().pipe(first()).subscribe((newDate) => {
      if (newDate) {
        //Update the start date
        this.repetitionForm.patchValue(newDate);
      }
    });
  }
}
