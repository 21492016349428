<div class="image-thumbnail full-width" *ngIf="imageForm">
  <div fxLayout="column" fxLayoutGap="20px" class="full-width">
    <div *ngIf="!errorOnLoading" fxFlex="430px" fxLayout="row" fxLayoutAlign="center start" fxFlex.lt-md="100">
      <mat-spinner fxFlexAlign="center" *ngIf="deleting" diameter="50"></mat-spinner>
      <mat-progress-bar fxFlexAlign="center" *ngIf="uploading" mode="determinate" [value]="((percentSmall | async) + (percentLarge | async) + (percentOriginal | async)) / 3"></mat-progress-bar>
      <div *ngIf="!uploading && !deleting && imageForm.controls.urlLarge.value" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px" class="image-container">
        <img fxFlex="400px" [src]="imageForm.controls.urlLarge.value" />
        <div class="image-size full-width" fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="10px">
          <div *ngIf="imageForm.controls.urlOriginal && imageForm.controls.originalSize.value != 0">
            <a class="blue" target="_blank" [href]="imageForm.controls.urlOriginal.value">{{ 'Original' | translate}}</a> ({{imageForm.controls.originalSize.value | filesize}})
          </div>
          <div *ngIf="imageForm.controls.urlLarge && imageForm.controls.largeSize.value != 0">
            <a class="blue" target="_blank" [href]="imageForm.controls.urlLarge.value">{{ 'Large' | translate }}</a> ({{imageForm.controls.largeSize.value | filesize}})
          </div>
          <div *ngIf="imageForm.controls.urlSmall && imageForm.controls.smallSize.value != 0">
            <a class="blue" target="_blank" [href]="imageForm.controls.urlSmall.value">{{ 'Small' | translate}}</a> ({{imageForm.controls.smallSize.value | filesize}})
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="grow" fxLayout="column" [formGroup]="imageForm" fxLayoutAlign="space-between start" fxLayoutGap="20px">
      <div *ngIf="!errorOnLoading" fxLayout="column" class="full-width">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'Alternative text' | translate }}</mat-label>
          <input trim="blur" matInput type="text" formControlName="alt">
          <mat-hint align="end">{{'Text in case the image can not be shown' | translate }}</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'Caption' | translate }}</mat-label>
          <input trim="blur" matInput type="text" formControlName="caption">
          <mat-hint align="end">{{'Text that is shown under the image' | translate }}</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'Credits' | translate }}</mat-label>
          <input trim="blur" matInput type="text" formControlName="credits">
          <mat-hint align="end">{{'Image credits' | translate }}</mat-hint>
        </mat-form-field>
      </div>
      <div *ngIf="errorOnLoading" class="full-width error-field" fxLayout="row" fxFlexAlign="space-between start" fxLayoutGap="20px">
        {{'There was an error loading the image. Please upload it manually or contact us.' | translate}}
      </div>
      <div fxLayout="row" fxFlexAlign="space-between start" class="full-width" fxLayoutGap="20px">
        <div class="cover-image-text" *ngIf="index == 0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <mat-icon>star_rate</mat-icon> <span>{{'Cover image' | translate}}</span>
        </div>
        <div *ngIf="index != 0">
          <button class="move-up" mat-stroked-button type="button" (click)="moveUp()">
            <mat-icon aria-label="Flytt bildet opp">keyboard_arrow_up</mat-icon> {{'Move up' | translate}}
          </button>
        </div>
        <div *ngIf="!isLast">
          <button class="move-down" mat-stroked-button type="button" (click)="moveDown()">
            <mat-icon aria-label="Flytt bildet ned">keyboard_arrow_down</mat-icon> {{'Move down' | translate}}
          </button>
        </div>
        <div *ngIf="imageForm.controls.urlLarge && !deleting">
          <button class="remove-image" mat-flat-button type="button" color="warn" (click)="removeImage()">
            <mat-icon aria-label="Fjern bilde">delete</mat-icon> {{'Remove' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
