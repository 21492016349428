import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BookingStatus } from '../models/booking-status';
import allBookingStatuses from '../../assets/data/bookingStatuses.json';

@Injectable()
export class BookingStatusService {

  constructor(private translate: TranslateService) {}

  //Returns the list of ticket types ordered by the current language
  getAllBookingStatuses(): BookingStatus[] {
    const lanName = "name_" + (this.translate.currentLang || 'nb');
    return Object.keys(allBookingStatuses)
      .map(function(k) { return { id: k, name: allBookingStatuses[k][lanName], name_nb: allBookingStatuses[k].name_nb, name_en: allBookingStatuses[k].name_en }; })
      .sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
  }

  getBookingStatusName(statusID: string, lan?: string): string {
    if (statusID) {
      const lanName = "name_" + (lan || this.translate.currentLang || 'nb');
      return allBookingStatuses[statusID][lanName] || '';  
    }
    return 'Error';
  }

  isOnlyVisible(statusId: string): boolean {
    const bookingStatus: BookingStatus = allBookingStatuses[statusId];
    return bookingStatus?.only_visible || false;
  }

  isEditable(statusId: string): boolean {
    const bookingStatus: BookingStatus = allBookingStatuses[statusId];
    return bookingStatus?.editable || false;
  }
}
