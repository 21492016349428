<div class="wrapper">
  <div class="mat-body privacy-policy mat-elevation-z1 page">
    <div *ngIf="translate.currentLang == 'nb'" fxLayout="column">
      <h1 class="mat-h1">Retningslinjer for bilder</h1>

      <p><strong>Bruk av bilder skal være en gjennomtenkt handling.</strong></p>

      <p>Før du publiserer bilder på {{environment.content.siteName}} eller hvaskjeriklæbu.no, les gjennom dette:</p>
      <ul>
        <li>Du må forholde deg til «Retten til eget bilde» som er <a target="_blank" href="https://lovdata.no/dokument/NL/lov/2018-06-15-40">åndsverkloven</a> §45c som gir et forbud mot å publisere personbilder uten samtykke fra den som er avbildet.
          I
          tillegg må du forholde deg til <a target="_blank" href="https://lovdata.no/dokument/NL/lov/2018-06-15-38/KAPITTEL_3#KAPITTEL_3">personvernloven</a>.</li>
        <li>Du må vite forskjellen på portrettbilder og situasjonsbilder:</li>
        <ul>
          <li>Portrettbilder er bilder som viser en eller flere bestemte personer som regnes som et hovedmotiv. Klassebilder regnes som portrettbilder.</li>
          <li>Situasjonsbilder er bilder der aktivitet eller hendelse er hovedinnholdet.</li>
        </ul>
      </ul>
      <p></p>
      <p>Portrettbilder: Du må informere personer du tar bilde av om hvilken sammenheng bildet vil bli brukt, og at det kan bli delt i sosiale medier.</p>
      <p></p>
      <p>Situasjonsbilder: Noen ganger er personene gjenkjennbare og det grenser opp mot portrettbilde.</p>
      <ul>
        <li><strong>Samtykke</strong>: Det er bedre å spørre en gang for mye enn en gang for lite.</li>
        <ul>
          <li>Du må ha samtykke til publisering både når du har tatt bildet selv og når du videreformidler.</li>
          <li>Du må alltid ha samtykke for å publisere portrettbilder.</li>
          <li>Du trenger ikke samtykke for å publisere situasjonsbilder som er av allmenn interesse eller gjengir offentlige hendelser som folketog, forsamlinger, demonstrasjoner og lignende</li>
          <li>Foresatte må samtykke når bildet er av barn under 15 år. Hvis en av de foresatte sier nei, kan bildet ikke publiseres. Sier barnet nei, kan bildet ikke publiseres. Barn bør selv velge hvor synlige de skal være på nett:</li>
          <ul>
            <li>Når barnet er fylt 7 år har det rett til å si sin mening om saken</li>
            <li>Når barnet har fylt 12 år skal det legges stor vekt på hva barnet mener.</li>
            <li>Når barnet er fylt 15 år skal det avgjøre selv om bildet skal publiseres.</li>
          </ul>
        </ul>
        <li>Unngå å bruke bilder som er tatt i andre sammenhenger</li>
      </ul>
      <p></p>
      <p>Du må vurdere om det er etisk riktig å videreformidle bildet:</p>
      <ul>
        <li>Er det noe/noen i bakgrunnen som av ulike årsaker gjør at bildet ikke bør publiseres?</li>
        <li>Kan bildet oppleves krenkende for den som er avbildet eller på annen måte?</li>
        <li>Tenk over innholdet og bruk filter/dårlig oppløsning når det er mulig.</li>
      </ul>
      <p><a target="_blank" href="https://www.datatilsynet.no/personvern-pa-ulike-omrader/internett-og-apper/bilder-pa-nett/">Datatilsynets sider om bilder på nett</a></p>
    </div>

    <div *ngIf="translate.currentLang != 'nb'" fxLayout="column">
      <h1 class="mat-h1">Guidelines for images</h1>

      <p><strong>The use of images should be a thoughtful action.</strong></p>

      <p>Before you publish photos at {{environment.siteURL}}, read through this list:</p>
      <ul>
        <li>You have confirm to the "Right to your own image" which is <a target="_blank" href="https://lovdata.no/dokument/NL/lov/2018-06-15-40">the Copyright Act</a> §45c which provides a ban on publishing personal photos without the consent of the
          person pictured. In addition you have to deal with <a target="_blank" href="https://lovdata.no/dokument/NL/lov/2018-06-15-38/KAPITTEL_3#KAPITTEL_3">the Privacy Act</a>.</li>
        <li>You need to know the difference between portrait and situation pictures:</li>
        <ul>
          <li>Portraits are images that show one or more specific people being considered a main subject. Class photos are considered portrait photos.</li>
          <li>Situation images are images where activity or event is the main content.</li>
        </ul>
      </ul>
      <p></p>
      <p>Portrait photos: You must inform people you are taking a picture of about the context in which the photo will be used and that it can be shared on social media.</p>
      <p></p>
      <p>Situation pictures: Sometimes the people are recognizable and it borders on the portrait picture.</p>
      <ul>
        <li><strong>Consent</strong>: It is better to ask once too much than once too little.</li>
        <ul>
          <li>You must have consent to publication both when you have taken the photo yourself and when you pass it on.</li>
          <li>You must always have consent to publish portraits.</li>
          <li>You do not need consent to publish situational pictures that are of public interest or reproduce public events such as rallies, assemblies, demonstrations and the like.</li>
          <li>Parents must consent when the picture is of children under 15 years of age. If one of the parents says no, the photo cannot be published. If the child says no, the picture cannot be published. Children should choose for themselves how
            visible they should be online:</li>
          <ul>
            <li>When the child has reached the age of 7, he or she has the right to express his or her opinion on the matter</li>
            <li>When the child has reached the age of 12, great emphasis must be placed on what the child means.</li>
            <li>When the child has reached the age of 15, (s)he must decide whether the picture is to be published.</li>
          </ul>
        </ul>
        <li>Avoid using pictures taken in other contexts</li>
      </ul>
      <p></p>
      <p>You must consider whether it is ethically correct to pass on the image:</p>
      <ul>
        <li>Is there something / someone in the background that for various reasons means that the image should not be published?</li>
        <li>Can the image be perceived as offensive to the person depicted or in any other way?</li>
        <li>Think about the content and use filter / poor resolution whenever possible.</li>
      </ul>
      <p><a target="_blank" href="https://www.datatilsynet.no/personvern-pa-ulike-omrader/internett-og-apper/bilder-pa-nett/">The Data Inspectorate's pages on images online</a></p>
    </div>
  </div>
</div>
