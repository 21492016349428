import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface QuestionData {
  title: string;
  question: string;
}

@Component({
  selector: 'app-question-warning',
  templateUrl: './question-warning.component.html',
  styleUrls: ['./question-warning.component.scss']
})
export class QuestionWarningComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<QuestionWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuestionData) { }

  ngOnInit() {
  }

}
