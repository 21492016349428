import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { TranslateService } from '@ngx-translate/core';
import { CampaignsService } from '../services/campaigns.service';
import { HttpsCallableResult } from '@angular/fire/functions';

@Component({
  selector: 'app-send-test-campaign',
  templateUrl: './send-test-campaign.component.html',
  styleUrls: ['./send-test-campaign.component.scss']
})
export class SendTestCampaignComponent implements OnInit {

  formIsValid: boolean = false;
  formIsWorking: boolean = false;
  testEmails: string[] = [];
  addOnBlur: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];


  constructor(
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SendTestCampaignComponent>,
    private campaignsService: CampaignsService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {

  }

  onCancel() {
    this.dialogRef.close();
  }

  //Prevent closing the dialog when the user press Enter focused on the "name" field
  preventClosing(e) {
    e.preventDefault();
  }

  sendTestEmails() {
    this.formIsWorking = true;
    if (this.testEmails.length > 0) {
      let sub = this.campaignsService.sendTestEmail(this.data.campaignId, this.testEmails).subscribe(
        (res: HttpsCallableResult<any>) => {
          this.formIsWorking = false;
          if (res.data.success) {
            this.dialogRef.close();
            this.snackBar.open(this.translate.instant("Test emails were sent"), null, {
              duration: 4000,
            });
          } else {
            console.error("Error sending test emails");
            this.snackBar.open(this.translate.instant("There was an error while sending the test email"), null, {
              duration: 4000,
            });
          }
          sub.unsubscribe();
        },
        (error) => {
          this.formIsWorking = false;
          console.error(error);
          this.snackBar.open(this.translate.instant("There was an error while sending the test email"), null, {
            duration: 4000,
          });
          sub.unsubscribe();
        }
      );
    }
  }

  addTestEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add a member
    if ((value || '').trim() && this.isValidEmail(value)) {
      this.testEmails.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTest(testEmail: string): void {
    const index = this.testEmails.indexOf(testEmail);

    if (index >= 0) {
      this.testEmails.splice(index, 1);
    }
  }

  isValidEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
