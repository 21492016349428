import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { User } from '../models/user';
import { Warning } from '../models/warning';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event.service';
import moment from 'moment';

@Component({
  selector: 'app-warnings',
  templateUrl: './warnings.component.html',
  styleUrls: ['./warnings.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class WarningsComponent implements OnInit {

  user: User;

  maxCharsContent: number = 300;

  Editor;
  configEditorEn;
  configEditorNb;
  isBrowser = false;

  minDate = new Date();

  loading: boolean = true;

  warnings: FormGroup = new FormGroup({
    showWarnings: new FormControl(),
    fromDate: new FormControl(),
    toDate: new FormControl(),
    warningContentNb: new FormControl('', [Validators.maxLength(this.maxCharsContent)]),
    warningContentEn: new FormControl('', [Validators.maxLength(this.maxCharsContent)])
  });

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private translate: TranslateService,
    private router: Router,
    private eventService: EventService,
    private adapter: DateAdapter<any>,
    public authService: AuthService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setAdapterLocale(this.translate.currentLang);
      this.setupUserInfo();
      this.setupEditor();
      this.isBrowser = true;
      this.translate.onLangChange.subscribe(() => {
        //If the language change while we are on this screen
        this.setAdapterLocale(this.translate.currentLang);
      });
      this.setupWarning();
    }
  }

  setAdapterLocale(language: string) {
    if (language != 'nb') {
      this.adapter.setLocale('en-GB');
    } else {
      this.adapter.setLocale(language);
    }
  }

  setupUserInfo(): void {
    let sub = this.authService.getCurrentUserInfo().subscribe(
      (user: User) => {
        sub.unsubscribe();
        if (user) {
          this.user = user;
        }
        this.loading = false;
      }
    )
  }

  setupEditor() {
    const ClassicEditor = require('@ckeditor/ckeditor5-build-classic');
    this.Editor = ClassicEditor;
    this.configEditorNb = {
      placeholder: this.translate.instant("Warning content in Norwegian"),
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      heading: {
        options: [
          { model: 'paragraph', title: this.translate.instant("Normal text"), class: 'ck-heading_paragraph' }
        ]
      }
    };
    this.configEditorEn = {
      placeholder: this.translate.instant("Warning content in English"),
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      heading: {
        options: [
          { model: 'paragraph', title: this.translate.instant("Normal text"), class: 'ck-heading_paragraph' }
        ]
      }
    };
  }

  setupWarning() {
    this.eventService.getWarning().pipe(first()).subscribe(
      (warning: Warning) => {
        if (warning) {
          this.warnings.patchValue(warning);
          this.warnings.controls.fromDate.setValue(warning.fromDate.toDate());
          this.warnings.controls.toDate.setValue(warning.toDate.toDate());
        }
      }
    )
  }

  cancel(): void {
    this.router.navigate(["/"]);
  }

  save(): void {
    if (this.warnings.valid) {
      this.loading = true;
      const newWarning = this.warnings.value;
      if (newWarning.fromDate) {
        newWarning.fromDate = moment(newWarning.fromDate).toDate();
      } else {
        newWarning.fromDate = null;
      }
      if (newWarning.toDate) {
        newWarning.toDate = moment(newWarning.toDate).toDate();
      } else {
        newWarning.toDate = null;
      }
      this.eventService.setWarning(newWarning).pipe(first()).subscribe(
        (res) => {
          this.loading = false;
          if (res) {
            this.snackBar.open(this.translate.instant("Warning was set successfully"), null, {
              duration: 10000,
            });
            this.router.navigate(["/"]);
          } else {
            this.snackBar.open(this.translate.instant("There was a problem setting the warning. Contact us."), null, {
              duration: 10000,
            });
          }
        }
      );
    }
  }
}
