<div class="repetition-item mat-elevation-z1" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
  <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="date" fxFlex="grow">{{getStartDate()}} <span *ngIf="eventRepetition.duration > 0"> -
        {{getEndTime()}}</span> <a class="blue" (click)="changeStartDate()">({{'change' | translate}})</a></div>
    <button fxFlex="40px" class="remove" type="button" mat-icon-button color="warn" (click)="remove()"
      *ngIf="eventRepetition.activeTickets == 0">
      <mat-icon aria-label="Remove repetition">delete</mat-icon>
    </button>
  </div>
  <form class="full-width" [formGroup]="repetitionForm" fxLayout="column" fxLayoutAlign="start start"
    fxLayoutGap="15px">
    <div class="full-width" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px" fxLayout.xs="column">
      <mat-form-field class="full-width" fxFlex="150px" fxFlex.xs="50px">
        <mat-label translate>Duration</mat-label>
        <mat-select placeholder="'Duration' | translate" formControlName="duration">
          <mat-option *ngFor="let duration of durations" [value]="duration.value">
            {{duration.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!registrationEnabled" class="full-width" fxFlex="grow" fxFlex.xs="100">
        <mat-label translate>Tickets URL</mat-label>
        <input trim="blur" matInput tyle="url" formControlName="ticketsURL">
        <mat-hint align="end" translate>Please, insert the URL for buying the tickets</mat-hint>
        <mat-error *ngIf="repetitionForm.hasError('pattern',['ticketsURL'])" translate>
          Tickets URL must be a valid URL (i.e. https://example.com)
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="registrationEnabled" fxFlex>
        <mat-label>{{'Available tickets' | translate}}</mat-label>
        <input type="number" matInput formControlName="availableTickets" min="0" required>
        <mat-hint>{{'Number of available tickets' | translate}}</mat-hint>
        <mat-error *ngIf="repetitionForm.get('availableTickets').hasError('required')">
          {{'The number of available tickets is required' | translate}}
        </mat-error>
        <mat-error *ngIf="repetitionForm.get('availableTickets').hasError('min')">
          {{'The number of available tickets must be a non negative number' | translate}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="full-width" fxLayout="row" *ngIf="repetitionForm.controls.mode.value == 'offline'"
      fxLayoutAlign="start start">
      <mat-form-field class="full-width">
        <mat-label translate>Venue</mat-label>
        <mat-select placeholder="'Venue' | translate" (click)="hasClicked=true" formControlName="venue">
          <mat-option *ngIf="!eventRepetition.venue" [value]="" disabled selected></mat-option>
          <mat-option *ngIf="eventRepetition.venue" [value]="eventRepetition.venue"
            selected>{{eventRepetition?.venueObj?.name || ''}}</mat-option>
          <ng-container *ngIf="hasClicked">
            <mat-option *ngFor="let venueObj of allVenues" [value]="venueObj.id">
              {{venueObj.name}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="full-width" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px" fxLayout.xs="column">
      <mat-checkbox formControlName="eventCancelled"><span translate>Event is cancelled</span></mat-checkbox>
      <mat-checkbox formControlName="eventSoldOut"><span translate>Event is sold out</span></mat-checkbox>
    </div>
  </form>
</div>