<h2 mat-dialog-title>{{'Add ticket type' | translate}}</h2>
<form [formGroup]="addTicketTypeForm" (keydown.enter)="preventClosing($event)">
    <div mat-dialog-content>
      <div id="adding-ticket-type" *ngIf="formIsWorking">
        <mat-spinner diameter="60"></mat-spinner>
      </div>
      <mat-form-field appearance="outline">
        <mat-label translate>Ticket type in Norwegian</mat-label>
        <input trim="blur" required matInput #name_nb maxlength="45" formControlName="name_nb">
        <mat-hint align="end">{{name_nb.value.length}} / 80</mat-hint>
        <mat-error *ngIf="addTicketTypeForm.hasError('required',['name_nb'])" translate>
          The name in Norwegian is required
        </mat-error>
        <mat-error *ngIf="addTicketTypeForm.hasError('maxlength',['name_nb'])" translate>
          Maximum length is 80 chars
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label translate>Ticket type in English</mat-label>
        <input trim="blur" required matInput #name_en maxlength="45" formControlName="name_en">
        <mat-hint align="end">{{name_en.value.length}} / 80</mat-hint>
        <mat-error *ngIf="addTicketTypeForm.hasError('required',['name_en'])" translate>
          The name in English is required
        </mat-error>
        <mat-error *ngIf="addTicketTypeForm.hasError('maxlength',['name_en'])" translate>
          Maximum length is 80 chars
        </mat-error>
      </mat-form-field>
      
    </div>
    <div align="end" mat-dialog-actions>
      <span class="fill-spacer"></span>
      <button [disabled]="formIsWorking" mat-button (click)="onCancel()">Cancel</button>
      <button [disabled]="!addTicketTypeForm.valid || formIsWorking" mat-raised-button color="primary" type="button" (click)="addTicketType()"><span translate>Add ticket type</span></button>
     </div>
  </form>