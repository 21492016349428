<h2 *ngIf="!this.data" mat-dialog-title translate>Add a team</h2>
<h2 *ngIf="this.data" mat-dialog-title translate>Edit a team</h2>
<form [formGroup]="addTeamForm" (keydown.enter)="preventClosing($event)">
  <div mat-dialog-content>
    <div id="adding-team" *ngIf="formIsWorking">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
    <mat-form-field appearance="outline">
      <mat-label translate>Team name</mat-label>
      <input trim="blur" required matInput #name maxlength="45" formControlName="name">
      <mat-hint align="end">{{name.value.length}} / 45</mat-hint>
      <mat-error *ngIf="addTeamForm.hasError('required',['name'])" translate>
        The name is required
      </mat-error>
      <mat-error *ngIf="addTeamForm.hasError('maxlength',['name'])" translate>
        Maximum length is 45 chars
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="start start">
      <mat-form-field appearance="outline">
        <mat-chip-list #membersList aria-label="Team members">
          <mat-chip
                  [selected]="isAdmin(memberEmail)"
                  [removable]="!isAdmin(memberEmail)"
                  (removed)="removeMember(memberEmail)"
                  *ngFor="let memberEmail of memberEmails">
            {{memberEmail}} {{isAdmin(memberEmail) ? '(admin)' : ''}}
            <mat-icon matChipRemove *ngIf="!isAdmin(memberEmail)">cancel</mat-icon>
          </mat-chip>
          <input [placeholder]="'New member...' | translate"
                 [matChipInputFor]="membersList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addMember($event)">
        </mat-chip-list>
        <mat-hint align="end" translate>Introduce the email addresses of the team members</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div align="end" mat-dialog-actions>
    <span class="fill-spacer"></span>
    <button [disabled]="formIsWorking" mat-button (click)="onCancel()">Cancel</button>
    <button *ngIf="!this.data" [disabled]="!addTeamForm.valid || formIsWorking" mat-raised-button color="primary" type="button" (click)="addTeam()"><span translate>Add Team</span></button>
    <button *ngIf="this.data" [disabled]="!addTeamForm.valid || formIsWorking" mat-raised-button color="primary" type="button" (click)="saveTeam()"><span translate>Save</span></button>
  </div>
</form>
