<div *ngIf="event" [formGroup]="ticketsControl" fxLayout="column" fxLayoutGap="10px">
    <div [fxLayout]="inDoorCheckinMode ? 'row' : 'column'" fxLayout.lt-lg="column" fxLayoutAlign="start start" [fxLayoutGap]="inDoorCheckinMode ? '40px' : '10px'">
        <div [ngClass]="ticketsControl.touched && ticketsControl.hasError('atLeastOneTicket') ? 'red-border' : ''"
            *ngFor="let ticketControl of ticketsControl.controls; let i=index;" fxLayoutGap="30px" fxLayout="row" 
            fxLayoutAlign="start start" [class.in-door]="inDoorCheckinMode" class="ticket-row full-width" [formGroup]="ticketsControl.at(i)">
            <div *ngIf="event.prices[i]" class="full-width">
                <div class="ticket-type">{{getTicketTypeName(event.prices[i])}}</div>
                <div class="ticket-price">{{event.prices[i].price > 0 ? (event.prices[i].price |
                    currency:'NOK':'symbol':'0.2-2':'nb') :
                    ('Gratis' | translate)}}</div>
            </div>
            <div class="ticket-number" fxFlex="150px" fxLayout="row" fxLayoutAlign="space-between center"
                *ngIf="event.prices[i]">
                <div fxFlex="30">
                    <button type="button" class="background-accent" (click)="removeATicket($event, i)">
                        <mat-icon>remove</mat-icon>
                    </button>
                </div>
                <div fxFlex="30">
                    <input type="number" min="0" class="text-center" matInput formControlName="numberTickets">
                </div>
                <div fxFlex="30">
                    <button type="button" class="background-accent" (click)="addATicket($event, i)">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="in-door ticket-row" *ngIf="inDoorCheckinMode">
            <div class="ticket-type">Total</div>
            <div class="ticket-price">{{totalPrice | currency:'NOK':'symbol':'0.2-2':'nb' }}</div>
        </div>
    </div>

    <div class="hint" *ngIf="!inDoorCheckinMode">
        {{'Select the number of tickets' | translate}}. {{'All prices include VAT' | translate}}. {{'Available tickets'
        |
        translate}}: <strong>{{ numAvailableTickets < 0 ? 0 : numAvailableTickets}}</strong>
    </div>
    <div class="hint" *ngIf="!inDoorCheckinMode && (updatedStatus == 'waiting_list')">
        {{'The selected number of tickets will add your booking to the waiting list' | translate}}.
    </div>
    <mat-error class="error-field" *ngIf="ticketsControl?.touched && ticketsControl?.hasError('atLeastOneTicket')">
        {{'You must select at least one ticket' | translate }}
    </mat-error>
</div>
<div fxLayout="row" *ngIf="!inDoorCheckinMode" class="full-width" fxLayoutAlign="end center">
    <div class="bold">Total: {{totalPrice | currency:'NOK':'symbol':'0.2-2':'nb'}}</div>
</div>