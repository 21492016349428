import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Event } from '../models/event';
import { Venue } from '../models/venue';
import { Organizer } from '../models/organizer';
import { UtilsService } from './utils.service';
import { CategoryService } from './category.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { VenueService } from './venue.service';
import { OrganizerService } from './organizer.service';

import { Timestamp } from '@angular/fire/firestore';
import moment from 'moment';
import { from, of } from 'rxjs';

@Injectable()
export class ImportService {

  private functions: Functions = inject(Functions);

  constructor(private venueService: VenueService,
    private categoryService: CategoryService,
    private organizerService: OrganizerService,
    private utilsService: UtilsService) {
  }

  importEventFromFacebook(eventURL: string): Observable<any> {
    const callable = httpsCallable(this.functions, 'getFacebookEvent');
    return from(callable({ fbURL: eventURL })).pipe(
      mergeMap((fbEvent: any) => this.fbEventToEvent(fbEvent.data, eventURL))
    );
  }

  private fbEventToEvent(fbEvent: any, fbURL: string) {
    console.log(fbEvent);
    let event: Event = new Event();
    event.title_nb = fbEvent.title_nb || '';
    event.title_en = fbEvent.title_en || '';
    event.desc_nb = this.utilsService.getHtmlFromPlainText(fbEvent.desc_nb || '');
    event.desc_en = this.utilsService.getHtmlFromPlainText(fbEvent.desc_en || '');
    if (fbEvent.startDate) {
      event.startDate = this.extractDate(fbEvent.startDate);
      event.startTime = this.extractTime(fbEvent.startDate);
      if (fbEvent.endDate) {
        event.duration = this.extractDuration(fbEvent.startDate, fbEvent.endDate);
        event.endDate = this.extractDate(fbEvent.endDate);
      } else {
        event.endDate = event.startDate;
      }
    }
    event.categories = this.extractCategories((event.title_nb + " " + event.desc_nb).toLowerCase());
    event.imageBase64 = fbEvent.imageBase64;
    event.imageCaption = fbEvent.imageCaption;
    event.organizers = [];
    event.mode = fbEvent.mode === 'online' ? 'online' : 'offline';
    event.venue = '';
    event.ageRestriction = 'no-restriction';
    event.facebookURL = fbURL;
    event.ticketsURL = fbEvent.ticketsURL;
    return this.venueService.getVenueFromVenueName(fbEvent.venueName).pipe(
      map((venue: Venue) => {
        if (venue) {
          event.venue = venue.id;
        }
        return event;
      }),
      mergeMap((event: Event) => (!event.venue && fbEvent.venueName && this.venueService.getVenueFromVenueAddress(fbEvent.venueName).pipe(
        map((venue: Venue | null) =>  {
          if (venue?.id) {
            event.venue = venue.id;
          }
          return event;
        })
      )) || of(event)),
      mergeMap((event: Event) => {
        //Get as many organizers as possible
        return this.organizerService.getOrganizersFromOrganizerNames(fbEvent.organizerNames).pipe(
          map((organizers: Organizer[]) => {
            if (organizers) {
              for(let mapOrganizer of organizers) {
                if (mapOrganizer) {
                  event.organizers.push({ organizer: mapOrganizer.id, organizerObj: mapOrganizer });
                }
              }
            }
            return event;
          })
        )
      })
    );
  }

  private extractDate(date: string): Timestamp {
    //"2019-06-23T15:00:00-07:00
    let momentDate = moment(date, "YYYY-MM-DD[T]HH:mm:ssZ");
    return new Timestamp(+momentDate.format('X'), 0);
  }

  private extractTime(date: string): string {
    //"2019-06-23T15:00:00-07:00
    let momentDate = moment(date, "YYYY-MM-DD[T]HH:mm:ssZ");
    return momentDate.format("H:mm");
  }


  private extractDuration(startDateStr: string, endDateStr: string): number {
    let startDate = moment(startDateStr, "YYYY-MM-DD[T]HH:mm:ssZ");
    let endDate = moment(endDateStr, "YYYY-MM-DD[T]HH:mm:ssZ");
    let duration = endDate.diff(startDate, 'minutes');
    if (duration <= 24 * 60) {
      return duration;
    }
    return 0;
  }

  private extractCategories(words: string): string[] {
    let allCategories = this.categoryService.getAllCategories();
    let selectedCatIDs = [];
    for (let cat of allCategories) {
      if (words.indexOf(cat.name_en.toLowerCase()) != -1 || words.indexOf(cat.name_nb.toLowerCase()) != -1) {
        selectedCatIDs.push(cat.id);
      }
    }
    return selectedCatIDs;
  }

}
