<mat-form-field fxFlex="400px" appearance="outline">
    <mat-label translate>Select the new venue</mat-label>
    <mat-select required [formControl]="venueControl">
        <mat-option>
            <ngx-mat-select-search trim="blur"
                [placeholderLabel]="'Type the venue name' | translate"
                [formControl]="venueFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="(filteredVenues | async)?.length == 0" [value]="null">{{'No
            venues found with this name' | translate}}</mat-option>
        <mat-option *ngFor="let venue of filteredVenues | async" [value]="venue.name">
            {{venue.name}}</mat-option>
    </mat-select>
    <mat-hint *ngIf="venueControl.value" align="end">
        {{venueControl.value?.address}}
    </mat-hint>
    <mat-error *ngIf="venueControl.hasError('required')" translate>
        The venue is required
    </mat-error>
</mat-form-field>