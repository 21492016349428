<mat-divider></mat-divider>
<div class="list-item">
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start start" ngClass.sm="full-width" ngClass.xs="full-width">
    <div fxFlex.sm="100" fxFlex.xs="100" fxFlex="grow" fxLayout="column" class="text-left" fxLayoutAlign="center start">
      <div fxFlex="auto" class="title mat-body-strong">{{utilsService.getTitle(event)}} <span *ngIf="event.repetitions && event.repetitions.length > 0">({{event.repetitions.length + 1}} <span translate>repetitions</span>)</span></div>
      <div fxLayout="column" fxFlex="grow" >
        <div class="start mat-small">{{utilsService.getStartDate(event)}} @ {{event.venueObj?.name}} <span *ngIf="event.registrationEnabled">({{getBookedTickets(event)}}/{{event.availableTickets}})</span></div>
        <div *ngIf="event.repetitions?.length > 0" >
          <div *ngFor="let rep of event.repetitions" class="start mat-small">{{utilsService.getStartDate(rep)}} @ {{rep.venueObj?.name}} <span *ngIf="event.registrationEnabled">({{getBookedTickets(event, rep)}}/{{rep.availableTickets}})</span></div>
        </div>
      </div>
      <div fxFlex="20px" class="meta mat-small" *ngIf="event.updated_at"><span translate>Updated at</span>: {{utilsService.getUpdatedAt(event)}}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start start" class="actions">
      <a *ngIf="event.registrationEnabled" fxFlex="auto" [routerLink]="['/bookings']" [queryParams]="{ eventId: event.id, startDateSeconds: event.startDate.seconds, venueName: event.venueObj.name }">{{'Bookings' | translate}}</a>
      <a fxFlex="auto" [routerLink]="['/event', event.event_slug]"><span translate>View</span> </a>
      <a fxFlex="auto" [routerLink]="['/edit-event', event.id]"><span translate>Edit</span> </a>
      <a fxFlex="auto" class="warn" (click)="deleteEvent(event.id)"><span translate>Delete</span> </a>
    </div>
  </div>
</div>
