import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ImportService } from '../services/import.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-import-facebook',
  templateUrl: './import-facebook.component.html',
  styleUrls: ['./import-facebook.component.scss']
})
export class ImportFacebookComponent implements OnInit {

  regexpURL = /^(?:https?\:\/\/)?(?:www\.)?(m\.)?facebook\.com\/events\/\d{10,20}((\/|\?).*)?$/i;

  errorImporting: boolean = false;
  errorURL: boolean = false;

  importEventForm = new FormGroup({
    facebookURL: new FormControl('', [Validators.pattern(this.regexpURL)]),
  });

  isLoading: boolean = false;

  constructor(
    private translate: TranslateService,
    private importService: ImportService,
    public dialogRef: MatDialogRef<ImportFacebookComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.importEventForm.controls['facebookURL'].valueChanges.subscribe(value => {
      this.errorImporting = false;
      this.errorURL = false;
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  importEvent() {
    if (this.importEventForm.valid) {
      let eventField = this.importEventForm.controls["facebookURL"].value;
      let eventIDExp = (/\d{10,20}/i).exec(eventField);
      if (eventIDExp) {
        let eventID = eventIDExp[0];
        let eventURL = 'https://www.facebook.com/events/' + eventID;
        this.isLoading = true;
        this.importService.importEventFromFacebook(eventURL).pipe(
          catchError((err) => {
            this.isLoading = false;
            this.errorImporting = true;
            console.error(err);
            return throwError(err);
          })
        ).subscribe(
          (event) => {
            this.isLoading = false;
            this.dialogRef.close(event);
            this.snackBar.open(this.translate.instant("Event information was filled out"), null, {
              duration: 4000,
            });
          }
        );
      } else {
        this.isLoading = false;
        this.errorURL = true;
      }
    }
  }

}
